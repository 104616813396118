import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../reducers/store";
import { 
    formValidation, 
    getAllNoteHistory, 
    getSystemForms, 
    getUsers, 
    handleFieldFiller, 
    removeNote } from "../../../reducers/ProjectValidation/tabs/noteHistoryReducer";
import { GridExpandMoreIcon, DataGrid, GridColDef } from "@mui/x-data-grid";
import { accordionStyle, accordionSummaryStyle, typographyStyle } from "../../../assets/styles/accordionStyles";
import { ptBR } from "@mui/x-data-grid/locales";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomFormComponent from "../../../component/CustomForm";

const NoteHistory = () => {
    const {
        btnConfig,
        pageFields,
        noteHistorys,
        systemForms } = useSelector((state: RootState) => state.noteHistory);
    const dispatch = useDispatch<AppDispatch>();

    const handleOnChange = (value: any, field: any) => {
        dispatch(handleFieldFiller({ field, value }))
        field.action && dispatch(field.action(value))
    }

    const handleSubmit = () => {
        dispatch(formValidation())
    }

    const initPage = async () => {
        dispatch(getUsers())
        await dispatch(getSystemForms())
        await dispatch(getAllNoteHistory())
    }

    useEffect(() => {
        initPage();
        // eslint-disable-next-line 
    }, [dispatch])

    const getAccordionTitle = (systemFormId: number) => {
        const title = systemForms.find((systemForm) => {
            if (systemForm.id === systemFormId) {
                return systemForm.name
            }
            return null;
        })

        return title.name;
    }

    const handleRowDeleteClick = (row: any): void => {
        dispatch(removeNote(row.id))
    }

    const columns: GridColDef[] = [
        {
            field: "systemFormName",
            headerName: "Tipo",
            width: 200,
            filterable: true,
        },
        {
            field: "userRegistrationName",
            headerName: "Incluído Por",
            width: 250,
            filterable: true,
        },
        {
            field: "dateRegistration",
            headerName: "Data da Inclusão",
            width: 150,
            filterable: true,
        },
        {
            field: "descriptionComment",
            headerName: "Comentário",
            width: 400,
            filterable: true,
        },
        {
            field: "userRecipienteName",
            headerName: "Destinatário",
            width: 250,
            filterable: true,
        },
        {
            field: "noteDeletion",
            headerName: "Excluir",
            width: 100,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <IconButton
                    onClick={() => handleRowDeleteClick(params.row)}
                    size="small"
                    title="Excluir"
                >
                    <DeleteForeverIcon color="primary" />
                </IconButton>
            ),
        }
    ];

    return (
        <React.Fragment>
            <br />
            <CustomFormComponent
                fields={pageFields}
                handleOnChange={handleOnChange}
                handleSubmit={handleSubmit}
                btnConfig={btnConfig}
            />
            <br />
            <hr />
            <>
                {
                    noteHistorys.length > 0 ?
                        noteHistorys.map((noteHistory: any) => {
                            return (
                                <Accordion
                                    sx={accordionStyle}
                                    disableGutters>
                                    <AccordionSummary
                                        expandIcon={<GridExpandMoreIcon sx={{ color: "#FFF" }} />}
                                        aria-controls="panel1-content"
                                        id={"noteHistory" + noteHistory.systemFormId}
                                        sx={accordionSummaryStyle}>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Typography sx={typographyStyle} variant="subtitle1">
                                                {
                                                    getAccordionTitle(noteHistory.systemFormId)
                                                }
                                            </Typography>
                                            <Typography sx={typographyStyle} marginRight="7.5px">
                                                {
                                                    (noteHistory.notes.length > 0 ? noteHistory.notes.length : 0)
                                                }
                                            </Typography>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DataGrid
                                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                            sx={{ width: "100%", minWidth: "16em" }}
                                            rows={noteHistory.notes}
                                            getRowId={(row: any) => row.id}
                                            columns={columns}
                                        />
                                    </AccordionDetails>
                                </Accordion>)
                        }) :
                        <></>
                }
            </>
        </React.Fragment>
    )
}

export default NoteHistory
