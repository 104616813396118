import React, { useEffect } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiList from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ListItemIcon from '@mui/material/ListItemIcon';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import { useNavigate } from "react-router-dom";
import SearchComponent from './PowerSearch';
import MuiListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import type { AppDispatch, RootState } from '../reducers/store'
import { useSelector, useDispatch } from 'react-redux'
import { getDuplicityPreProjectCount, getUnderReviewProjectCount } from '../reducers/QueueConunter/moduleQueueCountReducer'
import useMediaQuery from '@mui/material/useMediaQuery';
import logo_extended from '../assets/img/logo_extended.svg';
import logo_mini from '../assets/img/logo_mini.svg';

const NavbarComponent = ({open}: any) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
  
    const duplicityPreProjectCount = useSelector((state: RootState) => state.moduleQueueCount.duplicityPreProjectCount) || 0
    const underReviewProjectCount = useSelector((state: RootState) => state.moduleQueueCount.underReviewProjectCount) || 0
    const drawerWidth = 300;

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const openedMixin = (theme: Theme): CSSObject => ({
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    });
    
    const closedMixin = (theme: Theme): CSSObject => ({
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    });
    
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          }),
          ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          }),
        }),
      );
      

    const List = styled(MuiList)(
      ({ theme }) => ({
        width: '100%',
        maxWidth: 360,
        color: '#fff',
        flexShrink: 0,
      }),
    );

    const ListSubheader = styled(MuiListSubheader)(
      ({ theme }) => ({
        width: '100%',
        backgroundColor: '#4A4A4A',
        maxWidth: 360,
        color: '#fff',
        flexShrink: 0,
      }),
    );

    useEffect(() => {  
      dispatch(getDuplicityPreProjectCount());
      dispatch(getUnderReviewProjectCount());

      const intervalId1 = setInterval(() => {
        dispatch(getDuplicityPreProjectCount());
      }, 30000);
  
      const intervalId2 = setInterval(() => {
        dispatch(getUnderReviewProjectCount());
      }, 30000);
  
      
      return () => {        
        clearInterval(intervalId1);
        clearInterval(intervalId2);
      };
    }, [dispatch]);
        
    return (
        <Drawer
            anchor='left'
            open={!isMobile && open} 
            variant="permanent"

            >
                
            <DrawerHeader>
                <Typography
                      variant="h6"
                      noWrap
                      component="a"
                      href="#app-bar-with-responsive-menu"
                      sx={{ 
                      flexGrow : 0,
                      paddingTop: '0.7em',
                      marginBottom: '1em',
                      display: { xs: 'flex' },
                      height: '1.8em',
                      justifyContent:'center'                            
                      }}>
                      <img alt="logo" src={open ? logo_extended : logo_mini } />
                  </Typography>
                  
                </DrawerHeader>

                <List
                aria-labelledby="nested-list-subheader"
                subheader={ open &&
                    <ListSubheader id="nested-list-subheader">
                         <SearchComponent />
                    </ListSubheader>
                }
                >
                
                </List>
                <Divider />

                <List
                aria-labelledby="nested-list-subheader"
                subheader={ open &&
                    <ListSubheader id="nested-list-subheader">
                        MÓDULO PROJETO
                    </ListSubheader>
                }
                >
                <ListItemButton sx={{pt: 2}} onClick={() => navigate('/preproject-duplicity')}>
                    <ListItemIcon>
                      <Badge badgeContent={duplicityPreProjectCount} color="secondary">
                        <AppRegistrationIcon sx={{color: 'white'}}/>    
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Duplicidade pré-projeto" sx={{mt: 0}}/>
                </ListItemButton>

                <ListItemButton  sx={{pt: 2}} onClick={() => navigate('/projectgrid-pending')}>
                    <ListItemIcon>
                    <Badge badgeContent={underReviewProjectCount} color="secondary">
                    <FactCheckIcon  sx={{color: 'white'}}/>
                    </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Validação de projeto"  sx={{mt: 0}}/>
                </ListItemButton>
                </List>
                <Divider />

                {/*<List
                aria-labelledby="nested-list-subheader"
                subheader={open &&
                    <ListSubheader id="nested-list-subheader">
                        MÓDULO PREPARAÇÃO
                    </ListSubheader>
                }
                >
                <ListItemButton sx={{pt: 2}}>
                    <ListItemIcon>
                    <ArchitectureIcon  sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Fila preparar"  sx={{mt: 0}}/>
                </ListItemButton>
                </List>
                <Divider />

                <List
                aria-labelledby="nested-list-subheader"
                subheader={open &&
                    <ListSubheader id="nested-list-subheader">
                        MÓDULO CONSTRUÇÃO
                    </ListSubheader>
                }
                >
                <ListItemButton sx={{pt: 2}}>
                    <ListItemIcon>
                    <EngineeringIcon  sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Fila construir"  sx={{mt: 0}}/>
                </ListItemButton>
                </List>
              <Divider />*/}
        </Drawer>
    );
  }
  
  export default NavbarComponent