import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export interface TabData {
    label: string,
    content: React.ReactNode,   
}

interface FullWidthTabsProps {
    tabs: TabData[];
  }

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{  p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({ tabs }: FullWidthTabsProps) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", width: '100%' }}>
      <AppBar position="static">
        <Tabs          
          value={value}
          onChange={handleChange}
          scrollButtons
          allowScrollButtonsMobile
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          aria-label="full width tabs"
        >
          {tabs.map((tab: TabData, index: number) => (
            <Tab sx={{minWidth: '16em'}} key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab: TabData, index: number) => (
        <TabPanel key={index} value={value} index={index} dir={theme.direction}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
}