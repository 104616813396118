import { InputType } from '../../types'
import { notNullValidation, rangeValidation } from '../../services/validations'
import { calculateKWdcValue, getCitiesAndUtilies } from '../../reducers/PreProject/preProjectReducer'

const rangeStart = 499
const rangeEnd = 5001
const multiplierRangeStart = 1
const multiplierRangeEnd = 2

export function getNewPreprojectForm() {
  return {
    isFormValid: true,
    isFormDisabled: false,
    pageFields:[
      {
        id: 1,
        error: false,
        label: "Codigo pré-projeto",
        name: "coPreProject",
        required: false,
        value: '',
        type: 1,
        visible: false
      },
      {
        id: 2,
        error: false,
        label: "Status",
        name: "statusPreprojectId",
        required: false,
        value: '',
        type: 2,
        visible: false
      },
      {
        id: 3,
        error: false,
        label: "Última atualização",
        name: "dateLastUpdate",
        required: false,
        value: '',
        type: 1,
        visible: false
      },
      {
        id: 4,
        error: false,
        label: "Projeto (Dev.Reference)*",
        name: "nameProjectDevelop",
        required: true,
        value: '',
        type: 1,
        validation: (value: any) => notNullValidation(value)
      },
      {
        id: 5,
        error: false,
        label: "Desenvolvedor*",
        name: "developerId",
        required: true,
        value: '',
        type: 2,
        options: [],
        validation: (value: any) => notNullValidation(value)
      },
      {
        id: 6,
        error: false,
        label: "Projeto",
        name: "nameProject",
        required: false,
        value: '',
        type: 1,
        visible: false
      },
      {
        id: 7,
        error: false,
        label: "Estado*",
        name: "stateId",
        required: true,
        value: '',
        type: 2,
        options: [],
        action: (stateId: any) => getCitiesAndUtilies(stateId),
        validation: (value: any) => notNullValidation(value)
      },
      {
        id: 8,
        error: false,
        label: "Cidade*",
        name: "cityId",
        required: true,
        value: '',
        type: 2,
        options: [],
        validation: (value: any) => notNullValidation(value)
      },
      {
        id: 9,
        error: false,
        label: "Distribuidora*",
        name: "utilityId",
        required: true,
        value: '',
        type: 2,
        options: [],
        validation: (value: any) => notNullValidation(value)
      },
      {
        id: 10,
        error: false,
        label: "Capacidade instalada*",
        name: "numberCapacityKwac",
        adornment: 'kWac',
        required: false,
        inputType: InputType.NUMBER,
        value: '',
        type: 1,
        mask: 'decimalMask',
        action: (value: any) => calculateKWdcValue(),
        validation: (value: any) => rangeValidation(value, {start: rangeStart, end: rangeEnd}),
        helpMessage: 'Valor entre 500 e 5000 kWac'
      },
      {
        id: 11,
        error: false,
        label: "Fator*",
        name: "numberFactorCapacity",        
        required: true,
        inputType: InputType.NUMBER,
        value: '',
        type: 1,
        mask: 'multiplier',
        action: () => calculateKWdcValue(),
        validation: (value: any) => rangeValidation(value, {start: multiplierRangeStart, end: multiplierRangeEnd}),        
        helpMessage: 'Valor entre 1,0 e 2,0'
      },
      {
        id: 12,
        error: false,
        label: "Capacidade instalada*",
        name: "numberCapacityKwdc",
        adornment: 'kWdc',
        required: false,
        inputType: InputType.NUMBER,
        value: '',
        type: 1,        
        readOnly: true,
        helpMessage: 'Valor calculado automaticamente'
      },
      {
        id: 13,
        error: false,
        label: "Tem parecer de acesso?*",
        name: "accessOpinionId",        
        required: true,
        value: '',
        type: 2,
        options: [],
        validation: (value: any) => notNullValidation(value)
      },      
    ],
    stateSelected: null
  }
}

export default getNewPreprojectForm