import { createSlice } from '@reduxjs/toolkit'
import { DialogState } from '../../types'

const initialState: DialogState = {
  isOpen: false,
  title: '',
  message: '',
  error: false
}

export const projectValidationModalSlice = createSlice({
  name: 'projectValidationModal',
  initialState,
  reducers: {
    setProjectValidationModal: (state, { payload }) => {
      state.isOpen = true
      state.title = payload.title
      state.message = payload.message
      state.messageArray = payload.messageArray
      state.redirect = payload.redirect
      state.error = payload.error
    },
    setProjectValidationModalIdle: (state) => {
      state.isOpen = false
      state.title = ''
      state.message = ''
      state.messageArray = []
      state.error = false
    }
  },
})

export const { setProjectValidationModal, setProjectValidationModalIdle } = projectValidationModalSlice.actions

export default projectValidationModalSlice.reducer