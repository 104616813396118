import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "../../services/agent"
import loadingMiddleware from '../Loading/loadingMiddleware';
import { parseToDecimal } from "../../services/utils";

const COUNT = 10000
const PAGE = 1
const ORDER = 'asc'
const ORDER_PROPERTY = 'id'
const PRE_PROJECT_URL = "pre-projects"

const PRE_PROJECT_LIST_URL = `${PRE_PROJECT_URL}?Count=${COUNT}&Page=${PAGE}&Order=${ORDER}&OrderProprety=${ORDER_PROPERTY}`

const getPreprojectById = createAsyncThunk(
    'preprojectById/get',
    async (id: number, { dispatch }) => {
        const response = await get(PRE_PROJECT_URL + '/' + id)
        return response
    },
)

const getPreProjectGrid = createAsyncThunk(
    'getPreProjectGrid',
    async (_, { dispatch }) => {
        const response = await get(PRE_PROJECT_LIST_URL)
        return response
    },
)
const initialState = {
    rows: [],
    rowSelected: {
        id: null
    }
}

export const preProjectGridSlice = createSlice({
    name: 'preProjectGrid',
    initialState,
    reducers: {
        cleanRowSelected: (state) => {
            state.rowSelected = initialState.rowSelected
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPreProjectGrid.fulfilled, (state, { payload }) => {
                const transformedPayload = payload.map((item: any) => ({
                    id: item.id,
                    cityName: item.cityName,
                    dateLastUpdate: item.dateLastUpdate,
                    nameProject: item.nameProject,
                    coPreProject: item.coPreProject,
                    nameProjectDevelop: item.nameProjectDevelop,
                    stateAcronym: item.stateAcronym,
                    statusName: item.statusName,
                    statusPreprojectId: item.statusPreprojectId,
                    userRegistrationName: item.userRegistrationName,
                    utilityName: item.utilityName,
                    numberCapacityKwdc: parseToDecimal(item.numberCapacityKwdc),
                    numberCapacityKwac: parseToDecimal(item.numberCapacityKwac),
                    numberFactorCapacity: item.numberFactorCapacity?.toString().replace('.', ','),
                    accessOpinionName: item.accessOpinionName,
                }));
                state.rows = transformedPayload;
            })
            .addCase(getPreprojectById.fulfilled, (state, {payload}) => {
                state.rowSelected = payload;
            })
    }
})

export { getPreProjectGrid, loadingMiddleware, getPreprojectById };

export const { cleanRowSelected } = preProjectGridSlice.actions;

export default preProjectGridSlice.reducer;