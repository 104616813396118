import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "../../services/agent"
import loadingMiddleware from '../Loading/loadingMiddleware';
import { parseToDecimal } from "../../services/utils";

const PRE_PROJECT_URL = "pre-projects"
const PROJECT_URL = "projects"
const PRE_PROJECT_IMPORT_URL = 'pre-projects/imports';

const GET_PRE_PROJECT_LIST_FILTERED_URL = (filter: string) => `${PRE_PROJECT_URL}?PowerSearch=${filter}`
const GET_PROJECT_LIST_FILTERED_URL = (filter: string) => `${PROJECT_URL}?PowerSearch=${filter}`
const GET_PRE_PROJECT_IMPORT_FILTERED_URL = (filter: string) => `${PRE_PROJECT_IMPORT_URL}?PowerSearch=${filter}`

const getProjectGridFiltered = createAsyncThunk(
    'projectGridFiltered/get',
    async (filter: string) => {
        const response = await get(GET_PROJECT_LIST_FILTERED_URL(filter))
        return response.result
    },
)

const getPreProjectGridFiltered = createAsyncThunk(
    'preProjectGridFiltered/get',
    async (filter: string) => {
        const response = await get(GET_PRE_PROJECT_LIST_FILTERED_URL(filter))
        return response
    },
)

const getPreProjectImportGridFiltered = createAsyncThunk(
    'preProjectImportGrid/get',
    async (filter: string) => {
        const response = await get(GET_PRE_PROJECT_IMPORT_FILTERED_URL(filter))
        return response;
    }
)

const initialState = {
    projectRows: [],
    preProjectRows: [],
    preProjectImportRows: [],
    keyword: ''
}

export const powerSearchGridsSlice = createSlice({
    name: 'powerSearchGrids',
    initialState,
    reducers: {
        cleanRowsFiltereds: (state) => {
            state.projectRows = initialState.projectRows
            state.preProjectRows = initialState.preProjectRows
            state.preProjectImportRows = initialState.preProjectImportRows
            state.keyword = initialState.keyword
        },
        setKeyword: (state, {payload}) => {
            state.keyword = payload          
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPreProjectGridFiltered.rejected, (state) => {
                state.preProjectRows = initialState.preProjectRows
            })
            .addCase(getPreProjectGridFiltered.fulfilled, (state, { payload }) => {
                const transformedPayload = (payload || []).map((item: any) => ({
                    id: item.id,
                    cityName: item.cityName,
                    dateLastUpdate: item.dateLastUpdate,
                    nameProject: item.nameProject,
                    coPreProject: item.coPreProject,
                    nameProjectDevelop: item.nameProjectDevelop,
                    stateAcronym: item.stateAcronym,
                    statusName: item.statusName,
                    statusPreprojectId: item.statusPreprojectId,
                    userRegistrationName: item.userRegistrationName,
                    utilityName: item.utilityName,
                    numberCapacityKwdc: parseToDecimal(item.numberCapacityKwdc),
                    numberCapacityKwac: parseToDecimal(item.numberCapacityKwac),
                    numberFactorCapacity: item.numberFactorCapacity?.toString().replace('.', ','),
                    accessOpinionName: item.accessOpinionName
                }));
                state.preProjectRows = transformedPayload;
            })
            .addCase(getProjectGridFiltered.rejected, (state) => {
                state.projectRows = initialState.projectRows
            })
            .addCase(getProjectGridFiltered.fulfilled, (state, { payload }) => {
                const transformedPayload = (payload || []).map((item: any) => ({
                    id: item.id,
                    preId: item.preId,
                    stateName: item.nameState,
                    stateAcronym: item.stateAcronym,
                    cityName: item.nameCity,
                    dateLastUpdate: item.dateLastUpdate,
                    name: item.name,
                    coproject: item.co,
                    nameProjectDevelop: item.nameProjectDevelop,
                    statusName: item.nameStatus,
                    statusprojectId: item.statusId,
                    userRegistrationName: item.userRegistrationName,
                    utilityName: item.nameUtility,
                    numberCapacityKwdc: parseToDecimal(item.numberCapacityKwdc),
                    numberCapacityKwac: parseToDecimal(item.numberCapacityKwac),
                    numberFactorCapacity: item.numberFactorCapacity?.toString().replace('.', ','),
                    accessOpinionName: item.accessOpinionName
                }));
                state.projectRows = transformedPayload;
            })
            .addCase(getPreProjectImportGridFiltered.rejected, (state) => {
                state.preProjectImportRows = initialState.projectRows
            })
            .addCase(getPreProjectImportGridFiltered.fulfilled, (state, { payload }) => {
                const transformedPayload = (payload || []).map((item: any) => ({
                    id: item.importPreProjectId,
                    fileName: item.fileName,
                    fileTypeName: item.fileTypeName,
                    dateRegistration: item.dateRegistration,
                    userRegistrationName: item.userRegistrationName
                }));
                state.preProjectImportRows = transformedPayload;
            })
    }
})

export { loadingMiddleware, getPreProjectGridFiltered, getProjectGridFiltered, getPreProjectImportGridFiltered };

export const { cleanRowsFiltereds, setKeyword } = powerSearchGridsSlice.actions;

export default powerSearchGridsSlice.reducer;