import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../../component/Form";
import { AppDispatch, RootState } from "../../../reducers/store";
import {
    getAccessOpinionIds,
    getCitiesAndUtilies,
    getDevelopers,
    getStates,
    getStatuses,
    handleFieldFiller,
    getPreProjectById
} from "../../../reducers/ProjectValidation/tabs/initialInformationReducer";

const InitialInformation = () => {
    const { projectSelected } = useSelector((state: RootState) => state.projectToValidate);
    const {
        pageFields,
        stateSelected,
        isProjectLoaded } = useSelector((state: RootState) => state.initialInformation);
    const dispatch = useDispatch<AppDispatch>();

    const handleOnChange = (value: any, field: any) => {
        dispatch(handleFieldFiller({ field, value }));
        field.action && dispatch(field.action(value));
    };

    const initPage = async () => {
        const id = projectSelected.preId
        if (id) {
            await dispatch(getPreProjectById(id));
        }
        dispatch(getAccessOpinionIds())
        dispatch(getStatuses())
        dispatch(getDevelopers())
        dispatch(getStates())
        dispatch(getCitiesAndUtilies())
    }

    useEffect(() => {
        if (stateSelected &&
            isProjectLoaded) {
            dispatch(getCitiesAndUtilies())
        }
        // eslint-disable-next-line 
    }, [stateSelected, isProjectLoaded])

    useEffect(() => {
        initPage()
        // eslint-disable-next-line 
    }, [dispatch])

    return (
        <React.Fragment>
            <br />
            <Form fields={pageFields} hasSaveButton={false} handleOnChange={handleOnChange} />
        </React.Fragment>
    )
}

export default InitialInformation