import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import type { AppDispatch, RootState } from '../reducers/store'
import Box from '@mui/material/Box';
import Navbar from './Navbar';
import HeaderComponent from './Header';
import { authorizeUser, getToken, getUser } from '../reducers/User/userReducer'
import { useMsal } from "@azure/msal-react";


const AuthorizedPage = ({ children, userRole }: any) => {
  const isAuthorized = useSelector((state: RootState) => state.user.isAuthorized)
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch<AppDispatch>()
  const [menuState, setMenuState] = useState(false)

  useEffect(() => {
    dispatch(authorizeUser({ userRole, instance, accounts }))
  })

  useEffect(() => {
    dispatch(getToken({ instance, accounts }))
    dispatch(getUser())
    // eslint-disable-next-line 
  }, [isAuthorized])

  const closeMenu = (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    if (
      target instanceof Node &&
      !target.closest('.MuiDrawer-root')
    ) {
      setMenuState(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeMenu);
    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  }, []);

  return (
    isAuthorized ?
      <Box>
        <HeaderComponent open={menuState} menutoggle={() => setMenuState(!menuState)} />
        <Navbar open={menuState} />
        {children}
      </Box> : <div></div>
  )
}

export default AuthorizedPage