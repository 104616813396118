import { InputType } from '../../types'
import { notNullValidation, rangeValidation } from '../../services/validations'
import { getJustifications } from '../../reducers/ProjectValidation/tabs/projectAnalyzesReducer'
import { toast } from 'react-toastify'
import { parseToFloat } from '../../services/utils'

const valueGhiRangeStart = 0
const valueGhiRangeEnd = 10000

const handleBlur = (event: any) => {
    const value = parseToFloat(event.target.value)    
    if (value > 50000) {
        toast.warning('Atenção, valor acima do esperado!');
    }
};

export function getNewProjectAnalyzesForm() {

    return {
        id: 0,
        isFormValid: true,
        isFormDisabled: false,
        isUpdating: false,
        oldSystemFields: [],
        rows: [] as any[],
        btnConfig: {
            visible: false,
        },
        systemFields: [] as any[],
        impeditiveFields: [] as any[],
        pageFields: [
            {
                id: 1,
                error: false,
                label: "Matrícula*",
                name: "propertyRegistration",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [1, 4, 6, 7, 8],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 2,
                error: false,
                label: "CAR*",
                name: "CAR",
                required: true,
                value: '',
                type: 2,
                options: [],
                optionsFilter: [1, 4, 6, 7, 8],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 3,
                error: false,
                label: "CCIR*",
                name: "CCIR",
                required: false,
                value: '',
                type: 2,
                optionsFilter: [1, 3, 4, 6, 7, 8],
                validation: (value: any) => notNullValidation(value),
                options: [],
            },
            {
                id: 5,
                error: false,
                label: "Georreferenciamento*",
                name: "isGeoReferencing",
                required: true,
                inputType: InputType.BOOLEAN,
                value: true,
                type: 3,
                validation: (value: any) => notNullValidation(value),
                options: [
                    {
                        id: 0,
                        name: 'Sim',
                        value: true
                    },
                    {
                        id: 1,
                        name: 'Não',
                        value: false
                    },
                ],
            },
            {
                id: 6,
                error: false,
                label: "Tipo imóvel",
                name: "typePropertyId",
                required: true,
                value: '',
                type: 2,
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 7,
                error: false,
                label: "Documentação do imóvel",
                name: "propertyDocumentation",
                required: false,
                value: '',
                type: 2,
                optionsFilter: [1, 3, 4, 6, 7, 8],
                options: [],
            },
            {
                id: 8,
                error: false,
                label: "GHI*",
                name: "valueGhi",
                required: true,
                inputType: InputType.NUMBER,
                value: '',
                type: 1,
                mask: 'decimalMask',
                validation: (value: any) => rangeValidation(value, { start: valueGhiRangeStart, end: valueGhiRangeEnd }),
                helpMessage: 'Valor entre 0 e 10000'
            },
            {
                id: 9,
                error: false,
                label: "Produção específica (MWh/ano)",
                name: "numberSpecificProduction",
                required: false,
                inputType: InputType.NUMBER,
                value: '',
                type: 1,
                onBlur: handleBlur,
                mask: 'decimalMask',
                helpMessage: 'Valor entre 0 e 50000'
            },
        ],
        justificationForm: {
            isFormValid: true,
            isFormDisabled: false,
            btnConfig: {
                visible: true,
                value: 'Vincular',
                xs: 2,
                height: '50%',
                iconType: 1
            },
            pageFields: [
                {
                    id: 1,
                    error: false,
                    label: "Campo*",
                    name: "systemFieldFormEnum",
                    required: true,
                    value: '',
                    type: 2,
                    options: [],
                    action: (value: number) => getJustifications(value),
                    validation: (value: any) => notNullValidation(value)
                },
                {
                    id: 2,
                    error: false,
                    label: "Motivo*",
                    name: "justicationId",
                    required: true,
                    value: '',
                    type: 2,
                    options: [],
                    validation: (value: any) => notNullValidation(value)
                },
                {
                    id: 3,
                    error: false,
                    label: "Comentário",
                    name: "descJustification",
                    required: false,
                    value: '',
                    type: 4,
                    xs: 10,
                    options: [],
                }
            ],
        }
    }
}

export default getNewProjectAnalyzesForm