import React from 'react';
import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, Select, MenuItem, Tooltip, RadioGroup, FormControlLabel, Radio, FormLabel, FormHelperText } from '@mui/material';
import { getMaskFunction } from '../services/utils';

const InputComponent = ({field, handleOnChange, action}: any) => {
  const handleOnChangeInside = (event: any) => {
    const maskedValue = field.mask ? getMaskFunction(field.mask)(event.target.value) : event.target.value;
    handleOnChange(maskedValue, field)
  }

  const disabledStyle = field.disabled ? { color: 'gray', backgroundColor: '#f0f0f0' } : {};
  const visibleStyle = field.visible === false ? { display: 'none' } : {};
  
 
  return (
    <Box style={visibleStyle}>
      <FormControl fullWidth >
        {field.type === 3 ?  
          <FormLabel id={`${field.name}-label`} style={disabledStyle}>{field.label}</FormLabel> :
          <InputLabel sx={{ bgcolor:'white' }} id={`${field.name}-label`} style={disabledStyle}>{field.label}</InputLabel> 
        }

        {field.type === 1 && 
          <Tooltip
            children={
              <OutlinedInput 
                sx={{ width: (field.width ? field.width :'16em') }}
                error={field.error}
                id={`${field.name}`}
                name={field.name}
                disabled={field.disabled}
                type={field.inputType ? field.inputType.toString() : ''}
                style={{border: field.error && '0.1px solid red', ...disabledStyle}}
                value={field.value} 
                onChange={handleOnChangeInside}
                readOnly={field.readOnly ? field.readOnly : false}
                multiline={field.multiline ? field.multiline : false}
                endAdornment={field.adornment && <InputAdornment position="start">{field.adornment}</InputAdornment>}
              /> 
            }
            title={field.required && !field.value ? "Este campo é obrigatório" : ""}
            followCursor
          />
        }

        {field.type === 2 && 
          <Tooltip title={field.options?.length === 0 && 'Esse campo tem pré-requisito'}>
            <Tooltip
              children={
                <Select
                  sx={{ width: '16em' }}
                  error={field.error}
                  disabled={field.disabled}
                  defaultValue={1}
                  id={`${field.name}`}
                  labelId={`${field.name}-label`}
                  name={field.name}
                  label={field.value}
                  style={{border: field.error && '1px solid red', ...disabledStyle}}
                  value={field.value}               
                  onChange={handleOnChangeInside}
                  endAdornment={field.adornment && <InputAdornment position="start">{field.adornment}</InputAdornment>}
                >
                  {(field.options && field.options.length > 0) && field.options.map((option: any) => <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)}
                </Select>
              }
              title={field.required && !field.value ? "Este campo é obrigatório" : ""}
              followCursor
            />
          </Tooltip>
        }

        {field.type === 3 && 
          <Tooltip
            children={
              <RadioGroup
                row
                sx={{ width: '16em', marginBottom: '-0.5em'}}
                aria-labelledby={`${field.name}-label`}
                name="row-radio-buttons-group"
                value={field.value}
                onChange={handleOnChangeInside}
              >
                {(field.options && field.options.length > 0) && field.options.map((option: any) => <FormControlLabel key={option.id} value={option.value} disabled={field.disabled} control={<Radio />} label={option.name} />)}
              </RadioGroup>
            }
            title={field.required && !field.value ? "Este campo é obrigatório" : ""}
            followCursor
          />
        }

        {field.error && <FormHelperText id="outlined-weight-helper-text" style={{color:'red'}}>Informação Inválida</FormHelperText>}
        {(!field.error && field.helpMessage) && <FormHelperText id="outlined-weight-helper-text">{field.helpMessage}</FormHelperText>}
      </FormControl>
    </Box>
  );

}

export default InputComponent;