import { IconButton, Typography } from "@mui/material";

import { GridColDef } from "@mui/x-data-grid";

import { useDispatch } from "react-redux";

import {
  getPreprojectById,
  getprojectById,
} from "../../reducers/Project/projectGridReducer";
import { AppDispatch } from "../../reducers/store";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

import { format } from "date-fns";
import { parseToDecimal } from "../../services/utils";

const ProjectGridColumnsData = () => {
  const dispatch: AppDispatch = useDispatch();

  const handleRowClick = async (row: any) => {
    await dispatch(getPreprojectById(row.preId));
    await dispatch(getprojectById(row.id));
  };

  const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const columns: GridColDef[] = [
    {
      field: "projectActions",
      headerName: "Editar",
      width: 100,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleRowClick(params.row)}
          size="small"
          title="Editar"
        >
          <AppRegistrationIcon color="primary" />
        </IconButton>
      ),
    },
    { field: "coproject", headerName: "Código", width: 150, filterable: true },
    {
      field: "nameProjectDevelop",
      headerName: "Projeto (Ref. develop)",
      width: 250,
      filterable: true,
    },
    { field: "name", headerName: "Projeto", width: 150, filterable: true },
    { field: "stateName", headerName: "Estado", width: 80, filterable: true },
    { field: "cityName", headerName: "Cidade", width: 200, filterable: true },
    {
      field: "utilityName",
      headerName: "Distribuidora",
      width: 170,
      filterable: true,
    },
    {
      field: "numberCapacityKwac",
      headerName: "Capacidade instalada kWac", 
      width: 190,
      filterable: true,
    },
    { field: 'numberFactorCapacity', headerName: 'Fator', width: 80, filterable: true },
    {
      field: "numberCapacityKwdc",
      headerName: "Capacidade instalada kWdc",
      width: 190,
      filterable: true,
    },
    {
      field: "accessOpinionName",
      headerName: "Parecer de acesso",
      width: 150,
      filterable: true,
    },
    {
      field: "dateLastUpdate",
      headerName: "Última atualização",
      width: 150,
      filterable: true,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm")
          : "";
      },
    },
    { field: "statusName", headerName: "Status", width: 120, filterable: true },
    //networkReinforcement
    {
      field: "networkConectionDateRegistration",
      headerName: "Conexão de rede - Data do registro",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Data do registro
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm")
          : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionUserRegistrationName",
      headerName: "Conexão de rede - Incluído por",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Incluído por
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionNumberExtension",
      headerName: "Conexão de rede - Nº de extensão",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Nº de extensão
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? parseToDecimal(params.value) + " km's" : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionValueTotalCost",
      headerName: "Conexão de rede - Custo total da obra",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Custo total da obra
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? currencyFormatter.format(params.value) : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionValueFinancialParticipationCustomer",
      headerName: "Conexão de rede - Participação financeira do cliente",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Participação financeira do cliente
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value ? currencyFormatter.format(params.value) : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionDateIssueAccessOpinion",
      headerName: "Conexão de rede - Data de protocolo da solicitação de acesso",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Data de protocolo da solicitação de acesso
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), "dd/MM/yyyy")
          : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionDateConstructionDeadlines",
      headerName: "Conexão de rede - Prazo de obras (Inicial)",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Prazo de obras (Inicial)
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), "dd/MM/yyyy")
          : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionDateMaximumConnection",
      headerName: "Conexão de rede - Data máxima para conexão",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Data máxima para conexão
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), "dd/MM/yyyy")
          : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionIsProjectDocumentsApprovedAccessOpinion",
      headerName:
        "Conexão de rede - Documentos do projeto aprovado relativo ao parecer de acesso",
      renderHeader: () => (
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 500,
            fontSize: "13.5px",
            pb: "2px",
            lineHeight: "1.3",
          }}
        >
          Conexão de rede <br />
          Documentos do projeto aprovado <br />
          relativo ao parecer de acesso
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 270,
      valueFormatter: (params) => {
        return params.value ? "Sim" : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionTypeCabinProjectName",
      headerName: "Conexão de rede - Projeto de cabine",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Projeto de cabine
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionTypeProofProjectApprovalName",
      headerName:
        "Conexão de rede - Comprovante de aprovação do projeto elétrico",
      renderHeader: () => (
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 500,
            fontSize: "13.5px",
            pb: "2px",
            lineHeight: "1.3",
          }}
        >
          Conexão de rede <br />
          Comprovante de aprovação
          <br />
          do projeto elétrico
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionDateAccessRequestProtocol",
      headerName: "Conexão de rede - Data de emissão do acesso",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Data de emissão do acesso
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), "dd/MM/yyyy")
          : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionCcer",
      headerName: "Conexão de rede - CCER",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          CCER
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionCusd",
      headerName: "Conexão de rede - CUSD",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          CUSD
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionContratoDeObras",
      headerName: "Conexão de rede - Contrato de obras",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Contrato de obras
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "networkConectionHaImpeditivoNoTracadoObraDeRede",
      headerName: "Conexão de rede - Há impeditivo no traçado da obra de rede",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Conexão de rede <br />
          Há impeditivo no traçado da obra de rede
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    //project analysis
    {
      field: "projectAnalysisDateRegistration",
      headerName: "Análise do Projeto - Data de emissão do acesso",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          Data de emissão do acesso
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 230,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), "dd/MM/yyyy")
          : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisUserRegistrationName",
      headerName: "Análise do Projeto - Incluído por",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          Incluído por
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisIsGeoReferencing",
      headerName: "Análise do Projeto - Georreferenciamento",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          Georreferenciamento
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? "Sim" : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisValueGhi",
      headerName: "Análise do Projeto - GHI",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          GHI
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? parseToDecimal(params.value) : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisTypePropertyName",
      headerName: "Análise do Projeto - Tipo imóvel",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          Tipo imóvel
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisNumberSpecificProduction",
      headerName: "Análise do Projeto - Produção específica (MWh/ano)",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          Produção específica (MWh/ano)
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? parseToDecimal(params.value) : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisMatricula",
      headerName: "Análise do Projeto - Matrícula",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          Matrícula
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisCar",
      headerName: "Análise do Projeto - CAR",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          CAR
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisCcir",
      headerName: "Análise do Projeto - CCIR",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          CCIR
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "projectAnalysisDocumentacaoImovel",
      headerName: "Análise do Projeto - Documentação do imóvel",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Análise do Projeto <br />
          Documentação do imóvel
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },

    //georefer
    {
      field: "geoReferDateRegistration",
      headerName: "Georreferenciamento - Data do registro",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Data do registro
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 180,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm")
          : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferUserRegistrationName",
      headerName: "Georreferenciamento - Incluído por",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Incluído por
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferFileName",
      headerName: "Georreferenciamento - Nome do arquivo",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Nome do arquivo
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferExtensionName",
      headerName: "Georreferenciamento - Extensão do arquivo",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Extensão do arquivo
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferInclinacao",
      headerName: "Georreferenciamento - Inclinação",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Inclinação
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferRiscoDeAlagamento",
      headerName: "Georreferenciamento - Risco de alagamento",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Risco de alagamento
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferVegetacao",
      headerName: "Georreferenciamento - Vegetação",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Vegetação
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferApp",
      headerName: "Georreferenciamento - APP",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          APP
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferReservaLegal",
      headerName: "Georreferenciamento - Reserva legal",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Reserva legal
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferUcs",
      headerName: "Georreferenciamento - UCS",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          UCS
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferAssentamento",
      headerName: "Georreferenciamento - Assentamento",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Assentamento
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferDireitoMinerario",
      headerName: "Georreferenciamento - Direito minerário",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Direito minerário
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferRiscoDeCaverna",
      headerName: "Georreferenciamento - Risco de caverna",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Risco de caverna
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferSitiosArqueologicos",
      headerName: "Georreferenciamento - Sítios arqueológicos",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Sítios arqueológicos
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferQuilombola",
      headerName: "Georreferenciamento - Quilombola",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Quilombola
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferIndigena",
      headerName: "Georreferenciamento - Indígena",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Indígena
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferAreaDeComportaAPotencia",
      headerName: "Georreferenciamento - Área comporta potência",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Área comporta potência
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
    {
      field: "geoReferRiscoDeInundacao",
      headerName: "Georreferenciamento - Risco de inundação",
      renderHeader: () => (
        <Typography
          sx={{ textAlign: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Georreferenciamento <br />
          Risco de inundação
        </Typography>
      ),
      align: "center",
      headerAlign: "center",
      width: 200,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      filterable: true,
    },
  ];

  return { columns };
};

export default ProjectGridColumnsData;
