import React from 'react';
import ErrorBoundaryPage from '../pages/Error/ErrorBoundaryPage';
import ErrorHandler from '../services/app.insights';

class ErrorBoundary extends React.Component {
  errorHandler: ErrorHandler;

  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
    this.errorHandler = new ErrorHandler();
  }

  state = {
    hasError: false
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.errorHandler.handleError(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryPage />;
    }
    return (this.props as { children: React.ReactNode }).children;
  }
}

export default ErrorBoundary;
