import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../services/agent'
import { format } from 'date-fns'
import loadingMiddleware from '../Loading/loadingMiddleware';

const ALL_JUSTIFICATIONS_FIELDS_URL = 'justifications'
const GET_PRE_PROJECT__BY_ID_URL = (id: number) => `pre-projects/${id}`
const GET_PROJECT_BY_ID_URL = (id: number) => `projects/${id}`

const getProjectToValidateById = createAsyncThunk(
    'projectToValidateById/get',
    async (id: number) => {
        const response = await get(GET_PROJECT_BY_ID_URL(id))
        return response
    },
)

const getAllJustifications = createAsyncThunk(
    'allJustifications/get',
    async (_) => {
        const response = await get(ALL_JUSTIFICATIONS_FIELDS_URL)
        return response
    },
)

const getPreProjectById = createAsyncThunk(
    'preProjectById/get',
    async (id: number, { dispatch }) => {
        const response = await get(GET_PRE_PROJECT__BY_ID_URL(id))
        return response
    },
)

const initialState = {
    allJustifications: [],
    projectSelected: {
        id: 0,
        preId: 0
    },  
}

export const projectToValidateSlice = createSlice({
    name: 'projectToValidate',
    initialState,
    reducers: {
        setProjectData: (state, { payload }) => {
            state.projectSelected = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectToValidateById.fulfilled, (state, { payload }) => {
                state.projectSelected = {
                    ...payload,
                    dateRegistration: format(new Date(payload.dateRegistration), 'dd/MM/yyyy HH:mm'),
                    dateLastUpdate: format(new Date(payload.dateLastUpdate), 'dd/MM/yyyy HH:mm')
                }
            })
            .addCase(getAllJustifications.fulfilled, (state, { payload }) => {
                state.allJustifications = payload
            })
    },
})

export {
    getProjectToValidateById,
    getPreProjectById,
    getAllJustifications,
    loadingMiddleware
};

export const { setProjectData } = projectToValidateSlice.actions;

export default projectToValidateSlice.reducer
