import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from '../routes'

const BreadcrumbComponent = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
    
  const handleClick = (event: any, route: string) =>{
    event.preventDefault();
    navigate(route);
  }
  
  //@ts-ignore
  const routesMapped: any = routes.routes[0].children.map((r: any) => ({
    path: r.path,
    breadcrumbName: r.element.props ? r.element.props.breadcrumbName : r.path.replace('/','')
  }));

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/" onClick={(event) => handleClick(event, '/')}>
        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Principal
      </Link>
      {pathnames.map((value, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const route = routesMapped.find((r: any) => r.path === routeTo);

        if (route) {
          return (
            <Link key={value} color="inherit" href={routeTo} onClick={(event)=> handleClick(event, routeTo)}>
              {route.breadcrumbName}
            </Link>
          );
        } else {
          return <Typography key={value} color="textPrimary">{value}</Typography>;
        }
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbComponent