import { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { setLoading, setIdle } from './loadingReducer';

const actionFilters = ['token/get', 'user/authorizeUse', 'duplicityPreProject/count', 'underReviewProject/count'];

const loadingMiddleware: Middleware = ({ dispatch }) => next => action => {
  const actionType = (action as PayloadAction<any>)?.type; 

  if (actionType && actionFilters.some(filter => actionType.includes(filter))) {
    return next(action);
  }

  if (actionType && actionType.endsWith('/pending')) {
    dispatch(setLoading());
  }

  if (actionType && (actionType.endsWith('/fulfilled') || actionType.endsWith('/rejected'))) {
    dispatch(setIdle());
  }

  return next(action);
};

export default loadingMiddleware;