import { Grid, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setImportDetailData } from "../../../reducers/PreProjectImport/preProjectImportDetailReducer";
import {
  cleanRowSelected,
  getImportDetailById,
} from "../../../reducers/PreProjectImport/preProjectImportGridReducer";
import { AppDispatch, RootState } from "../../../reducers/store";
import { ptBR } from "@mui/x-data-grid/locales";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";

const PreProjectImport = () => {
  const dispatch: AppDispatch = useDispatch();
  const rows = useSelector(
    (state: RootState) => state.powerSearchGrids.preProjectImportRows
  );
  const rowSelected = useSelector(
    (state: RootState) => state.preProjectImportGrid.rowSelected
  );
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "preProjectActions",
      headerName: "Ações",
      width: 100,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleRowClick(params.row)}
          size="small"
          title="Ver"
        >
          <VisibilityIcon color="primary" />
        </IconButton>
      ),
    },
    {
      field: "fileName",
      headerName: "Nome arquivo",
      width: 400,
      filterable: true,
    },
    {
      field: "fileTypeName",
      headerName: "Extensão",
      width: 150,
      filterable: true,
    },
    {
      field: "dateRegistration",
      headerName: "Data de importação",
      width: 150,
      filterable: true,
      valueFormatter: (params) => {      
        return params.value ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm") : '';
      },
  },
    {
      field: "userRegistrationName",
      headerName: "Responsável importação",
      width: 200,
      filterable: true,
    },
  ];

  useEffect(() => {
    if (rowSelected.length > 0) {
      dispatch(setImportDetailData(rowSelected));
      dispatch(cleanRowSelected());
      navigate("/preproject/imports/detail");
    }
    // eslint-disable-next-line
  }, [rowSelected]);

  const handleRowClick = async (row: any) => {
    await dispatch(getImportDetailById(row.id));
  };

  return (
    <Grid
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      container
      mt={2}
      pb={1}
      spacing={0}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom>
          Pré-projeto (Importação)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.id}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          slots={{ toolbar: GridToolbar }}
        />
      </Grid>
    </Grid>
  );
};

export default PreProjectImport;
