import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import PageContainerComponent from "../../component/PageContainer";
import { RootState } from "../../reducers/store";
import { ptBR } from '@mui/x-data-grid/locales';
import { parseToDecimal } from "../../services/utils";

const PrepRojectImportDetailGridComponent = () => {
    const rows = useSelector((state: RootState) => state.preProjectImportDetail.rows);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const columns: GridColDef[] = [
        { field: 'nameState', headerName: 'Estado', width: 150, filterable: true },
        { field: 'nameCity', headerName: 'Cidade', width: 200, filterable: true },
        { field: 'nameDevelop', headerName: 'Desenvolvedor', width: 170, filterable: true },
        { field: 'nameUtility', headerName: 'Distribuidora', width: 170, filterable: true },
        {
          field: "numberCapacityKwac",
          headerName: "Capacidade instalada kWac",
          valueFormatter: (params) => {
              return parseToDecimal(params.value);
          },
          width: 190,
          filterable: true,
        },
        { field: "numberFactorCapacity", headerName: "Fator", width: 80, filterable: true, },
        {
          field: "numberCapacityKwdc",
          headerName: "Capacidade instalada kWdc",
          valueFormatter: (params) => {
              return parseToDecimal(params.value);
          },
          width: 190,
          filterable: true,
        },
        { field: 'isAccessOpinion', headerName: 'Tem parecer de acesso?', width: 175, filterable: true },
        { field: 'errors', headerName: 'Erros', width: 400, filterable: true, valueGetter: (params) => params.row.errors ? params.row.errors.join(', ') : '' }
    ];

    return (
        <PageContainerComponent title="Detalhamento de arquivo de importação de Pré-Projetos" style={{ marginLeft: isMobile ? 60 : 300 }}>
            <DataGrid localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} 
                rows={rows}
                columns={columns}
                getRowId={(row: any) => row.id}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                slots={{ toolbar: GridToolbar }}
                getRowClassName={(params) => {
                    return params.row.errors && params.row.errors.length > 0 ? 'error-row' : '';
                }}
                isRowSelectable={(params) => {
                    return !(params.row.errors && params.row.errors.length > 0);
                }}
            />
        </PageContainerComponent>
    );
}

export default PrepRojectImportDetailGridComponent;