import { DataGrid, GridColDef, GridExpandMoreIcon } from "@mui/x-data-grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import CustomFormComponent from "./CustomForm";
import { ptBR } from "@mui/x-data-grid/locales";
import React from "react";
import { format } from "date-fns";
import { accordionStyle, accordionSummaryStyle, mainAccordionStyle, mainAccordionSummaryStyle, typographyStyle } from "../assets/styles/accordionStyles";

const JustificationGrid = ({
  rows,
  pageFields,
  isFormDisabled,
  btnConfig,
  handleSubmit,
  handleOnChange,
}: any) => {
  const columns: GridColDef[] = [
    {
      field: "systemFieldFormEnum",
      headerName: "Campo",
      width: 200,
      filterable: true,
    },
    {
      field: "justicationName",
      headerName: "Motivo",
      width: 180,
      filterable: true,
    },
    {
      field: "userRegistrationName",
      headerName: "Incluído Por",
      width: 180,
      filterable: true,
    },
    {
      field: "dateRegistration",
      headerName: "Data da Inclusão",
      width: 150,
      filterable: true,
      valueFormatter: (params) => {       
        return params.value ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm") : '';
      },
    },
    {
      field: "descJustification",
      headerName: "Comentário",
      width: 800,
      filterable: true,
    },
  ];

  return (
    <React.Fragment>
      <Accordion sx={mainAccordionStyle} disableGutters defaultExpanded>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon sx={{ color: "#FFF" }} />}
          aria-controls="panel1-content"
          id="justification"
          sx={mainAccordionSummaryStyle}
        >
          <Typography sx={typographyStyle} variant="h6">
            Justificativas
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: "15px" }}>
          <Accordion sx={accordionStyle} disableGutters>
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon sx={{ color: "#FFF" }} />}
              aria-controls="panel1-content"
              id="justification"
              sx={accordionSummaryStyle}
            >
              <Typography sx={typographyStyle} variant="subtitle1">
                Incluir nova justificativa
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "15px 0" }}>
              <CustomFormComponent
                fields={pageFields}
                handleOnChange={handleOnChange}
                handleSubmit={handleSubmit}
                disabled={isFormDisabled}
                btnConfig={btnConfig}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={accordionStyle} disableGutters defaultExpanded>
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon sx={{ color: "#FFF" }} />}
              aria-controls="panel1-content"
              id="justification"
              sx={accordionSummaryStyle}
            >
              <Typography sx={typographyStyle} variant="subtitle1">
                Lista de justificativas
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "15px 0" }}>
              <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ width: "100%", minWidth: "16em" }}
                rows={rows}
                getRowId={(row: any) => row.id}
                columns={columns}
              />
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default JustificationGrid;
