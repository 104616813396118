import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import getNewInitialInformationForm from '../../data/forms/initialInformation'
import { get } from '../../services/agent'
import {
    findFieldByName,
    handleFiller,
    optionsFiller,
    parseToDecimal,
    rowDataToFieldParser
} from '../../services/utils'
import loadingMiddleware from '../Loading/loadingMiddleware';

const ACCESS_OPINION_URL = "access-opinion"
const GET_PRE_PROJECT__BY_ID_URL = (id: number) => `pre-projects/${id}`
const GET_PROJECT_BY_ID_URL = (id: number) => `projects/${id}`
const DEVELOPERS_URL = "developers"
const STATES_URL = "states"
const STATUS_PRE_PROJECT_URL = "status-pre-projects"
const CITIES_URL = (id: string) => `${STATES_URL}/${id}/cities`
const UTILITIES_URL = (id: string) => `${STATES_URL}/${id}/utilities`

const getAccessOpinionIds = createAsyncThunk(
    'accessOpinion/get',
    async (_, { dispatch }) => {
        const response = await get(ACCESS_OPINION_URL)
        return response
    },
)

const getDevelopers = createAsyncThunk(
    'developers/get',
    async (_, { dispatch }) => {
        const response = await get(DEVELOPERS_URL)
        return response
    },
)

const getProjectToValidateById = createAsyncThunk(
    'projectToValidateById/get',
    async (id: number) => {
        const response = await get(GET_PROJECT_BY_ID_URL(id))
        return response
    },
)

const getPreProjectById = createAsyncThunk(
    'preProjectById/get',
    async (id: number, { dispatch }) => {
        const response = await get(GET_PRE_PROJECT__BY_ID_URL(id)) 
        return response
    },
)

const getStates = createAsyncThunk(
    'states/get',
    async (_, { dispatch }) => {
        const response = await get(STATES_URL)
        return response
    },
)

const getStatuses = createAsyncThunk(
    'statuses/get',
    async (_, { dispatch }) => {
        const response = await get(STATUS_PRE_PROJECT_URL)
        return response
    },
)

const getCitiesAndUtilies = createAsyncThunk(
    'cities&utilities/get',
    async (stateId: any, { dispatch }) => {
        const cities = await get(CITIES_URL(stateId))
        const utilities = await get(UTILITIES_URL(stateId))
        return { cities, utilities }
    },
)

export const preProjectViewSlice = createSlice({
    name: 'preProjectView',
    initialState: getNewInitialInformationForm(),
    reducers: {
        handleFieldFiller: (state, { payload }) => {
            state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value)]
        },
        clearStateSelected: (state) => {
            state.stateSelected = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccessOpinionIds.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'accessOpinionId', payload)]
            })
            .addCase(getDevelopers.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'developerId', payload)]
            })
            .addCase(getPreProjectById.fulfilled, (state, { payload }) => {
                state.preId = payload.preId                
                payload = {
                    ...payload, 
                    numberFactorCapacity: payload.numberFactorCapacity.toString().replace('.', ','),
                    numberCapacityKwac: parseToDecimal(payload.numberCapacityKwac),
                    numberCapacityKwdc: parseToDecimal(payload.numberCapacityKwdc),
                    dateLastUpdate: format(
                        new Date(payload.dateLastUpdate),
                        'dd/MM/yyyy HH:mm'
                    ),
                }
                rowDataToFieldParser(payload, state.pageFields, true, true);
            })
            .addCase(getStates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getStates.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'stateId', payload)];
                const stateField = findFieldByName(state.pageFields, 'stateId');
                state.stateSelected = stateField?.value;
                state.isLoading = false;
            })
            .addCase(getCitiesAndUtilies.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCitiesAndUtilies.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'cityId', payload.cities)]
                state.pageFields = [...optionsFiller(state.pageFields, 'utilityId', payload.utilities)]
                state.isLoading = false;
            })
            .addCase(getStatuses.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'statusPreprojectId', payload)]
            })

    },
})

export {
    getProjectToValidateById, 
    getPreProjectById,
    getDevelopers,
    getStates,
    getCitiesAndUtilies,
    getStatuses,
    getAccessOpinionIds,
    loadingMiddleware
};

export const { 
    handleFieldFiller, 
    clearStateSelected } = preProjectViewSlice.actions;

export default preProjectViewSlice.reducer
