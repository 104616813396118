import { ApplicationInsights } from '@microsoft/applicationinsights-web';

class ErrorHandler {
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: 'InstrumentationKey=7d4fdcb6-c36e-490a-8334-87b5ddd39e78;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
        instrumentationKey: '7d4fdcb6-c36e-490a-8334-87b5ddd39e78',
      },
    });
    this.appInsights.loadAppInsights();
  }

  handleError(error: any) {
    this.appInsights.trackException({ exception: error });
    console.error(error);
  }
}



export default ErrorHandler;