import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getV2 } from "../../services/agent";
import { setDialog } from "../Dialog/dialogReducer";
import HttpResponseHandler from "../Error/httpResponseHandler";
import loadingMiddleware from '../Loading/loadingMiddleware';
import { parseToDecimal } from "../../services/utils";

const PRE_PROJECT_IMPORT_LIST = 'pre-projects/imports';

const getImportDetailById = createAsyncThunk(
    'importDetailById/get',
    async (id: number, { dispatch }) => {
        const response = await getV2(PRE_PROJECT_IMPORT_LIST + '/' + id + '/contents')
        const responseJson = await response.json()

        if (response.status === 200) {
            return responseJson;
        } else {
            dispatch(setDialog({
                title: 'Erro ao buscar detalhes de importação',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
            }))
            return '';
        }
    }
)

const getPreProjectImportGrid = createAsyncThunk(
    'getPreProjectImportGrid',
    async (_, { dispatch }) => {
        const response = await get(PRE_PROJECT_IMPORT_LIST)
        return response;
    }
)

const initialState = {
    rows: [],
    rowSelected: []
}

export const preProjectImportGridSlice = createSlice({
    name: 'preProjectImportGrid',
    initialState,
    reducers: {
        cleanRowSelected: (state) => {
            state.rowSelected = initialState.rowSelected
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPreProjectImportGrid.fulfilled, (state, { payload }) => {
                const transformedPayload = payload.map((item: any) => ({
                    id: item.importPreProjectId,
                    fileName: item.fileName,
                    fileTypeName: item.fileTypeName,
                    dateRegistration: item.dateRegistration,
                    userRegistrationName: item.userRegistrationName
                }));
                state.rows = transformedPayload;
            })
            .addCase(getImportDetailById.fulfilled, (state, { payload }) => {
                if (payload.length > 0) {
                    const transformedPayload = payload.map((item: any) => ({
                        id: item.importDetailsPreprojectId,
                        importPreprojectId: item.importPreprojectId,
                        isAccessOpinion: item.isAccessOpinion,
                        nameCity: item.nameCity,
                        nameState: item.nameState,
                        nameDevelop: item.nameDevelop,
                        nameProjectDevelop: item.nameProjectDevelop,
                        nameUtility: item.nameUtility,
                        numberCapacityKwac: parseFloat(item.numberCapacityKwac),
                        numberCapacityKwdc: parseFloat(item.numberCapacityKwdc),
                        numberFactorCapacity: item.numberFactorCapacity?.toString().replace('.', ','),
                        errors: item.errors
                    }));
                    state.rowSelected = transformedPayload;
                } else {
                    state.rowSelected = initialState.rowSelected;
                }
            })
    }
})

export { getPreProjectImportGrid, getImportDetailById, loadingMiddleware };

export const { cleanRowSelected } = preProjectImportGridSlice.actions;

export default preProjectImportGridSlice.reducer;