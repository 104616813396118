import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CustomInputComponent from "./CustomInput";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import SendIcon from '@mui/icons-material/Send';
import { AddCircleOutline } from "@mui/icons-material";
import { useMediaQuery, useTheme } from "@mui/material";

const CustomFormComponent = ({
  fields,
  handleOnChange,
  handleSubmit,
  disabled,
  btnConfig,
}: any) => {
  const [onSubmit, setOnSubmit] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const insideElementHandleOnChange = (props: any) => {
    setOnSubmit(false);
    handleOnChange(props);
  };

  const insideElementHandleSubmit = () => {
    setOnSubmit(true);
    handleSubmit();
  };

  const btnIcon = (type: number) => {
    switch (type) {
      case 1:
        return <AddCircleOutline />
      case 2:
        return <SaveAsIcon />
      case 3:
        return <AttachFileIcon />
      case 4:
        return <SendIcon />
      default:
        return <SaveAsIcon />
    }
  }

  return (
    <form>
      <Grid container direction="row" justifyContent="flex-start" spacing={3}>
        {fields.map((field: any) => {
          if (field.visible === undefined || field.visible === true) {
            return (
              <React.Fragment key={field.id}>
                <Grid item xs={field.type === 4 ? (field.xs || 12) : undefined}>
                  <CustomInputComponent
                    field={field}
                    handleOnChange={handleOnChange}
                  />
                </Grid>
                {field.br && (
                  <Grid style={{ padding: "0" }} item xs={12}></Grid>
                )}
              </React.Fragment>
            );
          } else {
            return null;
          }
        })}
        {btnConfig.visible && (
          <Grid item container xs={isMobile ? 12 : btnConfig.xs || 12} justifyContent="flex-end" alignSelf="flex-end" height={btnConfig.height}>
            <Button
              startIcon={btnIcon(btnConfig.iconType)}
              variant="contained"
              color="secondary"
              href="#contained-buttons"
              disabled={disabled}
              onClick={insideElementHandleSubmit}
              onChange={insideElementHandleOnChange}
            >
              {btnConfig.value || 'Salvar'}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default CustomFormComponent;
