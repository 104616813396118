import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearModal, formValidation, getReasonRefulsals, handleFieldFiller } from "../../reducers/PreProjectDuplicty/reasonRefusalsModalReducer";
import { AppDispatch, RootState } from "../../reducers/store";
import Form from "../Form";
import { TransitionProps } from '@mui/material/transitions';
import UndoIcon from '@mui/icons-material/Undo';
import SaveAsIcon from '@mui/icons-material/SaveAs';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReasonRefusalsModalComponent = () => {
    const fields = useSelector((state: RootState) => state.reasonRefusalsModal.pageFields);
    const {
        modalOpen,
        isFormDisabled } = useSelector((state: RootState) => state.reasonRefusalsModal);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (modalOpen === true) {
            dispatch(getReasonRefulsals())
        }
        // eslint-disable-next-line 
    }, [modalOpen])

    const handleOnChange = (value: any, field: any) => {
        dispatch(handleFieldFiller({ field, value }))
        field.action && dispatch(field.action(value))
    }

    const handleOnClose = () => {
        dispatch(clearModal())
    }

    const handleOnSave = () => {
        dispatch(formValidation())
    }

    return (
        <Dialog
            open={modalOpen}
            TransitionComponent={Transition}
            aria-labelledby="modal-modal-title"
        >
            <DialogTitle>Registrar Motivo</DialogTitle>

            <DialogContent>
                <br />
                <Form fields={fields} handleOnChange={handleOnChange} hasSaveButton={false} />
                <br />
                <DialogActions>
                    <Button
                        startIcon={<UndoIcon />}
                        variant="contained"
                        color="secondary"
                        href="#contained-buttons"
                        disabled={isFormDisabled}
                        onClick={() => { handleOnClose() }}>Voltar</Button>
                    <Button
                        startIcon={<SaveAsIcon />}
                        variant="contained"
                        color="secondary"
                        href="#contained-buttons"
                        disabled={isFormDisabled}
                        onClick={() => { handleOnSave() }}>Salvar</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default ReasonRefusalsModalComponent;