import { notNullValidation } from "../../services/validations"

export function getReasonRefusalModalForm() {
    return {
        isFormDisabled: false,
        isFormValid: true,
        modalOpen: false,
        pageFields: [
            {
                id: 1,
                error: false,
                label: "Tipo",
                name: "reasonRefusalId",
                required: true,
                value: '',
                type: 2,
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 2,
                error: false,
                label: "Código projeto duplicado",
                name: "preProjectId",
                required: false,
                value: '',
                type: 1,
                visible: false
            },
            {
                id: 3,
                error: false,
                label: "Comentário",
                name: "describleReasonRefusal",
                required: true,
                value: '',
                type: 1,
                multiline: true,
                width: '33.5em',
                validation: (value: any) => notNullValidation(value)
            }
        ]
    }
}

export default getReasonRefusalModalForm