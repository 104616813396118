import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../services/agent'
import { setDialog } from '../Dialog/dialogReducer'
import { format } from 'date-fns'
import loadingMiddleware from '../Loading/loadingMiddleware';
import httpResponseHandler from '../Error/httpResponseHandler';
import { parseToDecimal } from '../../services/utils';

const PRE_PROJECT_IMPORTS_URL = "pre-projects/imports"
const PRE_PROJECT_IMPORT_DETAILS_URL = (id: string) => PRE_PROJECT_IMPORTS_URL + `/${id}/contents`
const PRE_PROJECT_IMPORT_CONFIRM_URL = PRE_PROJECT_IMPORTS_URL + `/migrations`

const initialState = {
  fileUploadError: false,
  ImportsRows: [],
  DetailsRows: []
}

const toBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const sendFile = createAsyncThunk(
  'sendfile/preprojectincharge',
  async (data: any, {dispatch}) => {
    let base64File = await toBase64(data)
    base64File = (base64File as string).replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '');
    const contract = {
      extension: 1,
      contentBase64: base64File,
      fileName: data.name,
      userRegisterId: 1
    }
    
    const response = await post('pre-projects/imports', contract)
    const responseJson = await response.json()
    
    JSON.stringify(responseJson)

    dispatch(setDialog({title: 'Importação Pré-projeto', message: httpResponseHandler.getHttpStatusMessage(response.status , JSON.stringify(responseJson))}))

    if (response.status !== 200 && response.status !== 201) {      
      dispatch(newForm())
      if(response.status === 400){
        throw new Error('Erro no layout do arquivo')
      }
    }

    return responseJson
  },
)

//Confirma itens para efetivar pré-cadastro
const confirmImportPreProject = createAsyncThunk(
  'confirmImportPreProject',
  async (data: any, {dispatch}) => {
    const contract = {
      userRegisterId: 1,
      importDetailPreprojectId: data //array de ids
    };

      const response = await post(PRE_PROJECT_IMPORT_CONFIRM_URL, contract);
      const responseJson = await response.json();
      dispatch(setDialog({title: 'Importação Pré-projeto', message: httpResponseHandler.getHttpStatusMessage(response.status , JSON.stringify(responseJson))}))

      if (response.status === 200 || response.status === 201) {        
        dispatch(newForm())
      }
     
      return responseJson;
  }
);


const getPreProjectImports = createAsyncThunk(
  'getPreProjectImports',
  async (_, {dispatch}) => {
    const response = await get(PRE_PROJECT_IMPORTS_URL)
    return response
  },
)

const getPreProjectImportsDetails = createAsyncThunk(
  'getPreProjectImportsDetails',
  async (importId: any, {dispatch}) => {
    const response = await get(PRE_PROJECT_IMPORT_DETAILS_URL(importId))
    return response
  },
)

export const preprojetoinchargeSlice = createSlice({
  name: 'preprojetoincharge',
  initialState,
  reducers: {
    newForm: (state) =>{
      state.DetailsRows = initialState.DetailsRows
    }
  }, 
  extraReducers: (builder) => {
    builder
    .addCase(sendFile.fulfilled, (state, {payload}) => {
      getPreProjectImportsDetails(payload)
    })  
    .addCase(confirmImportPreProject.fulfilled, (state, {payload}) => {
     // console.log('Payload case confirmado' + payload)
    })  
    .addCase(getPreProjectImportsDetails.fulfilled, (state, {payload}) => {
      if(payload.status === 400){      
        return;
      }
      const transformedPayload = payload.map((item: any) => ({
        importDetailsPreprojectId: item.importDetailsPreprojectId,
        nameCity: item.nameCity,
        nameState: item.nameState,
        nameDevelop: item.nameDevelop,
        nameUtility: item.nameUtility,
        numberCapacityKwac: parseToDecimal(item.numberCapacityKwac),
        numberCapacityKwdc: parseToDecimal(item.numberCapacityKwdc),
        numberFactorCapacity: item.numberFactorCapacity?.toString().replace('.', ','),
        accessOpinionName: item.accessOpinionName,
        isAccessOpinion: item.isAccessOpinion,
        importPreprojectId: item.importPreprojectId,
        nameProjectDevelop: item.nameProjectDevelop,
        errors: item.errors
      }))

      state.DetailsRows = transformedPayload;
    })
    .addCase(getPreProjectImports.fulfilled, (state, {payload}) => {      
      const transformedPayload = payload.map((item: any) => ({
        importPreProjectId : item.importPreProjectId,
        fileName: item.fileName + '.' + item.fileTypeName,        
        inError: item.inError === false ? "Sucesso" : "Erro",
        dateRegistration: format(new Date(item.dateRegistration), 'dd/MM/yyyy HH:mm')
      }));
      state.ImportsRows = transformedPayload;
    })
  },
})

export { 
  sendFile, 
  confirmImportPreProject, 
  getPreProjectImports, 
  getPreProjectImportsDetails, 
  toBase64,
  loadingMiddleware
}

export const {newForm } = preprojetoinchargeSlice.actions

export default preprojetoinchargeSlice.reducer
