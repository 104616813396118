import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { UserState } from '../../types'
import { loginRequest } from "../../config/authConfig";
import { get, post, setUserToken } from "../../services/agent"

const GET_USER_ID_BY_USER_NAME = (userName: string)=> `users/${userName}`
const CREATE_USER_URL = `users`

const initialState: UserState = { isAuthorized: false}

const getToken = createAsyncThunk(
  'token/get',
  async ({ instance, accounts }: any, { dispatch }) => {
    return instance
      .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
      })
      .then((response: any) => {
        return response.accessToken
      })
  },
)

const getUser = createAsyncThunk(
  'getuser',
  async (_, { dispatch, getState }) => {
    const { user }: any = getState();
    const response = await get(GET_USER_ID_BY_USER_NAME(user.userName))
    if(response.status === 404){
      dispatch(createUser())
      const response = await get(GET_USER_ID_BY_USER_NAME(user.userName))
      return response;
    }
    return response;
  },
);

const createUser = createAsyncThunk(
  'createUser',
  async (_, { getState }) => {
    const { user }: any = getState();
    const payload =  {      
      name: user.userName,
      nameUserFull: user.name, 
      userRegistrationId: 1     
    }
    await post(CREATE_USER_URL, payload) 
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authorizeUser: (state, { payload }) => {
      if(payload.accounts.length === 0 ){
        payload.instance.loginRedirect(loginRequest).catch((e: any) => {
        });
      }else{
        const data = payload.accounts[0];
        state.isAuthorized = true;
        state.name = data.name;
        state.userName = data.username;   
        state.token = data.idToken;
        console.log("::Token::")
        console.log(data.idToken)
        console.log("::Token::")
      }
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getToken.fulfilled, (state, { payload }: any) => {
        state.token = payload
        setUserToken(payload)
      })
      .addCase(getUser.fulfilled, (state, { payload }: any) => { 
        state.userRegistrationId = payload.id
      })
  },
})

export { getToken, getUser }

export const { logout, authorizeUser } = userSlice.actions

export default userSlice.reducer