import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../reducers/store";
import { Button, Grid, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import PageContainerComponent from "../../component/PageContainer";
import Form from "../../component/Form";
import VisibilityIcon from '@mui/icons-material/Visibility'
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  clearDuplicityProjectSelected,
  clearDuplicityStateSelected,
  formValidation,
  getDuplicityAccessOpinionIds,
  getDuplicityCitiesAndUtilies,
  getDuplicityDevelopers,
  getDuplicityStates,
  getDuplicityStatuses,
  getPreProjectById,
  getProjectById,
  handleFieldFiller,
  listProjectDuplicates
} from '../../reducers/PreProjectDuplicty/preProjectDuplicityEditReducer';
import ReasonRefusalsModalComponent from '../../component/modals/ReasonRefusals.modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { openModal } from "../../reducers/PreProjectDuplicty/reasonRefusalsModalReducer";
import { ptBR } from '@mui/x-data-grid/locales';
import { useNavigate } from 'react-router-dom';
import { setProjectData } from '../../reducers/ProjectValidation/projectToValidateReducer';
import { format } from 'date-fns';

const PreProjetoDuplicityEditComponent = () => {
  const fields = useSelector((state: RootState) => state.preProjetoDuplicityEdit.pageFields);
  const rows = useSelector((state: RootState) => state.preProjetoDuplicityEdit.projectsDuplicated);
  const {
    stateSelected,
    preProjectId,
    projectSelected,
    isFormDisabled } = useSelector((state: RootState) => state.preProjetoDuplicityEdit);
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const initPage = () => {
    dispatch(getDuplicityAccessOpinionIds())
    dispatch(getDuplicityStatuses())
    dispatch(getDuplicityDevelopers())
    dispatch(getDuplicityStates())
    dispatch(getDuplicityCitiesAndUtilies())
    dispatch(listProjectDuplicates(preProjectId ? preProjectId : 0))
  }

  useEffect(() => {
    if (stateSelected) {
      dispatch(getDuplicityCitiesAndUtilies())
    }
    // eslint-disable-next-line 
  }, [stateSelected])

  useEffect(() => {
    initPage()
    // eslint-disable-next-line 
  }, [dispatch])

  const handleOnChange = (value: any, field: any) => {
    dispatch(handleFieldFiller({ field, value }))
    field.action && dispatch(field.action(value))
  }

  const handleSubmit = () => {
    dispatch(formValidation())
  }

  const handleViewClick = async (row: any) => {
    await dispatch(getPreProjectById(row.projectPreId))
    await dispatch(getProjectById(row.projectId));
  }

  useEffect(() => {
    if (projectSelected.id) {
      dispatch(setProjectData(projectSelected))
      dispatch(clearDuplicityProjectSelected());
      navigate('/project-validation');
    }
    // eslint-disable-next-line 
  }, [projectSelected])

  const setModalOpen = () => {
    dispatch(openModal(preProjectId))
  }

  const columns: GridColDef[] = [
    {
      field: "preProjectActions",
      headerName: "Ações",
      width: 100,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleViewClick(params.row)}
          size="small"
          title="Ver"
        >
          <VisibilityIcon color="primary" />
        </IconButton>
      ),
    },
    { field: 'projectPreId', headerName: 'ID pré-projeto', width: 150, filterable: true },
    { field: 'coPreProject', headerName: 'Código projeto', width: 150, filterable: true },
    { field: 'nameProjectDevelop', headerName: 'Projeto (Ref. develop)', width: 250, filterable: true },
    {
      field: "dateRegistration",
      headerName: "Incluído em",
      width: 150,
      filterable: true,
      valueFormatter: (params) => {
        return params.value ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm") : '';
      },
    },
    { field: 'userRegistrationName', headerName: 'Incluído por', width: 200, filterable: true },
    { field: 'statusProject', headerName: 'Status', width: 150, filterable: true }
  ];

  return (
    <PageContainerComponent title="Análise de duplicidade de pré-projeto" style={{ marginLeft: isMobile ? 60 : 3000 }}>
      <br />
      <Form fields={fields} handleOnChange={handleOnChange} hasSaveButton={false} />
      <br />
      <hr />
      <br />
      <DataGrid localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.id}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{ toolbar: GridToolbar }}
      />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={3}>
        <Grid item xs={1}>
          <Button
            startIcon={<SaveAsIcon />}
            variant="contained"
            color="secondary"
            href="#contained-buttons"
            disabled={isFormDisabled}
            onClick={() => { handleSubmit() }}>Salvar</Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            startIcon={<HighlightOffIcon />}
            variant="contained"
            color="secondary"
            href="#contained-buttons"
            disabled={isFormDisabled}
            onClick={() => { setModalOpen() }}>Recusar</Button>
        </Grid>
      </Grid>
      <ReasonRefusalsModalComponent />
    </PageContainerComponent>
  )
}

export default PreProjetoDuplicityEditComponent;