import React, { useRef } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Tooltip,
  RadioGroup,
  FormLabel,
  FormHelperText,
  Grid,
  Button,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { getMaskFunction } from '../services/utils';

const CustomInputComponent = ({ field, handleOnChange }: any) => {
  const inputRef = useRef(null);
  const handleOnChangeInside = (event: any) => {
    const maskedValue = field.mask
      ? getMaskFunction(field.mask)(event.target.value)
      : event.target.value;
    handleOnChange(maskedValue, field);
  };

  const handleOnFileChangeInside = (event: any) => {
    handleOnChange(event.target.files[0], field);

    if (inputRef.current) {
      //@ts-ignore
      inputRef.current.value = null
    }
  }

  const disabledStyle = field.disabled
    ? { color: "gray", backgroundColor: "#f0f0f0" }
    : {};
  const visibleStyle = field.visible === false ? { display: "none" } : {};

  return (
    <Box style={visibleStyle}>
      <FormControl fullWidth >
        {field.type === 3 ?
          <FormLabel id={`${field.name}-label`} style={disabledStyle}>{field.label}</FormLabel> :
          <InputLabel sx={{ bgcolor: 'white' }} id={`${field.name}-label`} style={disabledStyle}>{field.label}</InputLabel>
        }

        {field.type === 1 && (
          <Tooltip
            children={
              <OutlinedInput
                sx={{ width: (field.width ? field.width : '16em') }}
                error={field.error}
                id={`${field.name}`}
                name={field.name}
                disabled={field.disabled}
                onBlur={field.onBlur}
                type={field.inputType ? field.inputType.toString() : ""}
                style={{
                  border: field.error && "0.1px solid red",
                  ...disabledStyle,
                }}
                value={field.value}
                onChange={handleOnChangeInside}
                readOnly={field.readOnly ? field.readOnly : false}
                startAdornment={
                  field.startAdornment &&
                <InputAdornment position="start">
                  {field.startAdornment}
                </InputAdornment>
                }
                endAdornment={
                  field.adornment && (
                    <InputAdornment position="start">
                      {field.adornment}
                    </InputAdornment>
                  )
                }
              />
            }
            title={field.required && !field.value ? "Este campo é obrigatório" : ""}
            followCursor
          />
        )}

        {field.type === 2 && (
          <Tooltip
            title={
              field.options?.length === 0 && !field.required
                ? "Esse campo tem pré-requisito"
                : field.required && !field.value
                ? "Este campo é obrigatório"
                : ""
            }
            followCursor
          >
            <Select
              sx={{ width: field.width ? field.width : "16em" }}
              error={field.error}
              disabled={field.disabled}
              defaultValue={1}
              id={`${field.name}`}
              labelId={`${field.name}-label`}
              name={field.name}
              label={field.value}
              style={{
                border: field.error && "1px solid red",
                ...disabledStyle,
              }}
              value={field.value}
              onChange={handleOnChangeInside}
              endAdornment={
                field.adornment && (
                  <InputAdornment position="start">
                    {field.adornment}
                  </InputAdornment>
                )
              }
            >
              {field.options &&
                field.options.length > 0 &&
                field.options
                  .filter(
                    (option: any) => !field?.optionsFilter?.includes(option.id)
                  )
                  .map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
            </Select>
          </Tooltip>
        )}

        {field.type === 3 && (
          <Tooltip
            children={
              <RadioGroup
                row
                sx={{ width: "16em", marginBottom: "-0.5em" }}
                aria-labelledby={`${field.name}-label`}
                name="row-radio-buttons-group"
                value={field.value}
                onChange={handleOnChangeInside}
              >
                {field.options &&
                  field.options.length > 0 &&
                  field.options.map((option: any) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.value}
                      disabled={field.disabled}
                      control={<Radio />}
                      label={option.name}
                    />
                  ))}
              </RadioGroup>
            }
            title={field.required && !field.value ? "Este campo é obrigatório" : ""}
            followCursor
          />
        )}

        {field.type === 4 && (
          <Tooltip
            children={
              <OutlinedInput
                sx={{
                  width: field.width || "100%",
                  minWidth: "16em",
                }}
                multiline
                rows={field.rows || 3}
                error={field.error}
                id={`${field.name}`}
                name={field.name}
                disabled={field.disabled}
                type={field.inputType ? field.inputType.toString() : ""}
                style={{
                  border: field.error && "0.1px solid red",
                  ...disabledStyle,
                }}
                value={field.value}
                onChange={handleOnChangeInside}
                readOnly={field.readOnly ? field.readOnly : false}
                startAdornment={
                  field.startAdornment &&
                <InputAdornment position="start">
                  {field.startAdornment}
                </InputAdornment>
                }
              />
            }
            title={field.required && !field.value ? "Este campo é obrigatório" : ""}
            followCursor
          />
        )}

        {
          field.type === 5 &&
          <Grid
            alignItems={"center"}>
            <input
              style={{ display: 'none' }}
              id={`${field.name}`}
              onChange={handleOnFileChangeInside}
              ref={inputRef}
              type="file"
            />
            <label htmlFor={`${field.name}`}>
              <Button
                sx={{
                  marginTop: '0.25em',
                  width: '14em',
                  height: '3.5em'
                }}
                variant="contained"
                component="span">
                UPLOAD DE ARQUIVO
              </Button>
            </label>
          </ Grid>
        }

        {field.error && (
          <FormHelperText
            id="outlined-weight-helper-text"
            style={{ color: "red" }}
          >
            Informação Inválida
          </FormHelperText>
        )}
        {!field.error && field.helpMessage && (
          <FormHelperText id="outlined-weight-helper-text">
            {field.helpMessage}
          </FormHelperText>
        )}

      </FormControl >
    </Box >
  );
};

export default CustomInputComponent;
