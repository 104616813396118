import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { format } from "date-fns"
import { get } from "../../services/agent"
import loadingMiddleware from '../Loading/loadingMiddleware';
import { parseToDecimal } from "../../services/utils";

const PENDING_STATUS = 1
const PROJECT_URL = "projects"
const PRE_PROJECT_URL = "pre-projects"
const PROJECT_PENDING_URL = `${PROJECT_URL}?statusId=${PENDING_STATUS}`

const getprojectById = createAsyncThunk(
    'projectPendingById/get',
    async (id: number, { dispatch }) => {
        const response = await get(PROJECT_URL + '/' + id)
        return response
    },
)

const getPreprojectById = createAsyncThunk(
    'preprojectByIdProjectPendingGrid/get',
    async (id: number, { dispatch }) => {
        const response = await get(PRE_PROJECT_URL + '/' + id)
        return response
    },
)

const getprojectGrid = createAsyncThunk(
    'getprojectPendingGrid',
    async (_, { dispatch }) => {
        const response = await get(PROJECT_PENDING_URL)
        return response
    },
)

const initialState = {
    rows: [],
    rowSelected: {
        id: null
    },
    preProject: {}
}

export const projectPendgindGridSlice = createSlice({
    name: 'projectPendingGrid',
    initialState,
    reducers: {
        cleanRowSelected: (state) => {
            state.rowSelected = initialState.rowSelected
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getprojectGrid.fulfilled, (state, { payload }) => {
                const transformedPayload = payload.result.map((item: any) => ({
                    id: item.id,
                    preId: item.preId,
                    stateName: item.nameState,
                    stateAcronym: item.stateAcronym,
                    cityName: item.nameCity,
                    dateLastUpdate: item.dateLastUpdate,
                    name: item.name,
                    coproject: item.co,
                    nameProjectDevelop: item.nameProjectDevelop,
                    statusName: item.nameStatus,
                    statusprojectId: item.statusId,
                    userRegistrationName: item.userRegistrationName,
                    utilityName: item.nameUtility,
                    numberCapacityKwdc: parseToDecimal(item.numberCapacityKwdc),
                    numberCapacityKwac: parseToDecimal(item.numberCapacityKwac),
                    numberFactorCapacity: item.numberFactorCapacity?.toString().replace('.', ','),
                    accessOpinionName: item.accessOpinionName,
                }));
                state.rows = transformedPayload;
            })
            .addCase(getprojectById.fulfilled, (state, { payload }) => {
                state.rowSelected = {
                    ...payload,
                    dateRegistration: format(new Date(payload.dateRegistration), 'dd/MM/yyyy HH:mm'),
                    dateLastUpdate: format(new Date(payload.dateLastUpdate), 'dd/MM/yyyy HH:mm')
                }
            })
            .addCase(getPreprojectById.fulfilled, (state, { payload }) => {
                state.preProject = {
                    ...payload,
                    dateLastUpdate: format(new Date(payload.dateLastUpdate), 'dd/MM/yyyy HH:mm')
                }
            })
    }
})

export { getprojectGrid, loadingMiddleware, getprojectById, getPreprojectById };

export const { cleanRowSelected } = projectPendgindGridSlice.actions;

export default projectPendgindGridSlice.reducer;