import React from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import {
  RouterProvider,
} from "react-router-dom";
import router from "./routes";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from "redux-persist";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const persistor = persistStore(store);

function App() {  

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"           
          />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;