export interface PageFieldOptions{
    id: number,
    name: string
}

export interface PageField {
    id: number, //id de enumeração
    error: boolean, // serve para validação, informa se o campo foi validado e encontrado erro
    label?: string, // valor a ser exibido
    name: string, //servira como chave quando enviar o formulário
    required: boolean, // mostra se é requirido TO-DO: a remover
    value: any, //servira como value quando enviar o formulário
    type: number, // Tipo de exibição na tela 1 para texto, 2 para select e 3 para option
    options?: PageFieldOptions[], // opções para selação, funciona para os types 2 e 3 
    adornment?: string, // mostra valor no final no campo de texto, só funciona para tipo 1
    inputType?: InputType,
    validation?: any, // dispara função de validação do campo
    action?: any, //dispara função ao ser resolvido
    helpMessage?: string, // mensagem de ajuda para 
    disabled?: boolean,
    visible?: boolean,
    readOnly?: boolean,
    mask?: any, // máscara para ajuda na formatação dos campos
    rows?: number // Total de linhas no campo de tipo 4 (outlined multiline), para definir altura
}

export interface Form{
    isFormValid: boolean,
    pageFields: PageField[]
}

export interface DialogState {
    isOpen: boolean,
    title: string,
    message: string
    messageArray?: any[]
    redirect?: ''
    error?: boolean
}

 export interface LoadingState {
     isLoading: boolean
 }

 export interface UserState {
    name?: string,
    userName?: string,
    isAuthorized: boolean,
    userRegistrationId?: number,
    role?: string,
    token?: string
}

export interface File {
    name?: string,
    type?: string,
    size?: number
}

export enum InputType {
    NUMBER,
    STRING,
    BOOLEAN
}

export interface SystemForm {
    name: string,
    userRegistrationId: number,
    dateRegistration: string,
    id: number
}