import { Grid, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cleanRowSelected,
  getPreprojectById,
  getprojectById,
} from "../../../reducers/Project/projectGridReducer";
import { AppDispatch, RootState } from "../../../reducers/store";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { ptBR } from "@mui/x-data-grid/locales";
import { setProjectData } from "../../../reducers/ProjectValidation/projectToValidateReducer";
import { format } from "date-fns";

const Projects = () => {
  const dispatch: AppDispatch = useDispatch();
  const rows = useSelector((state: RootState) => state.powerSearchGrids.projectRows);
  const rowSelected = useSelector(
    (state: RootState) => state.projectGrid.rowSelected
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (rowSelected.id) {
      dispatch(setProjectData(rowSelected));
      dispatch(cleanRowSelected());
      navigate("/project-validation");
    }
    // eslint-disable-next-line
  }, [rowSelected]);

  const handleRowClick = async (row: any) => {
    await dispatch(getPreprojectById(row.preId));
    await dispatch(getprojectById(row.id));
  };

  const columns: GridColDef[] = [
    {
      field: "projectActions",
      headerName: "Ações",
      width: 100,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleRowClick(params.row)}
          size="small"
          title="Ver"
        >
          <AppRegistrationIcon color="primary" />
        </IconButton>
      ),
    },
    { field: "coproject", headerName: "Código", width: 150, filterable: true },
    {
      field: "nameProjectDevelop",
      headerName: "Projeto (Ref. develop)",
      width: 250,
      filterable: true,
    },
    { field: "name", headerName: "Projeto", width: 150, filterable: true },
    { field: "stateName", headerName: "Estado", width: 80, filterable: true },
    { field: "cityName", headerName: "Cidade", width: 200, filterable: true },
    {
      field: "utilityName",
      headerName: "Distribuidora",
      width: 200,
      filterable: true,
    },
    {
      field: "numberCapacityKwac",
      headerName: "Capacidade instalada kWac", 
      width: 190,
      filterable: true,
    },
    { field: 'numberFactorCapacity', headerName: 'Fator', width: 80, filterable: true },
    {
      field: "numberCapacityKwdc",
      headerName: "Capacidade instalada kWdc",
      width: 190,
      filterable: true,
    },
    { field: 'accessOpinionName', headerName: 'Parecer de acesso', width: 150, filterable: true },
    {
      field: "dateLastUpdate",
      headerName: "Última atualização",
      width: 150,
      filterable: true,
      valueFormatter: (params) => {      
        return params.value ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm") : '';
      },
    },
    { field: "statusName", headerName: "Status", width: 200, filterable: true },
  ];

  return (
    <Grid
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      container      
      spacing={0}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom>
          Projetos
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.id}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          slots={{ toolbar: GridToolbar }}
        />
      </Grid>
    </Grid>
  );
};

export default Projects;