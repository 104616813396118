import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import useConstant from "../hooks/useConstant";
import { debounce } from "../services/utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getPreProjectGridFiltered,
  getPreProjectImportGridFiltered,
  getProjectGridFiltered,
  setKeyword
} from "../reducers/PowerSearchGrids/powerSearchGridsReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../reducers/store";

const SearchComponent = ({ customStyle }: any) => {
  const location = useLocation();
  const pathnames = location.pathname;
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = async (event: any) => {
    if (event?.target.value.trim().length > 0) {
      if (pathnames !== "/power-searching") {
        navigate("/power-searching");
      }
      dispatch(setKeyword(event?.target.value));
      dispatch(getProjectGridFiltered(event?.target.value));
      dispatch(getPreProjectGridFiltered(event?.target.value));
      dispatch(getPreProjectImportGridFiltered(event?.target.value));
    }
  };

  const debouncedOnChange = useConstant(() => debounce(onChange, 2000));

  const Search = customStyle
    ? customStyle
    : styled("div")(({ theme }) => ({
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Pesquisar"
        inputProps={{ "aria-label": "search" }}
        onChange={debouncedOnChange}
      />
    </Search>
  );
};

export default SearchComponent;
