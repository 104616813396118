import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=7d4fdcb6-c36e-490a-8334-87b5ddd39e78;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=d2996e81-d4d1-4505-9219-683c637ac0d1',
    instrumentationKey: '7d4fdcb6-c36e-490a-8334-87b5ddd39e78',
  },
});
appInsights.loadAppInsights();

const errorMiddleware = (store: any) => (next: (arg0: any) => any) => (action: any) => {
  try {
    appInsights.trackTrace({ message: action.type });
    return next(action);
  } catch (error) {
    appInsights.trackException({ exception: error as Error });
    return Promise.reject(error);
  }
};

export default errorMiddleware;