
import PageContainerComponent from "../../component/PageContainer";
import { useMediaQuery, useTheme } from "@mui/material";

const HomeComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PageContainerComponent
      title=""
      style={{ marginLeft: isMobile ? 60 : 300 }}
    ></PageContainerComponent>
  );
};

export default HomeComponent;
