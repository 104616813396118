export const errorTitleStyle = {
    fontWeight: "bold",
    color: "#BBB",
    minHeight: "auto"
};
export const typographyStyle = { color: "#FFF", minHeight: "auto" };
export const accordionStyle = { boxShadow: "none", mt: 1, mb: 1 };
export const mainAccordionStyle = { mt: 1, mb: 1 };
export const accordionSummaryStyle = {
    bgcolor: "#4A4A4A",
    mt: 1,
    ".css-1betqn-MuiAccordionSummary-content": { m: 0 },
};
export const mainAccordionSummaryStyle = {
    bgcolor: "#4A4A4A80",
    mt: 1,
    ".css-1betqn-MuiAccordionSummary-content": { m: 0 },
};
export const linkStyle = {
    minHeight: "auto",
    fontSize: "10px !important"
};