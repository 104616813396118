import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PageContainerComponent from "../../component/PageContainer";
import FullWidthTabs from "../../component/FullWidthTabs";
import FullWidthGrid from "../../component/FullWidthGrid";
import { Box } from "@mui/material";
import { AppDispatch, RootState } from "../../reducers/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NetworkConnection from "./tabs/NetworkConnection";
import ProjectAnalyzes from "./tabs/ProjectAnalyzes";
import GeoreferencingAnalyzes from "./tabs/GeoreferencingAnalyzes";
import InitialInformation from "./tabs/InitialInformation";
import NoteHistory from "./tabs/NoteHistory";
import ProjectDocuments from "./tabs/ProjectDocuments";
import { getAllJustifications } from "../../reducers/ProjectValidation/projectToValidateReducer";
import ProjectValidationModal from "../../component/modals/ProjectValidation.modal";
import { setProjectValidationModalIdle } from "../../reducers/ProjectValidation/ProjectValidationModalReducer";

const ProjectValidation = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { projectSelected, allJustifications } = useSelector(
    (state: RootState) => state.projectToValidate
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDialogOpen = useSelector((state: RootState) => state.projectValidationModal.isOpen)
  useEffect(() => {
    if (!projectSelected.id) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [projectSelected]);

  const initPage = async () => {
    dispatch(getAllJustifications());
  };

  useEffect(() => {
    if (!allJustifications || !allJustifications.length) {
      initPage();
    }
    // eslint-disable-next-line
  }, [allJustifications]);

  const tabs = [
    { label: "Informação inicial", content: <InitialInformation /> },
    { label: "Conexão de rede", content: <NetworkConnection /> },
    { label: "Análise do projeto", content: <ProjectAnalyzes /> },
    {
      label: "Análise de georreferenciamento",
      content: <GeoreferencingAnalyzes />,
    },
    { label: "Documentos", content: <ProjectDocuments /> },
    { label: "Histórico de anotações", content: <NoteHistory /> },
  ];

  const mapProjectToGridData = (projectData: any) => {
    return [
      { key: "ID do Projeto", value: projectData.co },
      { key: "Projeto (Ivi Reference)", value: projectData.nameProjectDevelop },
      { key: "Status do Projeto", value: projectData.nameStatus },
      { key: "Data do Registro", value: projectData.dateRegistration },
      { key: "Estado", value: projectData.nameState },
      { key: "Cidade", value: projectData.nameCity },
      { key: "Distribuidora", value: projectData.nameUtility },
      { key: "Ultima Atualização", value: projectData.dateLastUpdate },
    ];
  };

  const projectDataForGrid = projectSelected
    ? mapProjectToGridData(projectSelected)
    : [];

  return (
    <PageContainerComponent
      title="Validação Projeto"
      style={{ marginLeft: isMobile ? 60 : 300 }}
    >
      <Box sx={{ m: 2 }}>
        <FullWidthGrid grids={projectDataForGrid} />
      </Box>
      <FullWidthTabs tabs={tabs} />
      <ProjectValidationModal open={isDialogOpen} handleClose={() => dispatch(setProjectValidationModalIdle())} />
    </PageContainerComponent>
  );
};

export default ProjectValidation;
