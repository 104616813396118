import React, { useEffect } from 'react';
import { Button, Grid, IconButton } from "@mui/material";
import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid";
import CustomFormComponent from "../../../component/CustomForm";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../reducers/store';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    checkAnalysis,
    downloadFile,
    formValidation,
    getFileTypes,
    getProjectFiles,
    handleFieldFiller,
    removeFile
} from '../../../reducers/ProjectValidation/tabs/projectDocumentsReducer';

const ProjectDocuments = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { projectSelected } : any = useSelector(
        (state: RootState) => state.projectToValidate
      );
    const {
        pageFields,
        btnConfig,
        documents } = useSelector((state: RootState) => state.projectDocuments)

    const initPage = async () => {
        dispatch(getFileTypes())
        dispatch(getProjectFiles())
    }

    useEffect(() => {
        initPage();
        // eslint-disable-next-line 
    }, [dispatch])

    const handleOnChange = (value: any, field: any) => {
        dispatch(handleFieldFiller({ field, value }))
        field.action && dispatch(field.action(value))
    }

    const handleSubmit = () => {
        dispatch(formValidation())
    }

    function handleRowViewClick(row: any): void {
        dispatch(downloadFile(row))
    }

    function handleRowDeleteClick(row: any): void {
        dispatch(removeFile(row.id))
    }

    function handleCompleteAnalysis(row: any): void {
        dispatch(checkAnalysis())
    }

    const columns: GridColDef[] = [
        {
            field: "docView",
            headerName: "Visualizar",
            width: 150,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <IconButton
                    onClick={() => handleRowViewClick(params.row)}
                    size="small"
                    title="Excluir"
                >
                    <VisibilityIcon color="primary" />
                </IconButton>
            ),
        },
        {
            field: "fileTypeName",
            headerName: "Tipo de Arquivo",
            width: 400,
            filterable: true,
        },
        {
            field: "dateRegistration",
            headerName: "Data da Inclusão",
            width: 300,
            filterable: true,
        },
        {
            field: "userName",
            headerName: "Incluído Por",
            width: 400,
            filterable: true,
        },
        {
            field: "docDeletion",
            headerName: "Excluir",
            width: 150,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <IconButton
                    onClick={() => handleRowDeleteClick(params.row)}
                    size="small"
                    title="Excluir"
                >
                    <DeleteForeverIcon color="primary" />
                </IconButton>
            ),
        }
    ];

    return (
        <React.Fragment>
            <br />
            <CustomFormComponent
                fields={pageFields}
                handleOnChange={handleOnChange}
                handleSubmit={handleSubmit}
                btnConfig={btnConfig} />
            <hr />
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ width: "100%", minWidth: "16em" }}
                rows={documents}
                getRowId={(row: any) => row.id}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                columns={columns}
            />
            <br />
            <Grid
                item
                container
                xs={12}
                justifyContent="flex-start"
                alignSelf="flex-end">
                <Button
                    startIcon={<CheckCircleOutlineIcon />}
                    variant="contained"
                    color="secondary"
                    href="#contained-buttons"
                    onClick={handleCompleteAnalysis}
                    disabled={['Recomendado', 'Reprovado'].includes(projectSelected.nameStatus)}
                >
                    Concluir Análise
                </Button>
            </Grid>
        </React.Fragment>
    )
}

export default ProjectDocuments;
