import { configureStore } from '@reduxjs/toolkit';
import errorMiddleware from './Error/errorMiddleware';
import persistedReducer from './persistReducers';
import loadingMiddleware from './Loading/loadingMiddleware';

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
      getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: [
            'preprojeto.pageFields',
            'networkConnection.pageFields',
            'networkConnection.justificationForm.pageFields',
            'projectAnalyzes.pageFields',
            'projectAnalyzes.justificationForm.pageFields',
            'georeferencingAnalyzes.pageFields',
            'georeferencingAnalyzes.justificationForm.pageFields',
            'preProjetoDuplicityEdit.pageFields',
            'reasonRefusalsModal.pageFields',
            'payload.instance',
            'noteHistory.pageFields',
            'projectDocuments.pageFields',
            'supportMailSender.pageFields',
            'payload.field.validation',
            'initialInformation.pageFields',
            'preProjectView.pageFields',
          ],
        },
      }).concat(errorMiddleware).prepend(loadingMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch