import { createSlice } from "@reduxjs/toolkit";
import loadingMiddleware from '../Loading/loadingMiddleware';

const initialState = {
    rows: []
}

export const preProjectImportDetailSlice = createSlice({
    name: "preProjectImportDetail",
    initialState,
    reducers: {
        setImportDetailData: (state, { payload }) => {
            state.rows = payload;
        }
    }
})

export {loadingMiddleware}

export const { setImportDetailData } = preProjectImportDetailSlice.actions;

export default preProjectImportDetailSlice.reducer