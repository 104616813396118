import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "../../reducers/store";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { completeAnalysis } from "../../reducers/ProjectValidation/tabs/projectDocumentsReducer";

const errorMessages = [
  "Projeto não existe",
  "Projeto já finalizado",
  "Aba: ANÁLISE DE PROJETO, precisa ser preenchida",
  "Aba: ÁNALISE DE GEOREFERENCIAMENTO, precisa ser preenchida",
  "Aba: CONEXÃO DE REDE, precisa ser preenchida",
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectValidationModal = ({ open, handleClose }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { title, message, messageArray, redirect } = useSelector(
    (state: RootState) => state.projectValidationModal
  );

  const error = errorMessages.some((phrase) => message.includes(phrase));
  const msg = React.useMemo(() => {
    return message.replace(
      "Requisição inválida. Por favor, verifique se algum dado deixou de ser preenchido.</br></br>",
      "Com base no preenchimento, o projeto será recusado.</br></br> <strong>Motivo da recusa:</strong></br>"
    );
  }, [message]);
  
  const handleOkClick = () => {
    handleClose();
    if (redirect) {
      navigate(redirect);
    }
  };

  const processMessage = (message: any) => {
    return { __html: message };
  };

  function handleCompleteAnalysis(): void {
    handleClose();
    dispatch(completeAnalysis());
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <span dangerouslySetInnerHTML={processMessage(msg)} />
          {messageArray && (
            <ul>
              {messageArray.map((x: string) => (
                <li>{x[0]}</li>
              ))}
            </ul>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
        {!error ? (
          <Box>
            <DialogContentText id="alert-dialog-slide-description">
              Deseja editar os dados?
            </DialogContentText>
            <Button sx={{ margin: "0 10px" }} autoFocus onClick={handleOkClick}>
              Sim
            </Button>
            <Button onClick={handleCompleteAnalysis}>Não</Button>
          </Box>
        ) : (
          <Button onClick={handleOkClick}>OK</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ProjectValidationModal;
