import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSupportMailSenderInitialState } from "../../data/forms/email/supportMailSender";
import { dataParser, handleFiller } from "../../services/utils";
import loadingMiddleware from '../Loading/loadingMiddleware';

const sendMail = createAsyncThunk(
    'sendSupportMail',
    async(data: any, {dispatch }) =>{
        console.log(data)
    }
)

const formValidation = createAsyncThunk(
    'formValidation/supportMail',
    async (_, { getState, dispatch }) => {
        const { supportMailSender }: any = getState();
        const { isFormValid, pageFields } = supportMailSender
        const dataToSend = dataParser(pageFields)

        if (isFormValid) {
            dispatch(sendMail(dataToSend))
        }

        return ''
    },
)

export const supportMailSenderSlice = createSlice({
    name: 'supportMailSender',
    initialState: getSupportMailSenderInitialState(),
    reducers: {
        handleFieldFiller: (state, { payload }) => {
            state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value)]
        }
    }
})

export {
    formValidation,
    loadingMiddleware,
};

export const {
    handleFieldFiller,
} = supportMailSenderSlice.actions

export default supportMailSenderSlice.reducer