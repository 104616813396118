import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReasonRefusalModalForm } from "../../data/forms/reasonRefusalsModal";
import { get, post } from "../../services/agent";
import { dataParser, handleFiller, insertValueIntoFieldByName, optionsFiller } from "../../services/utils";
import { setDialog } from "../Dialog/dialogReducer";
import HttpResponseHandler from "../Error/httpResponseHandler";
import loadingMiddleware from '../Loading/loadingMiddleware';

const REFUSALS_URL = "reason-refusals"
const PRE_PROJECT_URL = "pre-projects"

const getReasonRefulsals = createAsyncThunk(
    'getReasonRefulsals',
    async (_, { dispatch }) => {
        const response = await get(REFUSALS_URL + '/74')
        return [response]
    }
)

const savePreProjectRefusal = createAsyncThunk(
    'savePreProjectRefusal/post',
    async (data: any, { dispatch }) => {
        const response = await post(PRE_PROJECT_URL + '/' + REFUSALS_URL, data)
        const responseJson = await response.json()

        if (response.status === 200 || response.status === 201) {
            dispatch(setDialog({
                title: 'Pré-projeto recusado com sucesso!',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson)),
                redirect: '/preproject-duplicity'
            }))
        } else {
            dispatch(setDialog({
                title: 'Erro ao recusar pré-projeto!',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
            }))
        }
        dispatch(enableSave())
        dispatch(clearModal())
    }
)

const formValidation = createAsyncThunk(
    'formValidationRefusalModal',
    async (_, { getState, dispatch }) => {
        dispatch(disableSave())
        const { reasonRefusalsModal }: any = getState();
        const { isFormValid, pageFields } = reasonRefusalsModal
        const dataToSend = dataParser(pageFields)

        if (isFormValid) {
            dispatch(savePreProjectRefusal(dataToSend))
        } else {
            dispatch(enableSave())
        }

        return ''
    },
)

export const reasonRefusalsModalSlice = createSlice({
    name: 'reasonRefusalsModal',
    initialState: getReasonRefusalModalForm(),
    reducers: {
        handleFieldFiller: (state, { payload }) => {
            state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value)]
        },
        clearModal: (state) => {
            const initialState = getReasonRefusalModalForm();
            state.pageFields = initialState.pageFields;
            state.modalOpen = initialState.modalOpen;
        },
        openModal: (state, { payload }) => {
            state.modalOpen = true;
            insertValueIntoFieldByName(state.pageFields, 'preProjectId', payload);
        },
        enableSave: (state) => {
            state.isFormDisabled = false;
        },
        disableSave: (state) => {
            state.isFormDisabled = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReasonRefulsals.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'reasonRefusalId', payload)]
            })
    }
})

export {
    getReasonRefulsals,
    formValidation,
    loadingMiddleware
}

export const {
    handleFieldFiller,
    clearModal,
    openModal,
    enableSave,
    disableSave } = reasonRefusalsModalSlice.actions

export default reasonRefusalsModalSlice.reducer