import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { CircularProgress, DialogContent } from "@mui/material";

const LoadingOverlay = ({ open }: any) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent
        sx={{ overflow: "hidden", maxHeight: "none", maxWidth: "none" }}
      >
        <CircularProgress sx={{ color: "#DFFF02" }} />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingOverlay;
