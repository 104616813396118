import { PageField, InputType } from '../types'

export const optionsFiller = (array: any, name: string, options: any) =>
  array.map((x: any) => {
    if (x.name === name) {
      x = {
        ...x,
        options
      }
    }
    return x
  })


export const handleFiller = (array: any, field: any, value: any) =>
  array.map((x: any) => {
    if (x.id === field.id) {
      x = {
        ...field,
        error: false,
        value
      }
    }
    return x
  })


export const findFieldByName = (fields: PageField[], name: string) => {
  return fields.find((field: PageField) => field.name === name)
}

export const insertValueIntoFieldByName = (fields: PageField[], name: string, value: any) => {
  fields.map((field: any) => {
    if (field.name === name) {
      field.value = value;
      field.error = false;
    }
    return field;
  });
  return fields;
}

export const inputValidation = (fields: any) =>
  fields.map((field: any) => {
    field.error = field.validation ? field.validation(field.value) : false
    return field
  })

export const dataParser = (fields: PageField[]): any => {
  let dataToSend: any = {}
  fields.map((field: any) => {
    if (field.value !== '')
      dataToSend[field.name] = field.inputType === InputType.BOOLEAN ? Boolean(field.value) : field.value

    return field;
  })
  return dataToSend;
}

export const rowDataToFieldParser = (rowData: any, fields: PageField[], forceVisible?: boolean, forceDisabled?: boolean) => {
  fields.map((field: any) => {
    field.value = rowData[field.name];
    if (forceVisible) {
      field.visible = forceVisible;
    }
    if (forceDisabled) {
      field.disabled = forceDisabled;
    }
    return field;
  });
  return fields;
}

export const onlyNumbers = (value: any): string => {
  const number = `${value}`;
  if (number !== null && number !== undefined && number !== '') {
    return number.replace(/\D/g, '');
  }
  return '';
}

export const only2DecimalPlaces = (value: number | string): string => {
  const number = `${value}`;
  if (/^\d*\.?\d*$/.test(number)) {
    const numberParts = number.split('.');
    if (numberParts.length > 1) {
      return numberParts[0] + '.' + numberParts[1].substring(0, 2);
    }
    return numberParts[0];
  }
  return '';
}

export const amountMask = (value: string | null | undefined): string => {
  if (value !== null && value !== undefined) {
    const amount = value.replace(/\D/g, '');

    if (amount.length === 0) {
      return '0.00';
    }

    const integerPart = amount.slice(0, -2);
    const decimalPart = amount.slice(-2);

    return `${integerPart}.${decimalPart}`;
  }

  return '';
}

export const dateMask = (value: string | null | undefined): string => {
  if (value !== null && value !== undefined) {
    const sanitizedValue = value.replace(/\D/g, '');

    const day = sanitizedValue.slice(0, 2);
    const month = sanitizedValue.slice(2, 4);
    const year = sanitizedValue.slice(4, 8);

    let formattedDate = `${day}/${month}/${year}`;

    // Remove as barras extras se o campo estiver vazio ou parcialmente preenchido
    if (sanitizedValue.length === 0 || sanitizedValue.length < 8) {
      formattedDate = sanitizedValue;
    }

    return formattedDate;
  }

  return '';
}

export const multipleFieldsOptionsFiller = (array: any, names: string[], options: any) =>
  array.map((x: any) => {
    if (names.includes(x.name)) {
      x = {
        ...x,
        options
      }
    }
    return x;
  });


export const dataParserWithFieldName = (fields: any[], pageFields: PageField[]): any[] => {
  const dataToSendArray: any[] = [];


  const optionsMap: { [key: string]: { [key: number]: string } } = {};
  pageFields.forEach((field) => {
    if (field.options) {
      optionsMap[field.name] = field.options.reduce((map: { [key: number]: string }, option) => {
        map[option.id] = option.name;
        return map;
      }, {});
    }
  });

  fields.forEach((field) => {
    const dataToSend: any = {};
    Object.keys(field).forEach(key => {
      if (key !== 'descJustification') {
        const fieldKey = key as keyof PageField;
        const fieldValue = field[fieldKey];

        if (typeof fieldValue !== 'undefined' && fieldValue !== '') {
          if (optionsMap[fieldKey] && optionsMap[fieldKey][fieldValue]) {
            dataToSend[fieldKey] = optionsMap[fieldKey][fieldValue];
          } else {
            dataToSend[fieldKey] = fieldValue;
          }
        }
      } else {
        dataToSend[key] = field[key];
      }
    });
    dataToSendArray.push(dataToSend);
  });

  return dataToSendArray;
};

export const mapFieldsToSystemFormEnums = (input: any, systemFormFields: any[]): any[] => {
  const output: any[] = [];

  const fieldMappings: { [key: string]: number } = {
    "CUSD": 18,
    "CCER": 19,
    "workContract": 20,
    "impeditiveTypeEnum": 21,
    "propertyRegistration": 1,
    "CAR": 2,
    "CCIR": 3,
    "ITR": 4,
    "propertyDocumentation": 22,
    "doesTheAreaContainThePower": 17,
    "inclination": 5,
    "floodRisk": 6,
    "inundationRisk": 23,
    "vegetation": 7,
    "permanentProtectionArea": 8,
    "legalReserve": 9,
    "conservationUnit": 10,
    "settlement": 11,
    "quilombola": 15,
    "Indigenous": 16,
    "miningLaw": 12,
    "caveRisk": 13,
    "archaeologicalSites": 14,
  };
  for (const [key, value] of Object.entries(input)) {
    if (fieldMappings[key]) {
      const systemField = systemFormFields.find(field => field.id === fieldMappings[key]);
      if (systemField) {
        output.push({
          "systemFieldFormEnum": systemField.id,
          "impeditiveTypeEnum": Number(value)
        });
      }
    }
  }

  return output;
};

function multiplier(input: string): string {
  // eslint-disable-next-line 
  const regex = /^(1(\,([0-9])?)?|2(\,0)?)$/;
  if (regex.test(input)) {
    return input.replace('.', ',');
  } else {
    return '';
  }
}

function decimalMask(input: string): string {
  const numeroLimpo = input.replace(/[^\d]/g, '');

  if (numeroLimpo.length === 0 || parseInt(numeroLimpo) === 0) {
    return '';
  }

  const num = parseFloat(numeroLimpo) / 100;

  return num.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export function getMaskFunction(maskName: string): (value: string) => string {
  switch (maskName) {
    case 'only2DecimalPlaces':
      return only2DecimalPlaces;
    case 'amountMask':
      return amountMask;
    case 'dateMask':
      return dateMask;
    case 'onlyNumbers':
      return onlyNumbers;
    case 'multiplier':
      return multiplier;
    case 'decimalMask':
      return decimalMask;
    default:
      return (value: string) => value;
  }
}

export const getKWdcValue = (value: number) => {
  return (value ? (value * 13) / 10 : null);
}

export const getKWdcValueWithMultiplier = (value: number, multiplier: string) => {
  const numberToString = multiplier.toString()
  const factor = Number.parseFloat(numberToString.replace(',', '.')) * 10
  return (value ? value * factor / 10 || 0 : null);
}

export function mapFieldValues(
  systemFieldFormImpeditiveTypeViewModels: any[],
  systemFieldForms: any[],
  fieldMappings: { [key: number]: string }
) {
  const result: { [key: string]: number } = {};

  systemFieldFormImpeditiveTypeViewModels.forEach(({ systemFieldFormEnum, impeditiveTypeEnum }) => {
    const fieldName = fieldMappings[systemFieldForms.find(form => form.id === systemFieldFormEnum)?.id || ''];
    if (fieldName) {
      result[fieldName] = impeditiveTypeEnum;
    }
  });

  return result;
}

export const debounce =
  <T>(fn: Function, wait = 1000, time?: NodeJS.Timeout) =>
    (...args: T[]) => {
      clearTimeout(time);
      time = setTimeout(() => fn(...args), wait);
    };

export const parseToDecimal = (value: any) => {
  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export const parseToFloat = (value: any) => {
  return parseFloat(value.replace('.', '').replace(',', '.'))
}