import React, { useEffect } from "react";
import { AppDispatch, RootState } from "../../../reducers/store";
import { useDispatch, useSelector } from "react-redux";
import { 
  Alert, 
  AlertTitle, 
  Button, 
  Grid } from "@mui/material";
import {
  cleanForm,
  formValidation,
  getExistingProjectById,
  getImpeditiveTypes,
  getPropertyTypes,
  getSystemFormField,
  handleFieldFiller,
  handleJustificationFieldFiller,
  justificationFormValidation,
} from "../../../reducers/ProjectValidation/tabs/projectAnalyzesReducer";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CustomFormComponent from "../../../component/CustomForm";
import JustificationGrid from "../../../component/JustificationGrid";
import { dataParserWithFieldName } from "../../../services/utils";

const ProjectAnalyzes = () => {
  const dispatch: AppDispatch = useDispatch();
  const fields = useSelector(
    (state: RootState) => state.projectAnalyzes.pageFields
  );
  const { projectSelected } : any = useSelector(
    (state: RootState) => state.projectToValidate
  );
  const { isFormValid, btnConfig, justificationForm, rows, isFormDisabled } =
    useSelector((state: RootState) => state.projectAnalyzes);

  const rowsToShow = dataParserWithFieldName(
    rows,
    justificationForm.pageFields
  );

  const initPage = () => {
    dispatch(getImpeditiveTypes());
    dispatch(getPropertyTypes());
    dispatch(getSystemFormField());
  };

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (projectSelected.id) {
      dispatch(getExistingProjectById(projectSelected?.id));
    }
  }, [dispatch, projectSelected]);

  useEffect(() => {
    return () => {
      dispatch(cleanForm());
    };
    // eslint-disable-next-line 
  }, []);

  const handleSubmit = () => {
    dispatch(formValidation());
  };

  const handleSubmitJustification = () => {
    dispatch(justificationFormValidation());
  };

  const handleOnChange = (value: any, field: any) => {
    dispatch(handleFieldFiller({ field, value }));
    field.action && dispatch(field.action(value));
  };

  const handleOnChangeJustificationGrid = (value: any, field: any) => {
    dispatch(handleJustificationFieldFiller({ field, value }));
    field.action && dispatch(field.action(value));
  };

  return (
    <React.Fragment>
      {!isFormValid && (
        <Alert sx={{ marginTop: 2 }} severity="warning">
          <AlertTitle>Preencha todos os campos obrigatórios</AlertTitle>
        </Alert>
      )}
      <br />
      <Grid
        sx={{ p: 2, pt: 0 }}
        container
        xs={12}
      >
        <CustomFormComponent
          fields={fields}
          handleOnChange={handleOnChange}
          handleSubmit={handleSubmit}
          disabled={isFormDisabled}
          btnConfig={btnConfig}
        />
      </Grid>
      <JustificationGrid
        handleOnChange={handleOnChangeJustificationGrid}
        handleSubmit={handleSubmitJustification}
        pageFields={justificationForm.pageFields}
        isFormDisabled={false}
        btnConfig={justificationForm.btnConfig}
        rows={rowsToShow}
      />
      <Grid
        sx={{ pt: 1 }}
        container
        xs={12}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          startIcon={<SaveAsIcon />}
          variant="contained"
          color="secondary"
          href="#contained-buttons"
          onClick={() => handleSubmit()}
          onChange={() => handleOnChange}
          disabled={['Recomendado', 'Reprovado'].includes(projectSelected.nameStatus) || isFormDisabled}
        >
          Salvar
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default ProjectAnalyzes;
