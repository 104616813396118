import PageContainerComponent from "../../component/PageContainer";
import {
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { errorTitleStyle, linkStyle } from "../../assets/styles/accordionStyles";

const RouteNotFound = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <PageContainerComponent
            title=""
            style={{ marginLeft: isMobile ? 60 : 300 }}
        >
            <Grid
                container
                direction="column"
                alignSelf={"center"}
                alignContent={"center"}
                alignItems={"center"}>
                <Grid
                    xs={10}>
                    <Typography
                        sx={errorTitleStyle}
                        variant="h1"
                        textAlign={"center"}>Ops!</Typography>
                    <br />
                    <Typography
                        variant="h4"
                        textAlign={"center"}>Página não encontrada!</Typography>
                    <Typography
                        variant="h6"
                        textAlign={"center"}>Por favor, verifique a rota e tente novamente.</Typography>
                    <a href="mailto:suporte.cycklos@ivienergia.com?subject=Página não encontrada">
                        <Typography
                            sx={linkStyle}
                            variant="h6"
                            textAlign={"center"}>
                            Caso necessário, entre em contato com nosso suporte.
                        </Typography>
                    </a>
                </Grid>
            </Grid>
        </PageContainerComponent>
    );
};

export default RouteNotFound;