import { createSlice, createAsyncThunk, Slice } from '@reduxjs/toolkit'
import { get, post, put } from '../../../services/agent'
import { dataParser, handleFiller, inputValidation, mapFieldsToSystemFormEnums, multipleFieldsOptionsFiller, optionsFiller, mapFieldValues, insertValueIntoFieldByName } from '../../../services/utils'
import getNewGeoreferencingAnalyzesForm from '../../../data/forms/georeferencinganalyzes'
import { setDialog } from '../../Dialog/dialogReducer'
import HttpResponseHandler from '../../Error/httpResponseHandler'
import loadingMiddleware from '../../Loading/loadingMiddleware';

const georeferencingAnalyzesBodyParser = (data: any, id: number, fileName: string, extension: number) => {
    return {
        projectId: id,
        contentBase64: data.contentBase64,
        userRegistrationId: 0,
        systemFieldFormImpeditiveTypeViewModels: [] as any[],
        systemFieldFormJustificationViewModels: [] as any[],
        extension: extension,
        fileName: fileName
    }
}

const checkIfImpeditiveWasJustified = (impeditiveTypesArr: any[], justificationArr: any[], systemFields: any[], impeditiveFields: any[]) => {
    const rules: { [key: number]: number[] } = {
        6: [2, 3],
        7: [1, 2],
        8: [1, 2],
        9: [1],
        10: [1, 2],
        11: [1],
        15: [1],
        16: [1],
        12: [2, 3],
        13: [2, 3],
        14: [2, 3],
        23: [2, 3],
    };

    const checkResult: any = {
        status: true,
        msg: ''
    }

    var msg = ""
    for (const item of impeditiveTypesArr) {
        if (rules[item.systemFieldFormEnum] && rules[item.systemFieldFormEnum].includes(item.impeditiveTypeEnum)) {
            const isJustified = justificationArr.some(justification =>
                justification.systemFieldFormEnum === item.systemFieldFormEnum
            );

            if (!isJustified) {
                const field = systemFields.find((field) => field.id === item.systemFieldFormEnum).name
                const impeditive = impeditiveFields.find((impeditive) => impeditive.id === item.impeditiveTypeEnum).name
                checkResult.status = false
                msg = msg + `Campo: "${field}" se a resposta for "${impeditive}"</br>`
            }
        }
    }

    if (msg !== '') {
        msg = `<b>É obrigatório justificar os campos:</b></br></br>${msg}`;
    }

    checkResult.msg = msg;
    return checkResult;
}

const updatePageFieldsWithPayload = (pageFields: any[], payload: any, systemFields: any[]) => {

    const fieldMappings: { [key: string]: string } = {
        17: "doesTheAreaContainThePower",
        5: "inclination",
        6: "floodRisk",
        23: "inundationRisk",
        7: "vegetation",
        8: "permanentProtectionArea",
        9: "legalReserve",
        10: "conservationUnit",
        11: "settlement",
        15: "quilombola",
        16: "Indigenous",
        12: "miningLaw",
        13: "caveRisk",
        14: "archaeologicalSites"
    };

    payload = {
        ...payload,
        ...mapFieldValues(payload.systemFieldFormImpeditiveTypeViewModels, systemFields, fieldMappings),
    }

    for (const field of pageFields) {
        if (field.name in payload) {
            field.value = payload[field.name];
        }
    }
    return pageFields;
}

const IMPEDITIVE_TYPES_URL = "impeditive-types"
const GET_BASE64_BY_BLOB_NAME_URL = (blobName: string) => `files/downloads?blobName=${blobName}`
const GET_JUSTIFICATIONS_BY_ID_URL = (id: number) => `systems-form-fields/${id}/justification`
const GEOREFERENCING_ANALYZES_URL = "georeferencing-analyzes"
const SYSTEM_FROM_FIELDS_URL = (id: number) => `/system-forms/${id}/system-form-field`
const GET_EXISTING_PROJECT_BY_ID_URL = (id: number) => `projects/${id}/georeferencing-analysis`
const UPDATE_EXISTING_PROJECT_BY_ID_URL = (id: number) => `georeferencing-analyzes/${id}`

const getImpeditiveTypes = createAsyncThunk(
    'impeditiveTypes/get',
    async (_) => {
        const response = await get(IMPEDITIVE_TYPES_URL)
        return response
    },
)

const getJustifications = createAsyncThunk(
    'justifications/get',
    async (id: number) => {
        const response = await get(GET_JUSTIFICATIONS_BY_ID_URL(id))
        return response
    },
)

const getSystemFormField = createAsyncThunk(
    'systemFormFields/get',
    async (_) => {
        const response = await get(SYSTEM_FROM_FIELDS_URL(3))
        return response
    },
)

const createGeoreferencingAnalyzes = createAsyncThunk(
    'createGeoreferencingAnalyzes/post',
    async (data: any, { dispatch }) => {
        const response = await post(GEOREFERENCING_ANALYZES_URL, data)
        const responseJson = await response.json()
        response.status === 400 && dispatch(setDialog({ title: 'Análise de Projeto', message: 'Campos inválidos', messageArray: Object.values(responseJson.errors) }))
        response.status === 500 && dispatch(setDialog({ title: 'Análise de Projeto', message: 'O sistema apresentou um erro, aguarde um momento e tente novamente!' }))

        if (response.status === 200 || response.status === 201) {
            dispatch(setDialog({ title: 'Projeto', message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson)) }))
        }
    },
)

const getExistingProjectById = createAsyncThunk(
    'getExistinGeoreferencingAnalyzes/get',
    async (id: number, { dispatch }) => {
        const response = await get(GET_EXISTING_PROJECT_BY_ID_URL(id))

        if (response.id) {
            dispatch(getBase64ByBlobName(response.fileNameBlobStorage))
            dispatch(handleFillInExistingFields(response))
        }
    },
)

const getBase64ByBlobName = createAsyncThunk(
    'getBase64ByBlobName/get',
    async (blobName: string, { dispatch }) => {
        const response = await get(GET_BASE64_BY_BLOB_NAME_URL(blobName))
        return response
    },
)

const updateGeoreferencingAnalyzes = createAsyncThunk(
    'updateGeoreferencingAnalyzes/put',
    async ({ dataToSend, id }: { dataToSend: any, id: number }, { dispatch }) => {

        try {
            const msg = "Dados salvos com sucesso"
            await put(UPDATE_EXISTING_PROJECT_BY_ID_URL(id), dataToSend)
            dispatch(setDialog({ title: 'Projeto', message: msg }))

        } catch (err: any) {
            const msg = `Ocorreu um erro ao tentar atualizar os dados, por favor, tente novamente mais tarde.<br/>
        ${err.message}
        `
            dispatch(setDialog({ title: 'Projeto', message: msg }))

        } finally {
            return;
        }

    },
)

const formValidation = createAsyncThunk(
    'formValidationGeoreferencingAnalyzes',
    async (_, { getState, dispatch }) => {
        const { georeferencingAnalyzes, projectToValidate, user }: any = getState();
        const { isFormValid, pageFields, systemFields, rows, impeditiveFields, isUpdating, oldSystemFields, id, fileName, extension } = georeferencingAnalyzes
        const fieldsData = dataParser(pageFields)

        if (isFormValid) {
            const dataToSend = georeferencingAnalyzesBodyParser(fieldsData, projectToValidate.projectSelected.id, fileName, extension)
            dataToSend.systemFieldFormImpeditiveTypeViewModels = mapFieldsToSystemFormEnums(fieldsData, systemFields)
            dataToSend.systemFieldFormJustificationViewModels = rows.map(
                (justification: any) => ({ ...justification, id: justification.id.toString().includes('newJustification') ? 0 : justification.id })
            )
            dataToSend['userRegistrationId'] = user.userRegistrationId

            const impeditiveFieldsValidation = checkIfImpeditiveWasJustified(
                dataToSend.systemFieldFormImpeditiveTypeViewModels,
                dataToSend.systemFieldFormJustificationViewModels,
                systemFields,
                impeditiveFields
            )

            if (!impeditiveFieldsValidation.status) {
                dispatch(setDialog({ title: 'Campos Obrigatórios', message: impeditiveFieldsValidation.msg }))
                return
            }

            if (isUpdating) {
                const enumToIdMap: { [key: number]: number } = {};
                oldSystemFields.forEach((field: any) => {
                    enumToIdMap[field.systemFieldFormEnum] = field.id;
                });
                dataToSend.systemFieldFormImpeditiveTypeViewModels = dataToSend.systemFieldFormImpeditiveTypeViewModels.map(field => {
                    const id = enumToIdMap[field.systemFieldFormEnum];
                    return { ...field, id: id !== undefined ? id : 0 };
                });
                dispatch(updateGeoreferencingAnalyzes({ dataToSend, id }))
                return
            }
            dispatch(createGeoreferencingAnalyzes(dataToSend))
        }

    },
)

const justificationFormValidation = createAsyncThunk(
    'justificationFormValidation/georeferencingAnalyzes',
    async (_, { getState, dispatch }) => {
        const { georeferencingAnalyzes, user, projectToValidate }: any = getState();
        const { isFormValid, pageFields } = georeferencingAnalyzes.justificationForm
        const { allJustifications } = projectToValidate
        const rowData = dataParser(pageFields)

        if (isFormValid) {
            if (!rowData.hasOwnProperty('descJustification')) {
                rowData.descJustification = ''
            }
            const justificationName = allJustifications.find((jus: any) => jus.id === rowData.justicationId)?.name
            const now = new Date()
            rowData.id = `${now.getTime()}newJustification`
            rowData.userRegistrationName = user.name
            rowData.dateRegistration = now
            rowData.justicationName = justificationName
            dispatch(addJustificationRow(rowData))
        }
        return ''
    },
)

export const georeferencingAnalyzesSlice: Slice = createSlice({
    name: 'georeferencingAnalyzes',
    initialState: getNewGeoreferencingAnalyzesForm(),
    reducers: {
        handleFieldFiller: (state, { payload }) => {
            state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value) || '']
        },
        handleJustificationFieldFiller: (state, { payload }) => {
            state.justificationForm.pageFields = [...handleFiller(state.justificationForm.pageFields, payload.field, payload.value) || '']
        },
        addJustificationRow: (state, { payload }) => {
            state.rows = [...state.rows, payload]
            state.justificationForm.pageFields[0].value = ''
            state.justificationForm.pageFields[1].value = ''
            state.justificationForm.pageFields[2].value = ''
        },
        cleanForm: (state) => {
            const initialState = getNewGeoreferencingAnalyzesForm()
            state.pageFields = initialState.pageFields
            state.rows = initialState.rows
            state.oldSystemFields = initialState.oldSystemFields
            state.isUpdating = initialState.isUpdating
            state.id = initialState.id
        },
        handleFillInExistingFields: (state, { payload }) => {
            state.pageFields = [...updatePageFieldsWithPayload(state.pageFields, payload, state.systemFields)]
            state.rows = payload.systemFieldFormJustificationViewModels
            state.oldSystemFields = payload.systemFieldFormImpeditiveTypeViewModels
            state.isUpdating = true
            state.id = payload.id
            state.fileName = payload.fileName
            state.extension = payload.extension
            state.fileUrl = payload.fileUrl
        },
        setImage: (state, { payload }) => {
            const base64 = payload.base64.replace(/^[^,]+,/, '')
            state.extension = payload.extension
            state.fileName = payload.name
            insertValueIntoFieldByName(state.pageFields, 'contentBase64', base64);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getImpeditiveTypes.fulfilled, (state, { payload }) => {
                const fieldsToFill = [
                    'inclination',
                    'floodRisk',
                    'vegetation',
                    'permanentProtectionArea',
                    'legalReserve',
                    'conservationUnit',
                    'miningLaw',
                    'caveRisk',
                    'archaeologicalSites',
                    'inundationRisk'
                ]
                state.pageFields = [...multipleFieldsOptionsFiller(state.pageFields, fieldsToFill, payload)]
                state.impeditiveFields = payload
            })
            .addCase(getSystemFormField.fulfilled, (state, { payload }) => {
                state.systemFields = payload
                state.justificationForm.pageFields = [...optionsFiller(state.justificationForm.pageFields, 'systemFieldFormEnum', payload)]
            })
            .addCase(getJustifications.fulfilled, (state, { payload }) => {
                state.justificationForm.pageFields = [...optionsFiller(state.justificationForm.pageFields, 'justicationId', payload)]
            })
            .addCase(formValidation.pending, (state) => {
                state.pageFields = [...inputValidation(state.pageFields)]
                const isValid = !state.pageFields.filter(x => x.error === true)[0]
                state.isFormValid = isValid
            })
            .addCase(justificationFormValidation.pending, (state) => {
                state.justificationForm.pageFields = [...inputValidation(state.justificationForm.pageFields)]
                const isValid = !state.justificationForm.pageFields.filter(x => x.error === true)[0]
                state.justificationForm.isFormValid = isValid
            })
            .addCase(getBase64ByBlobName.fulfilled, (state, { payload }) => {
                insertValueIntoFieldByName(state.pageFields, 'contentBase64', payload);
            })
            .addCase(createGeoreferencingAnalyzes.pending, (state) => {
                state.isFormDisabled = true
                state.justificationForm.isFormDisabled = true
            })
            .addCase(createGeoreferencingAnalyzes.fulfilled, (state) => {
                state.isFormDisabled = false
                state.justificationForm.isFormDisabled = false
            })
            .addCase(createGeoreferencingAnalyzes.rejected, (state) => {
                state.isFormDisabled = false
                state.justificationForm.isFormDisabled = false
            })
            .addCase(updateGeoreferencingAnalyzes.pending, (state) => {
                state.isFormDisabled = true
            })
            .addCase(updateGeoreferencingAnalyzes.fulfilled, (state) => {
                state.isFormDisabled = false
            })
            .addCase(updateGeoreferencingAnalyzes.rejected, (state) => {
                state.isFormDisabled = false
            })
    },
})

export {
    formValidation,
    justificationFormValidation,
    getImpeditiveTypes,
    getSystemFormField,
    getJustifications,
    createGeoreferencingAnalyzes,
    loadingMiddleware,
    getExistingProjectById
};

export const { handleFieldFiller, handleJustificationFieldFiller, addJustificationRow, cleanForm, handleFillInExistingFields, setImage } = georeferencingAnalyzesSlice.actions;

export default georeferencingAnalyzesSlice.reducer
