import React from 'react';
import {
  createBrowserRouter, Outlet
} from "react-router-dom";
import AuthorizedPage from './component/AuthorizedPage'
import ProjectValidation from './pages/ProjectValidation';
import PowerSearchGrids from './pages/PowerSearchGrids';
import RouteNotFound from './pages/Error/ErrorPageNotFound';
import ErrorBoundary from './component/ErrorBoundary';
import HomeComponent from './pages/Home/Home';
import PreProject from './pages/PreProject/PreProject';
import PreProjetoDuplicityComponent from './pages/PreProjectDuplicty/PreProjectDuplicity';
import PreProjetoDuplicityEditComponent from './pages/PreProjectDuplicty/PreProjectDuplicityEdit';
import PreProjectGridComponent from './pages/PreProject/PreProjectGrid';
import PreProjectImportGridComponent from './pages/PreProjectImport/PreProjectImportGrid';
import ProjectGridComponent from './pages/Project/ProjectGrid';
import ProjectPendingGridComponent from './pages/Project/ProjectPendingGrid';
import PrepRojectImportDetailGridComponent from './pages/PreProjectImport/PreProjectImportDetailGrid';
import PreProjectInCharge from './pages/PreProjectInCharge/PreProjectInCharge'
import PreProjectView from './pages/PreProject/PreProjectView';

const ErrorBoundaryLayout = () => (
  <ErrorBoundary>
    <Outlet />
  </ErrorBoundary>
);

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [
      {
        path: "/",
        element: <AuthorizedPage children={<HomeComponent />} role='user' breadcrumbName="Principal" />
      },
      {
        path: "/preproject",
        element: <AuthorizedPage children={<PreProject />} role='user' breadcrumbName="Pré-Projeto" />
      },
      {
        path: "/preproject-duplicity",
        element: <AuthorizedPage children={<PreProjetoDuplicityComponent />} role='user' breadcrumbName="Pré-Projeto (Duplicidade)" />
      },
      {
        path: "/preproject-duplicity-edit",
        element: <AuthorizedPage children={<PreProjetoDuplicityEditComponent />} breadcrumbName="Pré-Projeto (Editar)" />
      },
      {
        path: "/preprojects",
        element: <AuthorizedPage children={<PreProjectGridComponent />} role='user' breadcrumbName="Pré-Projetos" />,
      },
      {
        path: "/preProject-incharge",
        element: <AuthorizedPage children={<PreProjectInCharge />} role='user' breadcrumbName="Pré-projeto (Importação)" />
      },
      {
        path: "/preproject/imports",
        element: <AuthorizedPage children={<PreProjectImportGridComponent />} role='user' breadcrumbName="Importação" />
      },
      {
        path: "/preproject/view",
        element: <AuthorizedPage children={<PreProjectView/>} role='user' breadcrumbName="Pré-projeto (Visualização)" />
      },
      {
        path: "/project-validation",
        element: <AuthorizedPage children={<ProjectValidation />} breadcrumbName="Projeto (Validação)" />
      },
      {
        path: "/projectgrid",
        element: <AuthorizedPage children={<ProjectGridComponent />} breadcrumbName="Projetos" />
      },
      {
        path: "/projectgrid-pending",
        element: <AuthorizedPage children={<ProjectPendingGridComponent />} breadcrumbName="Projetos em análise" />
      },
      {
        path: "/preproject/imports/detail",
        element: <AuthorizedPage children={<PrepRojectImportDetailGridComponent />} role='user' breadcrumbName="Detalhamento" />
      },
      {
        path: "/power-searching",
        element: <AuthorizedPage children={<PowerSearchGrids />} role='user' breadcrumbName="Projetos (Pesquisa)" />
      },
      {
        path: "*",
        element: <AuthorizedPage children={<RouteNotFound />} breadcrumbName="Página desconhecida" />,
      },
    ]
  }
]);

export default router;