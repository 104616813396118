import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, post, put } from '../../services/agent'
import {
  optionsFiller,
  handleFiller,
  inputValidation,
  dataParser,
  rowDataToFieldParser,
  findFieldByName,
  insertValueIntoFieldByName,
  getKWdcValueWithMultiplier,
  parseToFloat,
  parseToDecimal
} from '../../services/utils'
import { setDialog } from '../Dialog/dialogReducer'
import getNewPreprojectForm from '../../data/forms/preproject'
import HttpResponseHandler from '../Error/httpResponseHandler';
import loadingMiddleware from '../Loading/loadingMiddleware';

const ACCESS_OPINION_URL = "access-opinion"
const DEVELOPERS_URL = "developers"
const STATES_URL = "states"
const STATUS_PRE_PROJECT_URL = "status-pre-projects"
const PRE_PROJECT_URL = "pre-projects"
const CITIES_URL = (id: string) => `${STATES_URL}/${id}/cities`
const UTILITIES_URL = (id: string) => `${STATES_URL}/${id}/utilities`

const getAccessOpinionIds = createAsyncThunk(
  'accessOpinion/get',
  async (_, { dispatch }) => {
    const response = await get(ACCESS_OPINION_URL)
    return response
  },
)

const getDevelopers = createAsyncThunk(
  'developers/get',
  async (_, { dispatch }) => {
    const response = await get(DEVELOPERS_URL)
    return response
  },
)

const getStates = createAsyncThunk(
  'states/get',
  async (_, { dispatch }) => {
    const response = await get(STATES_URL)
    return response
  },
)

const getStatuses = createAsyncThunk(
  'statuses/get',
  async (_, { dispatch }) => {
    const response = await get(STATUS_PRE_PROJECT_URL)
    return response
  },
)

const getCitiesAndUtilies = createAsyncThunk(
  'cities&utilities/get',
  async (stateId: any, { dispatch }) => {
    const cities = await get(CITIES_URL(stateId))
    const utilities = await get(UTILITIES_URL(stateId))
    return { cities, utilities }
  },
)

const createPreProject = createAsyncThunk(
  'createPreProject/post',
  async (data: any, { dispatch }) => {

    const response = await post(PRE_PROJECT_URL, data)
    const responseJson = await response.json()

    dispatch(setDialog({
      title: 'Pré-projeto',
      message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
    }))

    if (response.status === 200 || response.status === 201) {
      dispatch(newForm())
      dispatch(getDevelopers())
      dispatch(getStates())
    }

    dispatch(enableSave())
  },
)

const updatePreProject = createAsyncThunk(
  'updatePreProject/put',
  async (data: any, { dispatch }) => {
    const response = await put(PRE_PROJECT_URL + "/" + data.id, data)
    const responseJson = await response.json()

    dispatch(setDialog({
      title: 'Pré-projeto',
      message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson)),
      redirect: "/preproject-duplicity"
    }))

    if (response.status === 200 || response.status === 201) {
      dispatch(newForm())
      dispatch(getDevelopers())
      dispatch(getStates())
    }

    dispatch(enableSave())
  },
)

const formValidation = createAsyncThunk(
  'formValidation',
  async (_, { getState, dispatch }) => {
    dispatch(disableSave())
    const { preprojeto, user }: any = getState();
    const { isFormValid, pageFields } = preprojeto
    const dataToSend = dataParser(pageFields)

    if (isFormValid) {
      dataToSend['userRegistrationId'] = user.userRegistrationId
      dataToSend.numberCapacityKwac = parseToFloat(dataToSend.numberCapacityKwac)
      dataToSend.numberFactorCapacity = Number.parseFloat(dataToSend.numberFactorCapacity.replace(',', '.'))
      dispatch(createPreProject(dataToSend,))
    } else {
      dispatch(enableSave())
    }

    return ''
  },
)

const calculateKWdcValue = createAsyncThunk(
  'calculateKWdcValue',
  async (_, { dispatch }) => {
    return '';
  }
)

export const preprojetoSlice = createSlice({
  name: 'preprojeto',
  initialState: getNewPreprojectForm(),
  reducers: {
    newForm: (state) => {
      const initialState = getNewPreprojectForm();
      state.pageFields = initialState.pageFields;
      state.isFormValid = initialState.isFormValid;
      state.isFormDisabled = initialState.isFormDisabled;
      state.stateSelected = initialState.stateSelected;
    },
    handleFieldFiller: (state, { payload }) => {
      state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value)]
    },
    setPreProjectData: (state, { payload }) => {      
      state.isFormDisabled = true;
      const initialState = getNewPreprojectForm();
      state.pageFields = initialState.pageFields;
      rowDataToFieldParser(payload, state.pageFields, true, true);
    },
    clearCitiesAndUtilities: (state) => {
      insertValueIntoFieldByName(state.pageFields, 'cityId', '');
      insertValueIntoFieldByName(state.pageFields, 'utilityId', '');
    },
    clearStateSelected: (state) => {
      state.stateSelected = null;
    },
    enableSave: (state) => {
      state.isFormDisabled = false;
    },
    disableSave: (state) => {
      state.isFormDisabled = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccessOpinionIds.fulfilled, (state, { payload }) => {
        state.pageFields = [...optionsFiller(state.pageFields, 'accessOpinionId', payload)]
      })
      .addCase(getDevelopers.fulfilled, (state, { payload }) => {
        state.pageFields = [...optionsFiller(state.pageFields, 'developerId', payload)]
      })
      .addCase(getStates.fulfilled, (state, { payload }) => {
        state.pageFields = [...optionsFiller(state.pageFields, 'stateId', payload)];
        const stateField = findFieldByName(state.pageFields, 'stateId');
        state.stateSelected = stateField?.value;
      })
      .addCase(getCitiesAndUtilies.fulfilled, (state, { payload }) => {
        state.pageFields = [...optionsFiller(state.pageFields, 'cityId', payload.cities)]
        state.pageFields = [...optionsFiller(state.pageFields, 'utilityId', payload.utilities)]
      })
      .addCase(formValidation.pending, (state) => {
        state.isFormDisabled = true;
        state.pageFields = [...inputValidation(state.pageFields)]
        const isValid = !state.pageFields.filter(x => x.error === true)[0]
        state.isFormValid = isValid
        state.isFormDisabled = false;
      })
      .addCase(getStatuses.fulfilled, (state, { payload }) => {
        state.pageFields = [...optionsFiller(state.pageFields, 'statusPreprojectId', payload)]
      })
      .addCase(calculateKWdcValue.fulfilled, (state) => {
        const kWacField = findFieldByName(state.pageFields, 'numberCapacityKwac');
        const multiplierField = findFieldByName(state.pageFields, 'numberFactorCapacity');        
        const multiplierValue = multiplierField?.value;
        const kWacValue = parseToFloat(kWacField?.value);
        const kWdcValue = getKWdcValueWithMultiplier(kWacValue, multiplierValue);
        insertValueIntoFieldByName(state.pageFields, 'numberCapacityKwdc', parseToDecimal(kWdcValue))
      })
  },
})

export {
  getDevelopers,
  getStates,
  createPreProject,
  updatePreProject,
  getCitiesAndUtilies,
  formValidation,
  getStatuses,
  calculateKWdcValue,
  getAccessOpinionIds,
  loadingMiddleware,
}

export const {
  clearCitiesAndUtilities,
  handleFieldFiller,
  newForm,
  setPreProjectData,
  clearStateSelected,
  enableSave,
  disableSave,
} = preprojetoSlice.actions

export default preprojetoSlice.reducer
