import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import InputComponent from "./Input"
import SaveAsIcon from '@mui/icons-material/SaveAs';

const FormComponent = ({ fields, handleOnChange, handleSubmit, disabled, hasSaveButton }: any) => {
  const [onSubmit, setOnSubmit] = useState(false);

  const insideElementHandleOnChange = (props: any) => {
    setOnSubmit(false)
    handleOnChange(props)
  }

  const insideElementHandleSubmit = () => {
    setOnSubmit(true)
    handleSubmit()
  }

  return (
    <form>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={3}>
        {
          fields.map((field: any) => {
            if (field.visible === undefined || field.visible === true) {
              return (
                <Grid item key={field.id}>
                  <InputComponent field={field} handleOnChange={handleOnChange} />
                </Grid>
              );
            } else { return null }
          })
        }
        {hasSaveButton !== false ?
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end">
            <Button
              startIcon={<SaveAsIcon />}
              variant="contained"
              color="secondary"
              href="#contained-buttons"
              disabled={disabled}
              onClick={insideElementHandleSubmit}
              onChange={insideElementHandleOnChange}>
              Salvar
            </Button>
          </Grid> : <></>
        }
      </Grid>
    </form>
  );
}

export default FormComponent