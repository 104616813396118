import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, post, put } from '../../../services/agent'
import {
  dataParser,
  handleFiller,
  inputValidation,
  optionsFiller,
  multipleFieldsOptionsFiller,
  mapFieldsToSystemFormEnums,
  mapFieldValues,
  parseToDecimal
} from '../../../services/utils'
import getNewNetworkConnectionForm from '../../../data/forms/networkconnection'
import { setDialog } from '../../Dialog/dialogReducer'
import HttpResponseHandler from '../../Error/httpResponseHandler'
import { format } from 'date-fns'
import loadingMiddleware from '../../Loading/loadingMiddleware';

const networkConnectionBodyParser = (data: any, id: number) => {

  return {
    projectId: id,
    numberExtension: data.numberExtension ? Number(data.numberExtension.replace('.', '').replace(',', '.')) : '',
    valueTotalCost: parseFloat((data.valueTotalCost.replace('.', '').replace(',', '.') || 0)),
    valueFinancialParticipationCustomer: parseFloat((data.valueFinancialParticipationCustomer.replace('.', '').replace(',', '.') || 0)),
    responsibleEntityId: data.responsibleEntityId,
    userRegistrationId: 0,
    dateIssueAccessOpinion: new Date(data?.dateIssueAccessOpinion).toISOString() || '',
    dateConstructionDeadlines: new Date(data.dateConstructionDeadlines).toISOString(),
    dateMaximumConnection: new Date(data.dateMaximumConnection).toISOString(),
    dateAccessRequestProtocol: new Date(data.dateAccessRequestProtocol).toISOString(),
    isProjectDocumentsApprovedAccessOpinion: data.isProjectDocumentsApprovedAccessOpinion,
    typeCabinProjectId: data.typeCabinProjectId,
    typeProofProjectApprovalId: data.typeProofProjectApprovalId,
    systemFieldFormImpeditiveTypeViewModels: [] as any[],
    systemFieldFormJustificationViewModels: [] as any[],
    electricalProjectId: data.electricalProjectId,
    typeProofCabinApprovalId: data.typeProofCabinApprovalId,
    shortDataId: data.shortDataId
  }
}

const updatePageFieldsWithPayload = (pageFields: any[], payload: any, systemFields: any[]) => {

  const fieldMappings: { [key: number]: string } = {
    18: "CUSD",
    19: "CCER",
    20: "workContract",
    21: "impeditiveTypeEnum",
  };

  payload = {
    ...payload,
    ...mapFieldValues(payload.systemFieldFormImpeditiveTypeViewModels, systemFields, fieldMappings),
    dateIssueAccessOpinion: payload.dateIssueAccessOpinion ? format(payload.dateIssueAccessOpinion, 'dd/MM/yyyy') : '',
    dateConstructionDeadlines: payload.dateConstructionDeadlines ? format(payload.dateConstructionDeadlines, 'dd/MM/yyyy') : '',
    dateAccessRequestProtocol: payload.dateAccessRequestProtocol ? format(payload.dateAccessRequestProtocol, 'dd/MM/yyyy') : '',
    dateMaximumConnection: payload.dateMaximumConnection ? format(payload.dateMaximumConnection, 'dd/MM/yyyy') : '',
  }

  for (const field of pageFields) {
    if (field.name in payload) {
      field.value = payload[field.name];
    }
  }
  return pageFields;
}

const NETWORK_REINFORCEMENT_URL = "network-reinforcements"
const TYPE_PROOF_PROJECT_APPROVALS_URL = "type-proof-project-approvals"
const RESPONSIBLE_ENTITIES_URL = "responsible-entities"
const GET_JUSTIFICATIONS_BY_ID_URL = (id: number) => `systems-form-fields/${id}/justification`
const TYPE_CABIN_PROJECTS_URL = "type-cabin-projects"
const IMPEDITIVE_TYPES_URL = "impeditive-types"
const SHORT_DATA_URL = "shortdata"
const SYSTEM_FROM_FIELDS_URL = (id: number) => `system-forms/${id}/system-form-field`
const GET_EXISTING_PROJECT_BY_ID_URL = (id: number) => `projects/${id}/network-reinforcement`
const UPDATE_EXISTING_PROJECT_BY_ID_URL = (id: number) => `network-reinforcements/${id}`

const getResponsibleEntities = createAsyncThunk(
  'responsibleEntities/get',
  async (_) => {
    const response = await get(RESPONSIBLE_ENTITIES_URL)
    return response
  },
)

const getImpeditiveTypes = createAsyncThunk(
  'impeditiveTypes/get',
  async (_) => {
    const response = await get(IMPEDITIVE_TYPES_URL)
    return response
  },
)

const getShortDataTypes = createAsyncThunk(
  'shortDataTypes/get',
  async (_) => {
    const response = await get(SHORT_DATA_URL)
    return response
  },
)

const getJustifications = createAsyncThunk(
  'justifications/get',
  async (id: number) => {
    const response = await get(GET_JUSTIFICATIONS_BY_ID_URL(id))
    return response
  },
)

const getSystemFormField = createAsyncThunk(
  'systemFormField/get',
  async (_) => {
    const response = await get(SYSTEM_FROM_FIELDS_URL(1))
    return response
  },
)

const getCabinProjectTypes = createAsyncThunk(
  'cabinProjectTypes/get',
  async (_) => {
    const response = await get(TYPE_CABIN_PROJECTS_URL)
    return response
  },
)

const getProOfProjectApprovalTypes = createAsyncThunk(
  'proOfProjectApproval/get',
  async (_) => {
    const response = await get(TYPE_PROOF_PROJECT_APPROVALS_URL)
    return response
  },
)

const createNetworkConnection = createAsyncThunk(
  'createNetworkConnection/post',
  async (data: any, { dispatch }) => {
    const response = await post(NETWORK_REINFORCEMENT_URL, data)
    const responseJson = await response.json()
    response.status === 400 && dispatch(setDialog({ title: 'Conexão de Rede', message: 'Campos inválidos', messageArray: Object.values(responseJson.errors) }))
    response.status === 500 && dispatch(setDialog({ title: 'Conexão de Rede', message: 'Sistema apresentando erro, por favor, tente novamente mais tarde.' }))

    if (response.status === 200 || response.status === 201) {
      dispatch(setDialog({ title: 'Projeto', message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson)) }))
    }
  },
)

const updateNetworkConnection = createAsyncThunk(
  'updateNetworkConnection/put',
  async ({ dataToSend, id }: { dataToSend: any, id: number }, { dispatch }) => {

    try {
      const msg = "Dados salvos com sucesso"
      await put(UPDATE_EXISTING_PROJECT_BY_ID_URL(id), dataToSend)
      dispatch(setDialog({ title: 'Projeto', message: msg }))

    } catch (err: any) {
      const msg = `Ocorreu um erro ao tentar atualizar os dados, por favor, tente novamente mais tarde.<br/>
      ${err.message}
      `
      dispatch(setDialog({ title: 'Projeto', message: msg }))

    } finally {
      return;
    }

  },
)

const getExistingProjectById = createAsyncThunk(
  'getExistingProject/get',
  async (id: number, { dispatch }) => {
    const response = await get(GET_EXISTING_PROJECT_BY_ID_URL(id))

    if (response.id) {
      response.numberExtension = parseToDecimal(response?.numberExtension)
      response.valueFinancialParticipationCustomer = parseToDecimal(response?.valueFinancialParticipationCustomer)
      response.valueTotalCost = parseToDecimal(response?.valueTotalCost)
      dispatch(handleFillInExistingFields(response))
    }
  },
)

const formValidation = createAsyncThunk(
  'formValidationNetworkConnection',
  async (_, { getState, dispatch }) => {
    const { networkConnection, projectToValidate, user }: any = getState();
    const { isFormValid, pageFields, systemFields, rows, isUpdating, id, oldSystemFields } = networkConnection
    const fieldsData = dataParser(pageFields)

    if (isFormValid) {
      const dataToSend = networkConnectionBodyParser(fieldsData, projectToValidate.projectSelected.id)
      dataToSend.systemFieldFormImpeditiveTypeViewModels = mapFieldsToSystemFormEnums(fieldsData, systemFields)
      dataToSend.systemFieldFormJustificationViewModels = rows.map(
        (justification: any) => ({ ...justification, id: justification.id.toString().includes('newJustification') ? 0 : justification.id })
      )
      dataToSend['userRegistrationId'] = user.userRegistrationId

      if (dataToSend.valueTotalCost < dataToSend.valueFinancialParticipationCustomer) {
        dispatch(setDialog({ title: 'Campos Inválidos', message: 'O campo "Custo total da obra" não pode ser menor que o campo "Participação financeira do cliente"' }))
        return
      }

      if (isUpdating) {
        const enumToIdMap: { [key: number]: number } = {};
        oldSystemFields.forEach((field: any) => {
          enumToIdMap[field.systemFieldFormEnum] = field.id;
        });

        dataToSend.systemFieldFormImpeditiveTypeViewModels = dataToSend.systemFieldFormImpeditiveTypeViewModels.map(field => {
          const id = enumToIdMap[field.systemFieldFormEnum];
          return { ...field, id: id !== undefined ? id : null };
        });

        dispatch(updateNetworkConnection({ dataToSend, id }))
        return
      }
      dispatch(createNetworkConnection(dataToSend))
    }
  },
)

const justificationFormValidation = createAsyncThunk(
  'justificationFormValidation/networkConnection',
  async (_, { getState, dispatch }) => {
    const { networkConnection, user, projectToValidate }: any = getState();
    const { isFormValid, pageFields } = networkConnection.justificationForm
    const { allJustifications } = projectToValidate
    const rowData = dataParser(pageFields)

    if (isFormValid) {
      if (!rowData.hasOwnProperty('descJustification')) {
        rowData.descJustification = ''
      }
      const justificationName = allJustifications.find((jus: any) => jus.id === rowData.justicationId)?.name
      const now = new Date()
      rowData.id = `${now.getTime()}newJustification`
      rowData.userRegistrationName = user.name
      rowData.dateRegistration = now
      rowData.justicationName = justificationName
      dispatch(addJustificationRow(rowData))
    }
    return ''
  },
)

export const networkConnectionSlice = createSlice({
  name: 'networkConnection',
  initialState: getNewNetworkConnectionForm(),
  reducers: {
    handleFieldFiller: (state, { payload }) => {
      state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value) || '']
    },
    handleJustificationFieldFiller: (state, { payload }) => {
      state.justificationForm.pageFields = [...handleFiller(state.justificationForm.pageFields, payload.field, payload.value) || '']
    },
    addJustificationRow: (state, { payload }) => {
      state.rows = [...state.rows, payload]
      state.justificationForm.pageFields = state.justificationForm.pageFields.map((field: any) => ({ ...field, value: '' }));
    },
    handleFillInExistingFields: (state, { payload }) => {
      state.pageFields = [...updatePageFieldsWithPayload(state.pageFields, payload, state.systemFields)]
      state.rows = payload.systemFieldFormJustificationViewModels
      state.oldSystemFields = payload.systemFieldFormImpeditiveTypeViewModels
      state.isUpdating = true
      state.id = payload.id
    },
    cleanForm: (state) => {
      const initialState = getNewNetworkConnectionForm()
      state.pageFields = initialState.pageFields
      state.rows = initialState.rows
      state.oldSystemFields = initialState.oldSystemFields
      state.isUpdating = initialState.isUpdating
      state.id = initialState.id
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResponsibleEntities.fulfilled, (state, { payload }) => {
        state.pageFields = [...optionsFiller(state.pageFields, 'responsibleEntityId', payload)]
      })
      .addCase(getImpeditiveTypes.fulfilled, (state, { payload }) => {
        state.pageFields = [...multipleFieldsOptionsFiller(state.pageFields, ['impeditiveTypeEnum', 'CUSD', 'CCER', 'workContract'], payload)]
        state.impeditiveFields = payload
      })
      .addCase(getJustifications.fulfilled, (state, { payload }) => {
        state.justificationForm.pageFields = [...optionsFiller(state.justificationForm.pageFields, 'justicationId', payload)]
      })
      .addCase(getProOfProjectApprovalTypes.fulfilled, (state, { payload }) => {
        state.pageFields = [...multipleFieldsOptionsFiller(state.pageFields, ['typeProofProjectApprovalId', 'typeProofCabinApprovalId'], payload)]
      })
      .addCase(getCabinProjectTypes.fulfilled, (state, { payload }) => {
        state.pageFields = [...multipleFieldsOptionsFiller(state.pageFields, ['typeCabinProjectId', 'electricalProjectId'], payload)]
      })
      .addCase(getShortDataTypes.fulfilled, (state, { payload }) => {
        state.pageFields = [...optionsFiller(state.pageFields, 'shortDataId', payload)]
      })
      .addCase(getSystemFormField.fulfilled, (state, { payload }) => {
        state.systemFields = payload
        state.justificationForm.pageFields = [...optionsFiller(state.justificationForm.pageFields, 'systemFieldFormEnum', payload)]
      })
      .addCase(formValidation.pending, (state) => {
        state.pageFields = [...inputValidation(state.pageFields)]
        const isValid = !state.pageFields.filter(x => x.error === true)[0]
        state.isFormValid = isValid
      })
      .addCase(justificationFormValidation.pending, (state) => {
        state.justificationForm.pageFields = [...inputValidation(state.justificationForm.pageFields)]
        const isValid = !state.justificationForm.pageFields.filter(x => x.error === true)[0]
        state.justificationForm.isFormValid = isValid
        state.isFormDisabled = true
      })
      .addCase(justificationFormValidation.fulfilled, (state) => {
        state.isFormDisabled = false
      })
      .addCase(createNetworkConnection.pending, (state) => {
        state.isFormDisabled = true
      })
      .addCase(createNetworkConnection.fulfilled, (state) => {
        state.isFormDisabled = false
      })
      .addCase(createNetworkConnection.rejected, (state) => {
        state.isFormDisabled = false
      })
      .addCase(updateNetworkConnection.pending, (state) => {
        state.isFormDisabled = true
      })
      .addCase(updateNetworkConnection.fulfilled, (state) => {
        state.isFormDisabled = false
      })
      .addCase(updateNetworkConnection.rejected, (state) => {
        state.isFormDisabled = false
      })
      .addCase(getExistingProjectById.pending, (state) => {
        state.isFormDisabled = true
      })
      .addCase(getExistingProjectById.fulfilled, (state) => {
        state.isFormDisabled = false
      })
      .addCase(getExistingProjectById.rejected, (state) => {
        state.isFormDisabled = false
      })
  }
})

export {
  justificationFormValidation,
  formValidation,
  getResponsibleEntities,
  getImpeditiveTypes,
  getJustifications,
  getCabinProjectTypes,
  getProOfProjectApprovalTypes,
  getSystemFormField,
  getExistingProjectById,
  updateNetworkConnection,
  getShortDataTypes,
  loadingMiddleware
};

export const { handleFieldFiller, handleJustificationFieldFiller, addJustificationRow, handleFillInExistingFields, cleanForm } = networkConnectionSlice.actions;

export default networkConnectionSlice.reducer

