import { useEffect } from 'react';
import PageContainerComponent from '../../component/PageContainer';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import type { AppDispatch, RootState } from '../../reducers/store';
import { cleanRowSelected, getPreprojectById, getPreProjectDuplicity } from '../../reducers/PreProjectDuplicty/preProjectDuplicityReducer';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { setPreProjectDuplicityData } from '../../reducers/PreProjectDuplicty/preProjectDuplicityEditReducer';
import { ptBR } from '@mui/x-data-grid/locales';
import { format } from 'date-fns';
import { parseToDecimal } from '../../services/utils';

const PreProjetoDuplicityComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const rows = useSelector((state: RootState) => state.preProjetoDuplicity.rows);
  const rowSelected: any = useSelector((state: RootState) => state.preProjetoDuplicity.rowSelected);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(getPreProjectDuplicity());
  }, [dispatch]);

  const handleEditClick = async (row: any) => {
    await dispatch(getPreprojectById(row.id));
  }

  useEffect(() => {
    if (rowSelected.id) {
      const payload: any = {
        ...rowSelected,
        numberFactorCapacity: rowSelected.numberFactorCapacity?.toString().replace('.', ','),
        numberCapacityKwac: parseToDecimal(rowSelected.numberCapacityKwac),
        numberCapacityKwdc: parseToDecimal(rowSelected.numberCapacityKwdc),
        dateLastUpdate: format(
          new Date(rowSelected.dateLastUpdate),
          "dd/MM/yyyy HH:mm"
        ),
      };
      dispatch(setPreProjectDuplicityData(payload));
      dispatch(cleanRowSelected());
      navigate('/preproject-duplicity-edit');
    }
    // eslint-disable-next-line 
  }, [rowSelected])

  const columns: GridColDef[] = [
    {
      field: "preProjectActions",
      headerName: "Editar",
      width: 100,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleEditClick(params.row)}
          size="small"
          title="Editar"
        >
          <EditIcon color="primary" />
        </IconButton>
      ),
    },
    { field: 'coPreProject', headerName: 'Código', width: 150, filterable: true },
    { field: 'nameProjectDevelop', headerName: 'Projeto (Ref. develop)', width: 250, filterable: true },
    { field: 'nameProject', headerName: 'Projeto', width: 150, filterable: true },
    { field: 'stateAcronym', headerName: 'Estado', width: 80, filterable: true },
    { field: 'cityName', headerName: 'Cidade', width: 200, filterable: true },
    { field: 'utilityName', headerName: 'Distribuidora', width: 170, filterable: true },
    {
      field: "numberCapacityKwac",
      headerName: "Capacidade instalada kWac",
      valueFormatter: (params) => {
        return parseToDecimal(params.value)
      },
      width: 190,
      filterable: true,
    },
    { field: 'numberFactorCapacity', headerName: 'Fator', width: 80, filterable: true },
    {
      field: "numberCapacityKwdc",
      headerName: "Capacidade instalada kWdc",
      valueFormatter: (params) => {
        return parseToDecimal(params.value);
      },
      width: 190,
      filterable: true,
    },
    { field: 'accessOpinionName', headerName: 'Parecer de acesso', width: 150, filterable: true },
    {
      field: "dateLastUpdate",
      headerName: "Última atualização",
      width: 150,
      filterable: true,
      valueFormatter: (params) => {      
        return params.value ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm") : '';
      },
    },
    { field: 'userRegistrationName', headerName: 'Incluído por', width: 150, filterable: true },
  ];


  return (
    <PageContainerComponent title="Pré-projetos com possíveis duplicidades" style={{ marginLeft: isMobile ? 60 : 300 }}>
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.id}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{ toolbar: GridToolbar }}
      />
    </PageContainerComponent>
  );
}

export default PreProjetoDuplicityComponent;