import React, { useState, useEffect, useRef } from 'react';
import PageContainerComponent from '../../component/PageContainer';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import type { AppDispatch, RootState } from '../../reducers/store'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { File } from '../../types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { 
  sendFile, 
  confirmImportPreProject, 
  getPreProjectImportsDetails, 
  newForm } from '../../reducers/PreProjectInCharge/preProjectInChargeReducer'
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid, GridColDef, GridRowId, GridToolbar } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { AppRegistration, UploadFileSharp, ViewListSharp } from '@mui/icons-material';
import ButtonGroupComponent from "../../component/ButtonGroup";
import { parseToDecimal } from '../../services/utils';

const steps = [
  {
    label: 'Selecione o arquivo .xlsx',
    description: `Arquivo para carregamento em massa`,
  },
  {
    label: 'Enviar arquivo',
    description: 'Ao clicar em Continuar o arquivo abaixo será enviado:'
  },
  {
    label: 'Confirmar importação',
    description: 'As informações serão carregadas abaixo, selecione os itens que deseja confirmar para importação ser concluída.',
  },
];

const PreProjectInCharge = () => {
  const emptyFile: File = {}

  const [file, setFile] = useState(emptyFile);
  const dispatch = useDispatch<AppDispatch>()

  const fileUploadError = useSelector((state: RootState) => state.preprojetoincharge.fileUploadError)
  const rows = useSelector((state: RootState) => state.preprojetoincharge.DetailsRows);

  const [activeStep, setActiveStep] = React.useState(0);
  const inputRef = useRef(null);
  const [isUploadCompleted, setIsUploadCompleted] = useState(false);
  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);

  useEffect(() => {
    if (fileUploadError === null && file !== emptyFile) {
      setIsUploadCompleted(true);
    } else {
      setIsUploadCompleted(false);
    }
    // eslint-disable-next-line 
  }, [file, fileUploadError]);


  const handleNext = () => {
    if (activeStep === 1) {
      dispatch(sendFile(file)).then((response) => {
        const id = response.payload.id;
        dispatch(getPreProjectImportsDetails(id)).then(() => {
          setIsUploadCompleted(true);
        }).catch(() => {
          setIsUploadCompleted(false);
          handleReset();
        });

      }).catch(() => {
        setIsUploadCompleted(false);
        handleReset();
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1 && inputRef.current) {
      //@ts-ignore
      inputRef.current.value = null
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    if (inputRef.current) {
      //@ts-ignore
      inputRef.current.value = null
    }
    dispatch(newForm())
    setActiveStep(0)
  };

  const handleConfirm = () => {
    dispatch(confirmImportPreProject(selectedIds))
    handleReset();
  };

  const columns: GridColDef[] = [
    { field: 'nameState', headerName: 'Estado', width: 170, filterable: true },
    { field: 'nameCity', headerName: 'Cidade', width: 200, filterable: true },
    { field: 'nameDevelop', headerName: 'Desenvolvedor', width: 170, filterable: true },
    { field: 'nameUtility', headerName: 'Distribuidora', width: 170, filterable: true },
    {
      field: "numberCapacityKwac",
      headerName: "Capacidade instalada kWac",
      valueFormatter: (params) => {
          return parseToDecimal(params.value);
      },
      width: 190,
      filterable: true,
    },
    { field: "numberFactorCapacity", headerName: "Fator", width: 80, filterable: true, },
    {
      field: "numberCapacityKwdc",
      headerName: "Capacidade instalada kWdc",
      valueFormatter: (params) => {
          return parseToDecimal(params.value);
      },
      width: 190,
      filterable: true,
    },
    { field: 'isAccessOpinion', headerName: 'Tem Parecer de Acesso?', width: 175, filterable: true },
    { field: 'errors', headerName: 'Erros', width: 400, filterable: true, valueGetter: (params) => params.row.errors ? params.row.errors.join(', ') : '' }
  ];

  const handleFileChange = (event: any) => {
    handleNext()
    const newFile = event.target.files[0];
    setFile(newFile)
  };

  const buttons = [
    { name: "Ver Pré-projetos", link: "/preprojects", icon: <ViewListSharp /> },
    { name: "Cadastrar", link: "/preproject", icon: <AppRegistration /> },
    { name: "Importar", link: "/preProject-incharge", icon: <UploadFileSharp /> },
    { name: "Ver Importações", link: "/preproject/imports", icon: <ViewListSharp /> },
  ];

  return (
    <PageContainerComponent title="Pré-projeto (Importação)">
      {fileUploadError &&
        <Alert severity="warning">
          <AlertTitle>Erro ao enviar arquivo</AlertTitle>
        </Alert>
      }
      <br />
      <ButtonGroupComponent buttons={buttons} />
      <Box sx={{ display: 'none' }}>
        <input type="file" ref={inputRef} onChange={handleFileChange} id="select-image" />
      </Box>

      <Box sx={{ maxWidth: 500 }}>

        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Último passo</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                {index === 1 && <Typography>{file.name}</Typography>}
                <Box sx={{ mb: 2 }}>
                  <div>
                    {
                      (index !== 0 && index !== 2) &&
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Passo Anterior
                      </Button>
                    }
                    {
                      index === 0 &&
                      <React.Fragment>
                        <label htmlFor="select-image">
                          <Button variant="contained" color="primary" component="span">
                            Upload de Arquivo
                          </Button>
                        </label>
                        <a href={require('../../assets/docs/Modelo_Importacao_Pre-Projeto.xlsx')} download="Modelo_Importacao_Pre-Projeto.xlsx">
                          <Typography variant="caption" display="block" sx={{ color: '' }} gutterBottom>
                            Baixar arquivo modelo
                          </Typography>
                        </a>
                      </React.Fragment>
                    }
                    {
                      (index !== 0 && index !== 2) &&
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 0 ? '' : 'Continuar'}
                      </Button>
                    }
                    {
                      (index === 2) &&
                      <Button
                        startIcon={<RestartAltIcon />}
                        variant="contained"
                        disabled={!isUploadCompleted && !selectedIds.length}
                        onClick={handleReset}
                        sx={{ mt: 1, mr: 4 }}
                      >
                        Recomeçar
                      </Button>
                    }
                    {
                      (index === 2) &&
                      <Button
                        startIcon={<UploadFileIcon />}
                        variant="contained"
                        color="secondary"
                        disabled={!isUploadCompleted && !selectedIds.length}
                        onClick={handleConfirm}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Confirmar
                      </Button>
                    }
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <br />
      <br />

      <DataGrid localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.importDetailsPreprojectId}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
        onRowSelectionModelChange={(newSelection: GridRowId[]) => {
          setSelectedIds(newSelection);
        }}
        getRowClassName={(params) => {
          return params.row.errors && params.row.errors.length > 0 ? 'error-row' : '';
        }}
        isRowSelectable={(params) => {
          return !(params.row.errors && params.row.errors.length > 0);
        }}
      />
    </PageContainerComponent>
  )
}

export default PreProjectInCharge