import {
  Chip,
  Grid,
  Typography,
  alpha,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import PageContainerComponent from "../../component/PageContainer";

import { AppDispatch, RootState } from "../../reducers/store";
import SearchComponent from "../../component/PowerSearch";
import PreProjetcs from "./PreProjects";
import Projetcs from "./Projects";
import PreProjectImport from "./PreProjectImport";
import { cleanRowsFiltereds } from "../../reducers/PowerSearchGrids/powerSearchGridsReducer";

const inputStyle = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.01),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.03),
  },
  ml: 0,
  border: "1px solid black",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    ml: 0,
    width: "100%",
  },
}));

const PowerSearchGrids = () => {
  const dispatch: AppDispatch = useDispatch();
  const { keyword } = useSelector((state: RootState) => state.powerSearchGrids);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCleanFilters = ()=>{
    dispatch(cleanRowsFiltereds())
  }

  return (
    <PageContainerComponent
      title=""
      style={{ marginLeft: isMobile ? 60 : 300 }}
    >
      <Grid
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        container
      >
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Pesquisar:
          </Typography>
        </Grid>
        <Grid sx={{ ml: 1 }} item xs={12} md={3}>
          <SearchComponent customStyle={inputStyle} />
        </Grid>
      </Grid>
      {keyword && (
        <Grid
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          container
          mt={1}
          mb={1}
        >
          <Typography variant="h6" gutterBottom>
            Palavra-chave:
          </Typography>
          <Grid ml={1} item>
            <Chip label={keyword} onDelete={handleCleanFilters} />
          </Grid>
        </Grid>
      )}
      <Projetcs />

      <PreProjetcs />

      <PreProjectImport />
    </PageContainerComponent>
  );
};

export default PowerSearchGrids;
