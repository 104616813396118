import { IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageContainerComponent from "../../component/PageContainer";
import { setImportDetailData } from "../../reducers/PreProjectImport/preProjectImportDetailReducer";
import { cleanRowSelected, getImportDetailById, getPreProjectImportGrid } from "../../reducers/PreProjectImport/preProjectImportGridReducer";
import { AppDispatch, RootState } from "../../reducers/store";
import { ptBR } from '@mui/x-data-grid/locales';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AppRegistration, UploadFileSharp, ViewListSharp } from '@mui/icons-material';
import ButtonGroupComponent from "../../component/ButtonGroup";
import { format } from "date-fns";

const PreProjectImportGridComponent = () => {
    const dispatch: AppDispatch = useDispatch();
    const rows = useSelector((state: RootState) => state.preProjectImportGrid.rows);
    const rowSelected = useSelector((state: RootState) => state.preProjectImportGrid.rowSelected);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const columns: GridColDef[] = [
        {
          field: "preProjectActions",
          headerName: "Visualizar",
          width: 100,
          filterable: false,
          renderCell: (params) => (
            <IconButton
              onClick={() => handleRowClick(params.row)}
              size="small"
              title="Ver"
            >
              <VisibilityIcon color="primary" />
            </IconButton>
          ),
        },
        { field: 'fileName', headerName: 'Nome Arquivo', width: 400, filterable: true },
        { field: 'fileTypeName', headerName: 'Extensão', width: 150, filterable: true },
        {
            field: "dateRegistration",
            headerName: "Data de importação",
            width: 150,
            filterable: true,
            valueFormatter: (params) => {      
              return params.value ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm") : '';
            },
        },
        { field: 'userRegistrationName', headerName: 'Responsável importação', width: 200, filterable: true },
    ];

    useEffect(() => {
        dispatch(getPreProjectImportGrid());
    }, [dispatch])

    useEffect(() => {
        if (rowSelected.length > 0) {          
            dispatch(setImportDetailData(rowSelected));
            dispatch(cleanRowSelected());
            navigate('/preproject/imports/detail');
        }
        // eslint-disable-next-line 
    }, [rowSelected])

    const handleRowClick = async (row: any) => {
        await dispatch(getImportDetailById(row.id));
    }
    
    const buttons = [
        { name: "Ver Pré-projetos", link: "/preprojects", icon: <ViewListSharp />},
        { name: "Cadastrar", link: "/preproject", icon: <AppRegistration />},
        { name: "Importar", link: "/preProject-incharge", icon: <UploadFileSharp /> },   
        { name: "Ver Importações", link: "/preproject/imports", icon: <ViewListSharp /> },
      ];

    return (
        <PageContainerComponent title="Importações de Pré-Projetos" style={{ marginLeft: isMobile ? 60 : 300 }}>
            <ButtonGroupComponent buttons={buttons} />
            <DataGrid localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} 
                rows={rows}
                columns={columns}
                getRowId={(row: any) => row.id}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                slots={{ toolbar: GridToolbar }}
            />
        </PageContainerComponent>
    );

}

export default PreProjectImportGridComponent;