import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../services/agent'
import loadingMiddleware from '../Loading/loadingMiddleware';
import { parseToDecimal } from '../../services/utils';

const COUNT = 10000
const PAGE = 1
const ORDER = 'asc'
const ORDER_PROPERTY = 'id'
const DUPLICITY_STATUS = 1
const PRE_PROJECT_URL = "pre-projects"

const PRE_PROJECT_DUPLICITY_URL = `${PRE_PROJECT_URL}?Count=${COUNT}&Page=${PAGE}&Order=${ORDER}&OrderProprety=${ORDER_PROPERTY}&StatusPreprojectId=${DUPLICITY_STATUS}`

const getPreprojectDuplicityById = createAsyncThunk(
  'preprojectDuplicityById/get',
  async (id: number, { dispatch }) => {
    const response = await get(PRE_PROJECT_URL + '/' + id)
    return response
  },
)

const getPreProjectDuplicity = createAsyncThunk(
  'getPreProjectDuplicity',
  async (_, { dispatch }) => {
    const response = await get(PRE_PROJECT_DUPLICITY_URL)
    return response
  },
)

const initialState = {
  rows: [],
  rowSelected: {
    id: null
  }
}

export const preprojetoduplicitySlice = createSlice({
  name: 'preProjetoDuplicity',
  initialState,
  reducers: {
    cleanRowSelected: (state) => {
      state.rowSelected = initialState.rowSelected
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreProjectDuplicity.fulfilled, (state, { payload }) => {        
        const transformedPayload = payload.map((item: any) => ({
          id: item.id,
          cityName: item.cityName,
          dateLastUpdate: item.dateLastUpdate,
          nameProject: item.nameProject,
          coPreProject: item.coPreProject,
          nameProjectDevelop: item.nameProjectDevelop,
          stateAcronym: item.stateAcronym,
          statusName: item.statusName,
          statusPreprojectId: item.statusPreprojectId,
          userRegistrationName: item.userRegistrationName,
          utilityName: item.utilityName,
          isAccessOpinion: item.isAccessOpinion,
          numberCapacityKwac: parseToDecimal(item.numberCapacityKwac),
          numberCapacityKwdc: parseToDecimal(item.numberCapacityKwdc),
          numberFactorCapacity: item.numberFactorCapacity?.toString().replace('.', ','),
          accessOpinionName: item.accessOpinionName,
        }));
        state.rows = transformedPayload;
      })
      .addCase(getPreprojectDuplicityById.fulfilled, (state, { payload }) => {
        state.rowSelected = payload;
      })
  },
})

export { getPreProjectDuplicity, loadingMiddleware, getPreprojectDuplicityById as getPreprojectById }

export const { cleanRowSelected } = preprojetoduplicitySlice.actions;

export default preprojetoduplicitySlice.reducer
