import { combineReducers } from 'redux';
import loadingSlice from './Loading/loadingReducer';
import preprojetoSlice from './PreProject/preProjectReducer';
import moduleQueueCountSlice from './QueueConunter/moduleQueueCountReducer';
import preprojetoinchargeSlice from './PreProjectInCharge/preProjectInChargeReducer';
import preprojetoDuplicitySlice from './PreProjectDuplicty/preProjectDuplicityReducer';
import dialogSlice from './Dialog/dialogReducer';
import userSlice from './User/userReducer';
import preProjectGridSlice from './PreProject/preProjectGridReducer';
import preProjectImportGridSlice from './PreProjectImport/preProjectImportGridReducer';
import networkConnectionSlice from './ProjectValidation/tabs/networkConnectionReducer';
import preProjectImportDetailSlice from './PreProjectImport/preProjectImportDetailReducer';
import preprojetoduplicityEditSlice from './PreProjectDuplicty/preProjectDuplicityEditReducer';
import reasonRefusalsModalSlice from './PreProjectDuplicty/reasonRefusalsModalReducer';
import projectAnalyzesSlice from './ProjectValidation/tabs/projectAnalyzesReducer';
import projectToValidateSlice from './ProjectValidation/projectToValidateReducer';
import georeferencingAnalyzesSlice from './ProjectValidation/tabs/georeferencingAnalyzesReducer';
import projectGridSlice from './Project/projectGridReducer';
import projectPendgindGridSlice from './Project/projectPendingGridReducer';
import powerSearchGridsSliceSlice from './PowerSearchGrids/powerSearchGridsReducer';
import noteHistorySlice from './ProjectValidation/tabs/noteHistoryReducer';
import projectDocumentsSlice from './ProjectValidation/tabs/projectDocumentsReducer';
import initialInformationSlice from './ProjectValidation/tabs/initialInformationReducer';
import supportMailSenderSlice from './Email/supportMailSenderReducer';
import preProjectViewSlice from './PreProject/preProjectViewReducer';
import projectValidationModalSlice from './ProjectValidation/ProjectValidationModalReducer';

const rootReducer = combineReducers({
  preprojeto: preprojetoSlice,
  preprojetoincharge: preprojetoinchargeSlice,
  preProjetoDuplicity: preprojetoDuplicitySlice,
  preProjectGrid: preProjectGridSlice,
  preProjectImportGrid: preProjectImportGridSlice,
  networkConnection: networkConnectionSlice,
  preProjectImportDetail: preProjectImportDetailSlice,
  preProjetoDuplicityEdit: preprojetoduplicityEditSlice,
  moduleQueueCount: moduleQueueCountSlice,
  reasonRefusalsModal: reasonRefusalsModalSlice,
  projectAnalyzes: projectAnalyzesSlice,
  projectToValidate: projectToValidateSlice,
  georeferencingAnalyzes: georeferencingAnalyzesSlice,
  projectGrid: projectGridSlice,
  projectPendingGrid: projectPendgindGridSlice,
  powerSearchGrids: powerSearchGridsSliceSlice,
  noteHistory: noteHistorySlice,
  projectDocuments: projectDocumentsSlice,
  initialInformation: initialInformationSlice,
  supportMailSender: supportMailSenderSlice,
  preProjectView: preProjectViewSlice,
  projectValidationModal: projectValidationModalSlice,
  user: userSlice,
  dialog: dialogSlice,
  loading: loadingSlice,
});

export default rootReducer;