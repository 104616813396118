import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import getPreprojectDuplicityEditForm from "../../data/forms/preprojectDuplicityEdit";
import { get } from "../../services/agent";
import {
    dataParser,
    findFieldByName,
    getKWdcValueWithMultiplier,
    handleFiller,
    inputValidation,
    insertValueIntoFieldByName,
    optionsFiller,
    parseToDecimal,
    parseToFloat,
    rowDataToFieldParser
} from "../../services/utils";
import { updatePreProject } from "../PreProject/preProjectReducer";
import loadingMiddleware from '../Loading/loadingMiddleware';

const ACCESS_OPINION_URL = "access-opinion"
const DEVELOPERS_URL = "developers"
const STATES_URL = "states"
const STATUS_PRE_PROJECT_URL = "status-pre-projects"
const PROJECT_URL = "projects"
const PRE_PROJECT_URL = "pre-projects"
const CITIES_URL = (id: string) => `${STATES_URL}/${id}/cities`
const UTILITIES_URL = (id: string) => `${STATES_URL}/${id}/utilities`

const getDuplicityAccessOpinionIds = createAsyncThunk(
    'accessOpinionDuplicity/get',
    async (_, { dispatch }) => {
        const response = await get(ACCESS_OPINION_URL)
        return response
    },
)

const getDuplicityDevelopers = createAsyncThunk(
    'developersDuplicity/get',
    async (_, { dispatch }) => {
        const response = await get(DEVELOPERS_URL)
        return response
    },
)

const getDuplicityStates = createAsyncThunk(
    'statesDuplicity/get',
    async (_, { dispatch }) => {
        const response = await get(STATES_URL)
        return response
    },
)

const getDuplicityStatuses = createAsyncThunk(
    'statusesDuplicity/get',
    async (_, { dispatch }) => {
        const response = await get(STATUS_PRE_PROJECT_URL)
        return response
    },
)

const getDuplicityCitiesAndUtilies = createAsyncThunk(
    'cities&utilitiesDuplicity/get',
    async (_, { getState, dispatch }) => {
        const { preProjetoDuplicityEdit }: any = getState();
        const stateField = findFieldByName(preProjetoDuplicityEdit.pageFields, 'stateId');
        let cities;
        let utilities;
        if (stateField?.value) {
            cities = await get(CITIES_URL(stateField?.value))
            utilities = await get(UTILITIES_URL(stateField?.value))
        }
        return { cities, utilities }
    },
)

const calculateKWdcDuplicityValue = createAsyncThunk(
    'calculateKWdcDuplicityValue',
    async (_, { dispatch }) => {
        return '';
    }
)

const listProjectDuplicates = createAsyncThunk(
    'listProjectDuplicates',
    async (id: number, { dispatch }) => {
        const response = await get(PRE_PROJECT_URL + '/' + id + '/duplicates')
        return response
    }
)

const formValidation = createAsyncThunk(
    'formValidationDuplicityEdit',
    async (_, { getState, dispatch }) => {
        dispatch(disableSave())
        const { preProjetoDuplicityEdit, user }: any = getState();
        const { isFormValid, pageFields } = preProjetoDuplicityEdit
        const dataToSend = dataParser(pageFields)

        if (isFormValid) {
            dataToSend['userRegistrationId'] = user.userRegistrationId
            dataToSend['userLastUpdateId'] = user.userRegistrationId
            dataToSend['id'] = preProjetoDuplicityEdit.preProjectId
            dataToSend.numberCapacityKwac = parseToFloat(dataToSend.numberCapacityKwac)
            dataToSend.numberFactorCapacity = Number.parseFloat(dataToSend.numberFactorCapacity.replace(',', '.'))
            await dispatch(updatePreProject(dataToSend))
        }
        dispatch(enableSave())

        return ''
    },
)

const getProjectById = createAsyncThunk(
    'projectByIdDuplicity/get',
    async (id: number, { dispatch }) => {
        const response = await get(PROJECT_URL + '/' + id)
        return response
    },
)

const getPreProjectById = createAsyncThunk(
    'preprojectByIdProjectDuplicityGrid/get',
    async (id: number, { dispatch }) => {
        const response = await get(PRE_PROJECT_URL + '/' + id)
        return response
    },
)

export const preprojetoduplicityEditSlice = createSlice({
    name: 'preProjetoDuplicityEdit',
    initialState: getPreprojectDuplicityEditForm(),
    reducers: {
        setPreProjectDuplicityData: (state, { payload }) => {
            const initialState = getPreprojectDuplicityEditForm();
            state.pageFields = initialState.pageFields;
            state.preProjectId = payload['id'];
            rowDataToFieldParser(payload, state.pageFields);
        },
        handleFieldFiller: (state, { payload }) => {
            state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value)]
        },
        clearDuplicityCitiesAndUtilities: (state) => {
            insertValueIntoFieldByName(state.pageFields, 'cityId', '');
            insertValueIntoFieldByName(state.pageFields, 'utilityId', '');
        },
        clearDuplicityStateSelected: (state) => {
            state.stateSelected = null;
        },
        clearDuplicityProjectSelected: (state) => {
            const initialState = getPreprojectDuplicityEditForm();
            state.projectSelected = initialState.projectSelected;
        },
        enableSave: (state) => {
            state.isFormDisabled = false;
        },
        disableSave: (state) => {
            state.isFormDisabled = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDuplicityAccessOpinionIds.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'accessOpinionId', payload)]
            })
            .addCase(getDuplicityDevelopers.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'developerId', payload)]
            })
            .addCase(getDuplicityStates.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'stateId', payload)];
                const stateField = findFieldByName(state.pageFields, 'stateId');
                state.stateSelected = stateField?.value;
                state.isLoading = false;
            })
            .addCase(getDuplicityCitiesAndUtilies.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'cityId', payload.cities)]
                state.pageFields = [...optionsFiller(state.pageFields, 'utilityId', payload.utilities)]
                state.isLoading = false;
            })
            .addCase(getDuplicityStatuses.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'statusPreprojectId', payload)]
            })
            .addCase(calculateKWdcDuplicityValue.fulfilled, (state) => {
                const kWacField = findFieldByName(state.pageFields, 'numberCapacityKwac');
                const multiplierField = findFieldByName(state.pageFields, 'numberFactorCapacity');
                const multiplierValue = multiplierField?.value;
                const kWacValue = parseToFloat(kWacField?.value);
                const kWdcValue = getKWdcValueWithMultiplier(kWacValue, multiplierValue);
                insertValueIntoFieldByName(state.pageFields, 'numberCapacityKwdc', parseToDecimal(kWdcValue || '0'))
            })
            .addCase(listProjectDuplicates.fulfilled, (state, { payload }) => {
                const transformedPayload = payload.result.map((item: any) => ({
                    id: item.id,
                    projectPreId: item.projectPreId,
                    projectId: item.projectId,
                    cityName: item.cityName,
                    dateRegistration: item.dateRegistration,
                    nameProject: item.nameProject,
                    coPreProject: item.coPreProject,
                    nameProjectDevelop: item.nameProjectDevelop,
                    stateAcronym: item.stateAcronym,
                    statusProject: item.statusProject,
                    statusPreprojectId: item.statusPreprojectId,
                    userRegistrationName: item.userRegistrationName,
                    utilityName: item.utilityName,
                }));
                state.projectsDuplicated = transformedPayload;
            })
            .addCase(getDuplicityCitiesAndUtilies.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPreProjectById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDuplicityStates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProjectById.fulfilled, (state, { payload }) => {
                state.projectSelected = {
                    ...payload,
                    dateRegistration: format(new Date(payload.dateRegistration), 'dd/MM/yyyy HH:mm'),
                    dateLastUpdate: format(new Date(payload.dateLastUpdate), 'dd/MM/yyyy HH:mm')
                }
            })
            .addCase(getPreProjectById.fulfilled, (state, { payload }) => {
                state.preProjectSelected = {
                    ...payload,
                    numberCapacityKwac: parseToDecimal(payload.numberCapacityKwac),
                    numberCapacityKwdc: parseToDecimal(payload.numberCapacityKwdc),
                    dateLastUpdate: format(
                        new Date(payload.dateLastUpdate),
                        'dd/MM/yyyy HH:mm'
                    ),
                }
            })
            .addCase(formValidation.pending, (state) => {
                state.pageFields = [...inputValidation(state.pageFields)];
                const isValid = !state.pageFields.filter(x => x.error === true)[0];
                state.isFormValid = isValid;
            })
    },
})

export {
    getDuplicityDevelopers,
    getDuplicityStates,
    getDuplicityCitiesAndUtilies,
    getDuplicityStatuses,
    listProjectDuplicates,
    calculateKWdcDuplicityValue,
    formValidation,
    getProjectById,
    getPreProjectById,
    getDuplicityAccessOpinionIds,
    loadingMiddleware
}

export const {
    handleFieldFiller,
    setPreProjectDuplicityData,
    clearDuplicityStateSelected,
    clearDuplicityProjectSelected,
    clearDuplicityCitiesAndUtilities,
    enableSave,
    disableSave } = preprojetoduplicityEditSlice.actions

export default preprojetoduplicityEditSlice.reducer