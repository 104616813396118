import { useEffect } from 'react';
import PageContainerComponent from '../../component/PageContainer';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Form from '../../component/Form'
import type { AppDispatch, RootState } from '../../reducers/store'
import { 
  getDevelopers, 
  getStates, 
  handleFieldFiller, 
  formValidation, 
  getStatuses, 
  getCitiesAndUtilies, 
  clearStateSelected, 
  getAccessOpinionIds } from '../../reducers/PreProject/preProjectReducer'
import { useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ErrorBoundary from '../../component/ErrorBoundary';
import { AppRegistration, UploadFileSharp, ViewListSharp } from '@mui/icons-material';
import ButtonGroupComponent from "../../component/ButtonGroup";

const PreProject = () => {
  const fields = useSelector((state: RootState) => state.preprojeto.pageFields);
  const isFormValid = useSelector((state: RootState) => state.preprojeto.isFormValid);
  const stateSelected = useSelector((state: RootState) => state.preprojeto.stateSelected);
  const isFormDisabled = useSelector((state: RootState) => state.preprojeto.isFormDisabled);
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleOnChange = (value: any, field: any) => {
    dispatch(handleFieldFiller({ field, value }))
    field.action && dispatch(field.action(value))
  }

  const handleSubmit = () => {
    dispatch(formValidation())
  }

  const initPage = () => {
    dispatch(getStatuses())
    dispatch(getDevelopers())
    dispatch(getStates())
    dispatch(getAccessOpinionIds())
  }

  useEffect(() => {
    initPage()
    // eslint-disable-next-line 
  }, [dispatch, isFormDisabled])

  useEffect(() => {
    if (stateSelected) {
      dispatch(getCitiesAndUtilies(stateSelected))
      dispatch(clearStateSelected())
    }
    // eslint-disable-next-line 
  }, [stateSelected])

  const buttons = [
    { name: "Ver pré-projetos", link: "/preprojects", icon: <ViewListSharp />},
    { name: "Cadastrar", link: "/preproject", icon: <AppRegistration />},
    { name: "Importar", link: "/preProject-incharge", icon: <UploadFileSharp /> },   
    { name: "Ver importações", link: "/preproject/imports", icon: <ViewListSharp /> },
  ];

  return (
    <ErrorBoundary>
      <PageContainerComponent title="Pré-projeto" style={{ marginLeft: isMobile ? 60 : 3000 }}>
        {!isFormValid &&
          <Alert severity="warning">
            <AlertTitle>Preencha todos os campos obrigatórios</AlertTitle>
          </Alert>
        }
        <br />
        <ButtonGroupComponent buttons={buttons} />
        <Form fields={fields} handleOnChange={handleOnChange} handleSubmit={handleSubmit} disabled={isFormDisabled} />      
      </PageContainerComponent>
    </ErrorBoundary>
  )
}

export default PreProject