import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { format } from "date-fns"
import { get } from "../../services/agent"
import loadingMiddleware from '../Loading/loadingMiddleware';
import { parseToDecimal } from "../../services/utils";

const PROJECT_URL = "projects"
const PROJECT_DETAILS_URL = "projects/details"
const PRE_PROJECT_URL = "pre-projects"

const getprojectById = createAsyncThunk(
    'projectById/get',
    async (id: number, { dispatch }) => {
        const response = await get(PROJECT_URL + '/' + id)
        return response
    },
)

const getPreprojectById = createAsyncThunk(
    'preprojectByIdProjectGrid/get',
    async (id: number, { dispatch }) => {
        const response = await get(PRE_PROJECT_URL + '/' + id)
        return response
    },
)

const getprojectGrid = createAsyncThunk(
    'getprojectGrid',
    async (_, { dispatch }) => {
        const response = await get(PROJECT_DETAILS_URL)
        return response
    },
)

const initialState = {
    rows: [],
    rowSelected: {
        id: null
    },
    preProject: {}
}

export const projectGridSlice = createSlice({
    name: 'projectGrid',
    initialState,
    reducers: {
        cleanRowSelected: (state) => {
            state.rowSelected = initialState.rowSelected
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getprojectGrid.fulfilled, (state, { payload }) => {                
                let transformedPayload = payload.map((item: any) => ({
                    id: item.id,
                    preId: item.preId,
                    stateName: item.stateName,
                    stateAcronym: item.stateAcronym,
                    cityName: item.cityName,
                    dateLastUpdate: item.dateLastUpdate,
                    name: item.name,
                    coproject: item.co,
                    nameProjectDevelop: item.nameProjectDevelop,
                    statusName: item.statusName,
                    statusprojectId: item.statusId,
                    userRegistrationName: item.userRegistrationName,
                    utilityName: item.utilityName,
                    numberCapacityKwdc: parseToDecimal(item.numberCapacityKwdc),
                    numberCapacityKwac: parseToDecimal(item.numberCapacityKwac),
                    numberFactorCapacity: item.numberFactorCapacity?.toString().replace('.', ','),
                    accessOpinionName: item.accessOpinionName,
                    //network reinforcement data                    
                    networkConectionNumberExtension: item.networkReinforcement?.numberExtension,
                    networkConectionValueCooperation: item.networkReinforcement?.valueCooperation,
                    networkConectionValueTotalCost: item.networkReinforcement?.valueTotalCost,
                    networkConectionUserRegistrationName: item.networkReinforcement?.userRegistrationName,
                    networkConectionDateRegistration: item.networkReinforcement?.dateRegistration,
                    networkConectionValueFinancialParticipationCustomer: item.networkReinforcement?.valueFinancialParticipationCustomer,
                    networkConectionDateIssueAccessOpinion: item.networkReinforcement?.dateIssueAccessOpinion,
                    networkConectionDateConstructionDeadlines: item.networkReinforcement?.dateConstructionDeadlines,
                    networkConectionDateMaximumConnection: item.networkReinforcement?.dateMaximumConnection,
                    networkConectionIsProjectDocumentsApprovedAccessOpinion: item.networkReinforcement?.isProjectDocumentsApprovedAccessOpinion,
                    networkConectionTypeCabinProjectName: item.networkReinforcement?.typeCabinProjectName,
                    networkConectionTypeProofProjectApprovalName: item.networkReinforcement?.typeProofProjectApprovalName,
                    networkConectionDateAccessRequestProtocol: item.networkReinforcement?.dateAccessRequestProtocol,
                    networkConectionCusd: item.networkReinforcement?.cusd,
                    networkConectionCcer: item.networkReinforcement?.ccer,
                    networkConectionContratoDeObras: item.networkReinforcement?.contratoDeObras,
                    networkConectionHaImpeditivoNoTracadoObraDeRede: item.networkReinforcement?.haImpeditivoNoTracadoObraDeRede,
                    //project analysis
                    projectAnalysisDateRegistration: item.projectAnalysis?.dateRegistration,
                    projectAnalysisUserRegistrationName: item.projectAnalysis?.userRegistrationName,
                    projectAnalysisIsGeoReferencing: item.projectAnalysis?.isGeoReferencing,
                    projectAnalysisValueGhi: item.projectAnalysis?.valueGhi,
                    projectAnalysisTypePropertyName: item.projectAnalysis?.typePropertyName,
                    projectAnalysisNumberSpecificProduction: item.projectAnalysis?.numberSpecificProduction,
                    projectAnalysisMatricula: item.projectAnalysis?.matricula,
                    projectAnalysisCar: item.projectAnalysis?.car,
                    projectAnalysisCcir: item.projectAnalysis?.ccir,
                    projectAnalysisItr: item.projectAnalysis?.itr,
                    projectAnalysisDocumentacaoImovel: item.projectAnalysis?.documentacaoImovel,
                    //georefer data                    
                    geoReferDateRegistration: item.geoReferencingAnalysis?.dateRegistration,
                    geoReferUserRegistrationName: item.geoReferencingAnalysis?.userRegistrationName,
                    geoReferFileName: item.geoReferencingAnalysis?.fileName,
                    geoReferExtensionName: item.geoReferencingAnalysis?.extensionName,
                    geoReferInclinacao: item.geoReferencingAnalysis?.inclinacao,
                    geoReferRiscoDeAlagamento: item.geoReferencingAnalysis?.riscoDeAlagamento,
                    geoReferVegetacao: item.geoReferencingAnalysis?.vegetacao,
                    geoReferApp: item.geoReferencingAnalysis?.app,
                    geoReferReservaLegal: item.geoReferencingAnalysis?.reservaLegal,
                    geoReferUcs: item.geoReferencingAnalysis?.ucs,
                    geoReferAssentamento: item.geoReferencingAnalysis?.assentamento,
                    geoReferDireitoMinerario: item.geoReferencingAnalysis?.direitoMinerario,
                    geoReferRiscoDeCaverna: item.geoReferencingAnalysis?.riscoDeCaverna,
                    geoReferSitiosArqueologicos: item.geoReferencingAnalysis?.["sítiosArqueologicos"],
                    geoReferQuilombola: item.geoReferencingAnalysis?.quilombola,
                    geoReferIndigena: item.geoReferencingAnalysis?.["indígena"],
                    geoReferAreaDeComportaAPotencia: item.geoReferencingAnalysis?.areaDeComportaAPotencia,
                    geoReferRiscoDeInundacao: item.geoReferencingAnalysis?.riscoDeInundacao,
                }));
                state.rows = transformedPayload;
            })
            .addCase(getprojectById.fulfilled, (state, { payload }) => {
                state.rowSelected = {
                    ...payload,
                    dateRegistration: format(new Date(payload.dateRegistration), 'dd/MM/yyyy HH:mm'),
                    dateLastUpdate: format(new Date(payload.dateLastUpdate), 'dd/MM/yyyy HH:mm')
                }
            })
            .addCase(getPreprojectById.fulfilled, (state, { payload }) => {
                state.preProject = {
                    ...payload,
                    dateLastUpdate: format(new Date(payload.dateLastUpdate), 'dd/MM/yyyy HH:mm')
                }
            })
    }
})

export { getprojectGrid, loadingMiddleware, getprojectById, getPreprojectById };

export const { cleanRowSelected } = projectGridSlice.actions;

export default projectGridSlice.reducer;