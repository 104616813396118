export const notNullValidation = (value: any) : boolean => {
    return value === '';
}

export const rangeValidation = (value: any, {start, end}: any) : boolean => {
    const n = value.includes('.') ? parseFloat(value.replace('.', '').replace(',', '.')) : parseFloat(value);
    return !(!isNaN(n) && n >= start && n <= end)
}

export const isValidDate = (value: string): boolean => {
    const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(202[0-9]|203[0-9]|2040)$/;  
    return !dateRegex.test(value);
}