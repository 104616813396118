import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { newForm } from "../reducers/PreProject/preProjectReducer";
import { useDispatch } from "react-redux";

const ButtonGroupComponent = ({ buttons }: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleGoToPage = (link: any) => {
    if(link === '/preproject') {
      dispatch(newForm());
    }

    navigate(link);
  };

  return (
    <Box
      sx={{
        display: "flex",
        "& > *": {
          m: 1,
        },
        justifyContent: "center",
      }}
    >
      <ButtonGroup
        orientation={(isMobile || isMdScreen) ? "vertical" : undefined}
        sx={{ mb: 3 }}
        variant="text"
        aria-label="Basic button group"
      >
        {buttons.map((item: any) => (
          <Button            
            disabled={currentPage === item.link && item.link !== '/preproject'}
            sx={{ py: 0, minWidth: '16em' }}
            onClick={() => handleGoToPage(item.link)}
            endIcon={item.icon}
          >
            {item.name}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default ButtonGroupComponent;
