import { useTheme } from "@mui/material/styles";
import { Grid, Box } from "@mui/material";

interface FullWidthGridProps {
  grids: any[];
}

export default function FullWidthGrid({ grids }: FullWidthGridProps) {
  const theme = useTheme();

  const groups = [];
  for (let i = 0; i < grids.length; i += 4) {
    groups.push(grids.slice(i, i + 4));
  }

  return (
    <>
      {groups.map((group, index) => (
        <Grid key={index} container spacing={2} sx={{ marginBottom: 2 }}> 
          {group.map((grid, idx) => (
            <Grid key={idx} item  sx={{ padding: 2, width: '20em' }}> 
              <Box sx={{ color: theme.palette.text.primary}}>
                <strong>{grid.key}:</strong><br/> {grid.value}
              </Box>
            </Grid>
          ))}
          {group.length < 4 && (
            <Grid item xs={12 - group.length * 3}></Grid>
          )}
        </Grid>
      ))}
    </>
  );
}