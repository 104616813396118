import { AppRegistration, UploadFileSharp, ViewListSharp } from "@mui/icons-material";
import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonGroupComponent from "../../component/ButtonGroup";
import CustomFormComponent from "../../component/CustomForm";
import PageContainerComponent from "../../component/PageContainer";
import {
    clearStateSelected,
    getAccessOpinionIds,
    getCitiesAndUtilies,
    getDevelopers,
    getStates,
    getStatuses,
    handleFieldFiller
} from "../../reducers/PreProject/preProjectViewReducer";
import { AppDispatch, RootState } from "../../reducers/store";

const PreProjectView = () => {
    const {
        pageFields,
        stateSelected,
        isLoading,
        btnConfig } = useSelector((state: RootState) => state.preProjectView);
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOnChange = (value: any, field: any) => {
        dispatch(handleFieldFiller({ field, value }));
        field.action && dispatch(field.action(value));
    };

    const initPage = () => {
        dispatch(getAccessOpinionIds())
        dispatch(getStatuses())
        dispatch(getDevelopers())
        dispatch(getStates())
    }

    useEffect(() => {
        if (stateSelected && !isLoading) {
            dispatch(getCitiesAndUtilies(stateSelected))
            dispatch(clearStateSelected())
        }
        // eslint-disable-next-line 
    }, [stateSelected, isLoading])

    useEffect(() => {
        initPage()
        // eslint-disable-next-line 
    }, [dispatch])

    const buttons = [
        { name: "Ver pré-projetos", link: "/preprojects", icon: <ViewListSharp /> },
        { name: "Cadastrar", link: "/preproject", icon: <AppRegistration /> },
        { name: "Importar", link: "/preProject-incharge", icon: <UploadFileSharp /> },
        { name: "Ver importações", link: "/preproject/imports", icon: <ViewListSharp /> },
    ];

    return (
        <React.Fragment>
            <PageContainerComponent title="Pré-projeto (Visualização)" style={{ marginLeft: isMobile ? 60 : 3000 }}>
                <br />
                <ButtonGroupComponent buttons={buttons} />
                <CustomFormComponent
                    fields={pageFields}
                    hasSaveButton={false}
                    handleOnChange={handleOnChange}
                    btnConfig={btnConfig} />
            </PageContainerComponent>

        </React.Fragment>
    )
}

export default PreProjectView