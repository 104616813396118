import { notNullValidation } from "../../services/validations"

const anyList: any[] = []
export function getNoteHistoryInitialState() {
    return {
        pageFields: [
            {
                id: 1,
                error: false,
                label: "Tipo*",
                name: "systemFormId",
                required: true,
                value: '',
                options: [],
                type: 2,
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 2,
                error: false,
                label: "Destinatário*",
                name: "userRecipientId",
                required: true,
                value: '',
                options: [],
                type: 2,
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 3,
                error: false,
                label: "Comentário*",
                name: "descriptionComment",
                required: true,
                value: '',
                type: 4,
                multiline: true,
                xs: 10,
                validation: (value: any) => notNullValidation(value)
            },
        ],
        btnConfig: {
            visible: true,
            value: 'Vincular',
            xs: 2,
            height: '50%',
            iconType: 1
        },
        systemForms: anyList,
        noteHistorys: anyList,
        firstTouch: true,
        isFormValid: true,
    }
}