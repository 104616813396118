import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getV2, post, remove } from "../../../services/agent";
import { dataParser, handleFiller, inputValidation, insertValueIntoFieldByName, optionsFiller } from "../../../services/utils";
import { setProjectValidationModal } from "../ProjectValidationModalReducer";
import HttpResponseHandler from "../../Error/httpResponseHandler";
import { toBase64 } from "../../PreProjectInCharge/preProjectInChargeReducer";
import loadingMiddleware from '../../Loading/loadingMiddleware';
import { format } from "date-fns";
import getProjectDocumentsInitialState from "../../../data/forms/projectDocuments";
import { setDialog } from "../../Dialog/dialogReducer";

const PROJECT_URL = "projects"
const FILES_URL = "files"
const FILES_DOWNLOAD_URL = (id: string) => `${FILES_URL}/${id}/downloads`
const PROJECT_FILE_URL = (id: string) => `${PROJECT_URL}/${id}/file`
const CHECK_ANALYSIS_URL = (id: string) => `${PROJECT_URL}/${id}/check-analysis`
const PROJECT_ANALYSIS_URL = (id: string) => `${PROJECT_URL}/${id}/complete-analysis`
const DELETE_FILE_URL = (id: string, fileId: string) => `${PROJECT_URL}/${id}/file/${fileId}`
const FILE_TYPES_URL = "file-types"

const getProjectFiles = createAsyncThunk(
    'projectFiles/get',
    async (_, { getState, dispatch }) => {
        const { projectToValidate }: any = getState();
        const projectId = projectToValidate.projectSelected.id;
        const response = await get(PROJECT_FILE_URL(projectId))
        return response
    }
)

const removeFile = createAsyncThunk(
    'projectFile/remove',
    async (id: any, { getState, dispatch }) => {
        const { projectToValidate }: any = getState();
        const projectId = projectToValidate.projectSelected.id;
        const response = await remove(DELETE_FILE_URL(projectId, id))

        if (response.status === 200) {
            dispatch(setDialog({
                title: 'Remoção de Documento',
                message: 'Documento excluído com sucesso.'
            }))
            dispatch(newForm())
            dispatch(getFileTypes())
            dispatch(getProjectFiles())
        } else {
            const responseJson = await response.json()
            dispatch(setDialog({
                title: 'Remoção de Documento',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
            }))
        }
    }
)

const downloadFile = createAsyncThunk(
    'downloadFile/get',
    async (row: any, { getState, dispatch }) => {
        const response = await getV2(FILES_DOWNLOAD_URL(row.id))
        const responseJson = await response.json()

        if (response.status === 200) {
            return { response: responseJson, data: row }
        } else {
            dispatch(setDialog({
                title: 'Visualização de Documento',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
            }))
        }
    }
)

const getFileTypes = createAsyncThunk(
    'projectFileTypes/get',
    async (_, { dispatch }) => {
        const response = await get(FILE_TYPES_URL)
        return response
    }
)

const sendFile = createAsyncThunk(
    'sendfile/projectDocs',
    async (data: any, { getState, dispatch }) => {
        const { user, projectToValidate }: any = getState();
        const userRegistrationId = user.userRegistrationId;
        const projectId = projectToValidate.projectSelected.id;
        let base64File = await toBase64(data.fileData)
        
        base64File = (base64File as string).replace(/^.*?base64,/, "");
        
        const contract = {
            extension: data.fileTypeId,
            contentBase64: base64File,
            fileName: data.fileName,
            userRegisterId: userRegistrationId
        }

        const response = await post(PROJECT_FILE_URL(projectId), contract)
        const responseJson = await response.json()

        dispatch(setDialog({
            title: 'Inclusão de Documento',
            message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
        }))

        if (response.status === 200 || response.status === 201) {
            dispatch(newForm())
            dispatch(getFileTypes())
            dispatch(getProjectFiles())
        }

        return responseJson
    },
)

const formValidation = createAsyncThunk(
    'formValidation/projectDocs',
    async (_, { getState, dispatch }) => {
        const { projectDocuments }: any = getState();
        const { isFormValid, pageFields } = projectDocuments
        const dataToSend = dataParser(pageFields)

        if (isFormValid) {
            dispatch(sendFile(dataToSend))
        }

        return ''
    },
)

const handleFileChange = createAsyncThunk(
    'fileChange/projectDocs',
    async (file: any, { dispatch }) => {
        return file
    }
)

const completeAnalysis = createAsyncThunk(
    'completeProjectAnalysis/projectValidation',
    async (_, { getState, dispatch }) => {
        const { projectToValidate }: any = getState();
        const projectId = projectToValidate.projectSelected.id;
        const response = await post(PROJECT_ANALYSIS_URL(projectId))
        const responseJson = await response.json()

        if (response.status === 200) {
            dispatch(setDialog({
                title: 'Análise do projeto concluída!',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson)),
                redirect: '/projectgrid'
            }))
        } else {
            dispatch(setDialog({
                title: 'Não foi possível concluir análise do projeto!',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
            }))
        }
        return response
    }
)

const checkAnalysis = createAsyncThunk(
    'checkProjectAnalysis/projectValidation',
    async (_, { getState, dispatch }) => {
        const { projectToValidate }: any = getState();
        const projectId = projectToValidate.projectSelected.id;
        const response = await post(CHECK_ANALYSIS_URL(projectId))
        const responseJson = await response.json()

        if (response.status === 200) {
            dispatch(completeAnalysis())
        } else {
            dispatch(setProjectValidationModal({
                title: 'Análise do projeto concluída!',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
            }))
        }
        return response
    }
)

export const projectDocumentsSlice = createSlice({
    name: 'projectDocuments',
    initialState: getProjectDocumentsInitialState(),
    reducers: {
        newForm: (state) => {
            const initialState = getProjectDocumentsInitialState();
            state.pageFields = initialState.pageFields;
            state.isFormValid = initialState.isFormValid;
            state.documents = initialState.documents;
        },
        handleFieldFiller: (state, { payload }) => {
            state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value)]
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectFiles.fulfilled, (state, { payload }) => {
                const transformedPayload = payload.map((item: any) => ({
                    id: item.fileId,
                    fileId: item.fileId,
                    projectId: item.projectId,
                    fileName: item.fileName,
                    fileTypeName: item.fileTypeName,
                    userName: item.userName,
                    dateRegistration: format(new Date(item.dateRegistration), 'dd/MM/yyyy HH:mm'),
                }));
                state.documents = transformedPayload;
            })
            .addCase(formValidation.pending, (state) => {
                state.pageFields = [...inputValidation(state.pageFields)];
                const isValid = !state.pageFields.filter(x => x.error === true)[0];
                state.isFormValid = isValid;
            })
            .addCase(getFileTypes.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'fileTypeId', payload)];
            })
            .addCase(handleFileChange.fulfilled, (state, { payload }) => {
                insertValueIntoFieldByName(state.pageFields, 'fileName', payload.name);
            })
            .addCase(downloadFile.fulfilled, (state, { payload }) => {
                if (payload) {
                    const fileTypeId = payload.data.fileTypeId;
                    const fileName = payload.data.fileName;

                    let contentType = '';

                    switch (fileTypeId) {
                        case 1:
                            contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                            break;
                        default:
                            contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                            break;
                    }

                    const linkSource = `data:${contentType};base64,${payload.response}`;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            })
            .addCase(checkAnalysis.fulfilled, (state, { payload }) => {
                console.log(payload)
            })
            .addCase(completeAnalysis.fulfilled, (state, { payload }) => {
                console.log(payload)
            })
    }
})

export {
    getFileTypes,
    getProjectFiles,
    handleFileChange,
    completeAnalysis,
    checkAnalysis,
    removeFile,
    formValidation,
    downloadFile,
    loadingMiddleware,
};

export const {
    newForm,
    handleFieldFiller,
} = projectDocumentsSlice.actions

export default projectDocumentsSlice.reducer