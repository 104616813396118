import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../services/agent'

const initialState = {
  duplicityPreProjectCount: 0,
  underReviewProjectCount: 0,
}

const DUPLICITY_PRE_PROJECT_COUNT = "pre-projects/duplicates/count"
const UNDER_REVIEW_PROJECT_COUNT = "projects/under-review/count"

const getDuplicityPreProjectCount = createAsyncThunk(
  'duplicityPreProject/count',
  async () => {
    const response = await get(DUPLICITY_PRE_PROJECT_COUNT)
    return response
  },
)

const getUnderReviewProjectCount = createAsyncThunk(
  'underReviewProject/count',
  async () => {
    const response = await get(UNDER_REVIEW_PROJECT_COUNT)
    return response
  },
)


export const moduleQueueCountSlice = createSlice({
  name: 'moduleQueueCount',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(getDuplicityPreProjectCount.fulfilled, (state, { payload }) => {
      state.duplicityPreProjectCount = payload
    })
    .addCase(getUnderReviewProjectCount.fulfilled, (state, { payload }) => {
      state.underReviewProjectCount = payload
    })
  },
})

export { getDuplicityPreProjectCount, getUnderReviewProjectCount }

export default moduleQueueCountSlice.reducer