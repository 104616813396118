import { InputType } from '../../types'
import { isValidDate, notNullValidation } from '../../services/validations'
import { getJustifications } from '../../reducers/ProjectValidation/tabs/networkConnectionReducer'

export function getNewNetworkConnectionForm() {

  return {
    id: 0,
    isFormValid: true,
    isFormDisabled: false,
    isUpdating: false,    
    oldSystemFields: [],
    rows: [] as any[],
    btnConfig: {
      visible: false,
      disabled: false,
    },
    systemFields: [] as any[],
    impeditiveFields: [] as any[],
    pageFields: [
      {
        id: 1,
        error: false,
        label: "Extensão (km)",
        name: "numberExtension",
        required: false,
        inputType: InputType.NUMBER,
        value: '',
        type: 1,
        mask: 'decimalMask',
      },
      {
        id: 3,
        error: false,        
        label: "Participação financeira do cliente*",
        name: "valueFinancialParticipationCustomer",
        startAdornment: 'R$',
        required: true,
        inputType: InputType.NUMBER,
        value: '0,00',
        type: 1,
        validation: (value: any) => notNullValidation(value),
        mask: 'decimalMask'
      },
      {
        id: 4,
        error: false,
        label: "Custo total da obra*",
        startAdornment: 'R$',
        name: "valueTotalCost",
        required: true,
        inputType: InputType.NUMBER,
        value: '0,00',
        type: 1,
        validation: (value: any) => notNullValidation(value),
        mask: 'decimalMask'
      },
      {
        id: 5,
        error: false,
        label: "Responsabilidade da obra*",
        name: "responsibleEntityId",
        required: true,
        value: '',
        type: 2,
        validation: (value: any) => notNullValidation(value)
      },
      {
        id: 6,
        error: false,
        label: "Data de protocolo da solicitação de acesso",
        name: "dateIssueAccessOpinion",
        required: false,
        value: '',
        type: 1,
        validation: (value: any) => isValidDate(value),
        mask: 'dateMask'
      },
      {
        id: 7,
        error: false,
        label: "Data de emissão do parecer de acesso*",
        name: "dateAccessRequestProtocol",
        required: true,
        value: '',
        type: 1,
        validation: (value: any) => isValidDate(value),
        mask: 'dateMask'
      },
      {
        id: 8,
        error: false,
        label: "Data máxima para conexão*",
        name: "dateMaximumConnection",
        required: true,
        value: '',
        type: 1,
        validation: (value: any) => isValidDate(value),
        mask: 'dateMask'
      },
      {
        id: 9,
        error: false,
        label: "Prazo de obras (Inicial)*",
        name: "dateConstructionDeadlines",
        required: true,
        inputType: InputType.NUMBER,
        value: '',
        type: 1,
        validation: (value: any) => isValidDate(value),
        mask: 'dateMask'
      },
      {
        id: 10,
        error: false,
        label: "CUSD",
        name: "CUSD",
        required: false,
        value: '',
        type: 2,
        optionsFilter: [1, 3, 4, 6, 7, 8],
        options: [],
      },
      {
        id: 11,
        error: false,
        label: "CCER",
        name: "CCER",
        required: false,
        value: '',
        type: 2,
        optionsFilter: [1, 3, 4, 6, 7, 8],
        options: [],
      },
      {
        id: 12,
        error: false,
        label: "Contrato de obras",
        name: "workContract",
        required: false,
        value: '',
        type: 2,
        optionsFilter: [1, 3, 4, 6, 7, 8],
        options: [],
      },
      {
        id: 13,
        error: false,
        label: "Há impeditivo no traçado da obra de rede*",
        name: "impeditiveTypeEnum",
        required: true,
        value: '',
        type: 2,
        optionsFilter: [3, 5, 6, 7, 8, 9],
        options: [],
        validation: (value: any) => notNullValidation(value),
      },
      {
        id: 14,
        error: false,
        label: "Projeto de cabine",
        name: "typeCabinProjectId",
        required: false,
        value: '',
        type: 2,
        options: [],
      },
      {
        id: 18,
        error: false,
        label: "Projeto Elétrico",
        name: "electricalProjectId",
        required: false,        
        value: '',
        type: 2,
        options: [],
      },
      {
        id: 17,
        error: false,
        label: "Dados de curto",
        name: "shortDataId",
        required: false,        
        value: '',
        type: 2,
        options: [],
      },
      {
        id: 15,
        error: false,
        width: '25em',
        label: "Comprovante de aprovação do projeto elétrico",
        name: "typeProofProjectApprovalId",
        required: false,
        value: '',
        type: 2,
        options: [],
      },
      {
        id: 16,
        error: false,
        width: '23em',
        label: "Comprovante de aprovação de cabine",
        name: "typeProofCabinApprovalId",
        required: false,        
        value: '',
        type: 2,
        options: [],
      },
      {
        id: 19,
        error: false,
        label: "Documentos do projeto aprovado relativo ao parecer de acesso",
        name: "isProjectDocumentsApprovedAccessOpinion",
        required: false,
        inputType: InputType.BOOLEAN,
        value: true,
        type: 3,
        options: [
          {
            id: 0,
            name: 'Sim',            
            value: true
          },
          {
            id: 1,
            name: 'Não',
            value: false
          },
        ],
      },
    ],
    justificationForm: {
      isFormValid: true,
      isFormDisabled: false,
      btnConfig: {
        disabled: false,
        visible: true,
        value: 'Vincular',
        xs: 2,
        height: '50%',
        iconType: 1
      },
      pageFields: [
        {
          id: 1,
          error: false,
          label: "Campo*",
          name: "systemFieldFormEnum",
          required: true,
          value: '',
          type: 2,
          options: [],
          action: (value: number)=> getJustifications(value),
          validation: (value: any) => notNullValidation(value)
        },
        {
          id: 2,
          error: false,
          label: "Motivo*",
          name: "justicationId",
          required: true,
          value: '',
          type: 2,
          options: [],
          validation: (value: any) => notNullValidation(value)
        },
        {
          id: 3,
          error: false,
          label: "Comentário",
          name: "descJustification",
          required: false,
          value: '',
          type: 4,
          xs: 10,
          options: [],
        }
      ],
    }
  }
}

export default getNewNetworkConnectionForm