import { handleFileChange } from "../../reducers/ProjectValidation/tabs/projectDocumentsReducer"
import { notNullValidation } from "../../services/validations"

const anyList: any[] = []

export function getProjectDocumentsInitialState() {
    return {
        pageFields: [
            {
                id: 1,
                error: false,
                label: "Tipo de arquivo",
                name: "fileTypeId",
                required: true,
                value: '',
                options: [],
                type: 2,
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 2,
                error: false,
                label: "Arquivo",
                name: "fileName",
                required: true,
                value: '',
                type: 1,
                disabled: true,
                width: '42em',
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 3,
                error: false,
                name: "fileData",
                required: true,
                value: '',
                type: 5,
                action: (file: any) => handleFileChange(file)
            },
        ],
        btnConfig: {
            visible: true,
            value: 'Incluir',
            xs: 2.15,
            height: '50%',
            iconType: 3
        },
        documents: anyList,
        isFormValid: true,
    }
}

export default getProjectDocumentsInitialState