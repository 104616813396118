class HttpResponseHandler {
  static getHttpStatusMessage(statusHttp: any, message: string) {

    try {
      const parsedMessage = JSON.parse(message);
      if (Array.isArray(parsedMessage)) {
        message = '';
        for (let i = 0; i < parsedMessage.length; i++) {
          if (parsedMessage[i].descricao) {
            message += parsedMessage[i].descricao;
            if (i < parsedMessage.length - 1) {
              message += '<br/>';
            }
          }
        }
      }
      else if (parsedMessage && parsedMessage.mensagem) {
        message = parsedMessage.mensagem.replace(parsedMessage.id, `<strong>${parsedMessage.id}</strong>`)
      }
    } catch (e) {
    }

    switch (statusHttp) {
      case 200: // OK
        return 'Requisição bem-sucedida.' + (message ? '</br></br>' + message : '');
      case 201: // Created
      if (message !== 'Inserido com Sucesso' && message !== '\"Inserido com Sucesso\"' && !message.includes('https')) {
        return 'Dados salvos com sucesso.' + (message ? '</br></br>' + message : '');
      } else {
        return 'Dados salvos com sucesso.';
      }
      case 400: // Bad Request      
        return 'Requisição inválida. Por favor, verifique se algum dado deixou de ser preenchido.' + (message ? '</br></br>' + message : '');
      case 401: // Unauthorized
        return 'Não autorizado. Por favor, verifique sua autenticação.';
      case 403: // Forbidden
        return 'Proibido. Você não tem permissão para acessar este recurso.';
      case 404: // Not Found
        return 'Recurso não encontrado.';
      case 409: // Conflict
        return (message ? '</br></br>' + message : 'Conflito.');
      default:
        return 'Ocorreu um erro interno no servidor. Por favor, tente novamente mais tarde.';
    }
  }
}

export default HttpResponseHandler;