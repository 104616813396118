import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, post, put } from '../../../services/agent'
import { optionsFiller, handleFiller, inputValidation, dataParser, multipleFieldsOptionsFiller, mapFieldsToSystemFormEnums, mapFieldValues, parseToFloat, parseToDecimal } from '../../../services/utils'
import { setDialog } from '../../Dialog/dialogReducer'
import getNewProjectAnalyzesForm from '../../../data/forms/projectanalyzes'
import HttpResponseHandler from '../../Error/httpResponseHandler'
import loadingMiddleware from '../../Loading/loadingMiddleware';

const projectAnalyzesBodyParser = (data: any, id: number) => {
    return {
        projectId: id,
        userRegistrationId: 0,
        isGeoReferencing: data.isGeoReferencing,
        numberSpecificProduction: parseToFloat(data.numberSpecificProduction),
        valueGhi: parseToFloat(data.valueGhi),
        typePropertyId: data.typePropertyId,
        systemFieldFormImpeditiveTypeViewModels: [] as any[],
        systemFieldFormJustificationViewModels: [] as any[]
    }
}

const checkIfImpeditiveWasJustified = (impeditiveTypesArr: any[], justificationArr: any[], systemFields: any[], impeditiveFields: any[]) => {
    const rules: { [key: number]: number[] } = {
        1: [3, 2],
        2: [3, 2],
        3: [2],
        22: [2],
    };

    const checkResult: any = {
        status: true,
        msg: ''
    }

    var msg = ""
    for (const item of impeditiveTypesArr) {
        if (rules[item.systemFieldFormEnum] && rules[item.systemFieldFormEnum].includes(item.impeditiveTypeEnum)) {
            const isJustified = justificationArr.some(justification =>
                justification.systemFieldFormEnum === item.systemFieldFormEnum
            );

            if (!isJustified) {
                const field = systemFields.find((field) => field.id === item.systemFieldFormEnum).name
                const impeditive = impeditiveFields.find((impeditive) => impeditive.id === item.impeditiveTypeEnum).name
                checkResult.status = false
                msg = msg + `Campo: "${field}" se a resposta for "${impeditive}"</br>`
            }
        }
    }

    if (msg !== '') {
        msg = `<b>É obrigatório justificar os campos:</b></br></br>${msg}`;
    }

    checkResult.msg = msg;
    return checkResult;
}

const updatePageFieldsWithPayload = (pageFields: any[], payload: any, systemFields: any[]) => {

    const fieldMappings: { [key: string]: string } = {
        1: "propertyRegistration",
        2: "CAR",
        3: "CCIR",
        22: "propertyDocumentation",
    };
    payload = {
        ...payload,
        ...mapFieldValues(payload.systemFieldFormImpeditiveTypeViewModels, systemFields, fieldMappings),
    }

    for (const field of pageFields) {
        if (field.name in payload) {
            field.value = payload[field.name];
        }
    }
    return pageFields;
}

const IMPEDITIVE_TYPES_URL = "impeditive-types"
const PROPERTY_TYPES_URL = "type-properties"
const GET_JUSTIFICATIONS_BY_ID_URL = (id: number) => `systems-form-fields/${id}/justification`
const PROJECT_ANALYZES_URL = 'project-analyzes'
const SYSTEM_FROM_FIELDS_URL = (id: number) => `system-forms/${id}/system-form-field`
const GET_EXISTING_PROJECT_BY_ID_URL = (id: number) => `projects/${id}/project-analysis`
const UPDATE_EXISTING_PROJECT_BY_ID_URL = (id: number) => `project-analyzes/${id}`

const getImpeditiveTypes = createAsyncThunk(
    'impeditiveTypes/get',
    async (_) => {
        const response = await get(IMPEDITIVE_TYPES_URL)
        return response
    },
)

const getPropertyTypes = createAsyncThunk(
    'propertyTypes/get',
    async (_) => {
        const response = await get(PROPERTY_TYPES_URL)
        return response
    },
)

const getSystemFormField = createAsyncThunk(
    'systemFormFields/get',
    async (_) => {
        const response = await get(SYSTEM_FROM_FIELDS_URL(2))
        return response
    },
)

const getJustifications = createAsyncThunk(
    'justifications/get',
    async (id: number) => {
        const response = await get(GET_JUSTIFICATIONS_BY_ID_URL(id))
        return response
    },
)

const getExistingProjectById = createAsyncThunk(
    'getExistingProjectAnalyzes/get',
    async (id: number, { dispatch }) => {
        const response = await get(GET_EXISTING_PROJECT_BY_ID_URL(id))

        if (response.id) {
            response.numberSpecificProduction = parseToDecimal(response.numberSpecificProduction)
            response.valueGhi = parseToDecimal(response.valueGhi)
            dispatch(handleFillInExistingFields(response))
        }
    },
)

const createProjectAnalyzes = createAsyncThunk(
    'createProjectAnalyzes/post',
    async (data: any, { dispatch }) => {
        const response = await post(PROJECT_ANALYZES_URL, data)
        const responseJson = await response.json()
        response.status === 400 && dispatch(setDialog({ title: 'Análise de Projeto', message: 'Campos inválidos', messageArray: Object.values(responseJson.errors) }))
        response.status === 500 && dispatch(setDialog({ title: 'Análise de Projeto', message: 'Sistema apresentando erro, por favor, tente novamente mais tarde.' }))

        if (response.status === 200 || response.status === 201) {
            dispatch(setDialog({ title: 'Projeto', message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson)) }))
        }
    },
)

const updateProjectAnalizes = createAsyncThunk(
    'updateProjectAnalizes/put',
    async ({ dataToSend, id }: { dataToSend: any, id: number }, { dispatch }) => {

        try {
            const msg = "Dados salvos com sucesso"
            await put(UPDATE_EXISTING_PROJECT_BY_ID_URL(id), dataToSend)
            dispatch(setDialog({ title: 'Projeto', message: msg }))

        } catch (err: any) {
            const msg = `Ocorreu um erro ao tentar atualizar os dados, por favor, tente novamente mais tarde.<br/>
        ${err.message}
        `
            dispatch(setDialog({ title: 'Projeto', message: msg }))

        } finally {
            return;
        }

    },
)

const formValidation = createAsyncThunk(
    'formValidationProjectAnalyzes',
    async (_, { getState, dispatch }) => {
        const { projectAnalyzes, projectToValidate, user }: any = getState();
        const { isFormValid, pageFields, systemFields, rows, impeditiveFields, isUpdating, oldSystemFields, id } = projectAnalyzes
        const fieldsData = dataParser(pageFields)

        if (isFormValid) {
            const dataToSend = projectAnalyzesBodyParser(fieldsData, projectToValidate.projectSelected.id)
            dataToSend.systemFieldFormImpeditiveTypeViewModels = mapFieldsToSystemFormEnums(fieldsData, systemFields)
            dataToSend.systemFieldFormJustificationViewModels = rows.map(
                (justification: any) => ({ ...justification, id: justification.id.toString().includes('newJustification') ? 0 : justification.id })
            )
            dataToSend['userRegistrationId'] = user.userRegistrationId

            const impeditiveFieldsValidation = checkIfImpeditiveWasJustified(
                dataToSend.systemFieldFormImpeditiveTypeViewModels,
                dataToSend.systemFieldFormJustificationViewModels,
                systemFields,
                impeditiveFields
            )

            if (!impeditiveFieldsValidation.status) {
                dispatch(setDialog({ title: 'Campos Obrigatórios', message: impeditiveFieldsValidation.msg }))
                return
            }

            if (isUpdating) {
                const enumToIdMap: { [key: number]: number } = {};
                oldSystemFields.forEach((field: any) => {
                    enumToIdMap[field.systemFieldFormEnum] = field.id;
                });

                dataToSend.systemFieldFormImpeditiveTypeViewModels = dataToSend.systemFieldFormImpeditiveTypeViewModels.map(field => {
                    const id = enumToIdMap[field.systemFieldFormEnum];
                    return { ...field, id: id !== undefined ? id : null };
                });

                dispatch(updateProjectAnalizes({ dataToSend, id }))
                return
            }
            dispatch(createProjectAnalyzes(dataToSend))
        }

    },
)

const justificationFormValidation = createAsyncThunk(
    'justificationFormValidation/projectAnalyzes',
    async (_, { getState, dispatch }) => {
        const { projectAnalyzes, user, projectToValidate }: any = getState();
        const { isFormValid, pageFields } = projectAnalyzes.justificationForm
        const { allJustifications } = projectToValidate
        const rowData = dataParser(pageFields)

        if (isFormValid) {
            if (!rowData.hasOwnProperty('descJustification')) {
                rowData.descJustification = ''
            }
            const justificationName = allJustifications.find((jus: any) => jus.id === rowData.justicationId)?.name
            const now = new Date()
            rowData.id = `${now.getTime()}newJustification`
            rowData.userRegistrationName = user.name
            rowData.dateRegistration = now
            rowData.justicationName = justificationName
            dispatch(addJustificationRow(rowData))
        }
        return ''
    },
)

export const projectAnalyzesSlice = createSlice({
    name: 'projectAnalyzes',
    initialState: getNewProjectAnalyzesForm(),
    reducers: {
        handleFieldFiller: (state, { payload }) => {
            state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value) || '']
        },
        handleJustificationFieldFiller: (state, { payload }) => {
            state.justificationForm.pageFields = [...handleFiller(state.justificationForm.pageFields, payload.field, payload.value) || '']
        },
        addJustificationRow: (state, { payload }) => {
            state.rows = [...state.rows, payload]
            state.justificationForm.pageFields[0].value = ''
            state.justificationForm.pageFields[1].value = ''
            state.justificationForm.pageFields[2].value = ''
        },
        cleanForm: (state) => {
            const initialState = getNewProjectAnalyzesForm()
            state.pageFields = initialState.pageFields
            state.rows = initialState.rows
            state.oldSystemFields = initialState.oldSystemFields
            state.isUpdating = initialState.isUpdating
            state.id = initialState.id
        },
        handleFillInExistingFields: (state, { payload }) => {
            state.pageFields = [...updatePageFieldsWithPayload(state.pageFields, payload, state.systemFields)]
            state.rows = payload.systemFieldFormJustificationViewModels
            state.oldSystemFields = payload.systemFieldFormImpeditiveTypeViewModels
            state.isUpdating = true
            state.id = payload.id
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getImpeditiveTypes.fulfilled, (state, { payload }) => {
                const fieldsToFill = ['Matricula', 'CAR', 'CCIR', 'propertyDocumentation', 'propertyRegistration', 'ITR']
                state.pageFields = [...multipleFieldsOptionsFiller(state.pageFields, fieldsToFill, payload)]
                state.impeditiveFields = payload
            })
            .addCase(getPropertyTypes.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'typePropertyId', payload)]
            })
            .addCase(getSystemFormField.fulfilled, (state, { payload }) => {
                state.systemFields = payload
                state.justificationForm.pageFields = [...optionsFiller(state.justificationForm.pageFields, 'systemFieldFormEnum', payload)]
            })
            .addCase(getJustifications.fulfilled, (state, { payload }) => {
                state.justificationForm.pageFields = [...optionsFiller(state.justificationForm.pageFields, 'justicationId', payload)]
            })
            .addCase(formValidation.pending, (state) => {
                state.pageFields = [...inputValidation(state.pageFields)]
                const isValid = !state.pageFields.filter(x => x.error === true)[0]
                state.isFormValid = isValid
            })
            .addCase(justificationFormValidation.pending, (state) => {
                state.justificationForm.pageFields = [...inputValidation(state.justificationForm.pageFields)]
                const isValid = !state.justificationForm.pageFields.filter(x => x.error === true)[0]
                state.justificationForm.isFormValid = isValid
            })
            .addCase(createProjectAnalyzes.pending, (state) => {
                state.isFormDisabled = true
                state.justificationForm.isFormDisabled = true
            })
            .addCase(createProjectAnalyzes.fulfilled, (state) => {
                state.isFormDisabled = false
                state.justificationForm.isFormDisabled = false
            })
            .addCase(createProjectAnalyzes.rejected, (state) => {
                state.isFormDisabled = false
                state.justificationForm.isFormDisabled = false
            })
            .addCase(updateProjectAnalizes.pending, (state) => {
                state.isFormDisabled = true
            })
            .addCase(updateProjectAnalizes.fulfilled, (state) => {
                state.isFormDisabled = false
            })
            .addCase(updateProjectAnalizes.rejected, (state) => {
                state.isFormDisabled = false
            })
    },
})

export {
    getImpeditiveTypes,
    formValidation,
    justificationFormValidation,
    getPropertyTypes,
    getSystemFormField,
    getJustifications,
    createProjectAnalyzes,
    getExistingProjectById,
    updateProjectAnalizes,
    loadingMiddleware
}


export const { handleFieldFiller, handleJustificationFieldFiller, addJustificationRow, cleanForm, handleFillInExistingFields } = projectAnalyzesSlice.actions

export default projectAnalyzesSlice.reducer
