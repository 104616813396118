import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AppDispatch } from "../reducers/store";
import { useDispatch } from "react-redux";

const ImageInput = ({ onChange, imageUrl, fileName, fileExtension }: any) => {
  const inputRef = useRef(null);
  const dispatch: AppDispatch = useDispatch();
  const [isImgOpenned, setIsImgOpenned] = useState(false);
  const [isOverLayed, setIsOverLayed] = useState(false);
  const [isUpdatingImage, setIsUpdatingImage] = useState(false);
  const [fileItemData, setFileItemData] = useState({
    base64: imageUrl || "",
    name: fileName || "",
    extension: fileExtension || 0,
  });

  const imgName = fileItemData.base64
    ? fileItemData.name.length < 30
      ? fileItemData.name
      : `${fileItemData.name.slice(0, 30)}...`
    : "Nenhuma imagem selecionada";

  const handleCleanAll = () => {
    setFileItemData({
      base64: "",
      name: "",
      extension: 0,
    });
    setIsUpdatingImage(true)
  };

  function getFileType(fileType: string) {
    if (fileType === "image/jpeg" || fileType === "image/jpg") {
      return 2;
    } else if (fileType === "image/png") {
      return 3;
    } else {
      return -1;
    }
  }

  async function handleFileItem(event: any) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const ftype = f.type;
      const itemFileReader = new FileReader();
      itemFileReader.onload = function () {
        setFileItemData({
          //@ts-ignore
          base64: itemFileReader.result,
          name: fname,
          extension: getFileType(ftype),
        });
        dispatch(onChange(fileItemData));
      };
      itemFileReader.readAsDataURL(f);
    } else {
      handleCleanAll();
    }
  }

  useEffect(() => {
    if (fileItemData.base64) {
      if (fileItemData.base64) {
        const image = new Image();
        image.src = fileItemData.base64;

        image.onload = () => {
          setFileItemData({
            ...fileItemData,
          });
          dispatch(onChange(fileItemData));
        };
      }
    }
    // eslint-disable-next-line 
  }, [fileItemData.base64]);

  useEffect(() => {
    if (imageUrl && fileExtension && fileName && !isUpdatingImage) {
      setFileItemData({
        base64: `data:image/${getFileType(fileExtension)};base64,${imageUrl}`,
        name: fileName || "",
        extension: fileExtension || 0,
      });
      dispatch(onChange(fileItemData));
    }
    // eslint-disable-next-line 
  }, [imageUrl, fileExtension, fileName]);

  return (
    <Grid container sx={{ mt: 3 }} alignItems="center">
      <input
        type="file"
        ref={inputRef}
        onChange={handleFileItem}
        accept=".jpg, .jpeg, .png"
        style={{ display: "none" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          //@ts-ignore
          fileItemData.base64 ? handleCleanAll() : inputRef.current.click()
        }
      >
        {fileItemData.base64 ? "Alterar Imagem" : "Selecionar Imagem"}
      </Button>
      <Typography sx={{ ml: 1 }} variant="overline">
        {imgName}
      </Typography>
      {fileItemData.base64 && (
        <div
          onMouseEnter={() => setIsOverLayed(true)}
          onMouseLeave={() => setIsOverLayed(false)}
          onClick={() => setIsImgOpenned(true)}
          style={{
            marginLeft: "10px",
            cursor: "pointer",
            position: "relative",
            display: "flex",
          }}
        >
          <VisibilityIcon
            sx={{
              position: "absolute",
              color: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              fontSize: "100%",
            }}
          />
          <img
            src={fileItemData.base64}
            alt="image"
            loading="lazy"
            style={{
              height: "80px",
              filter: isOverLayed ? "blur(5px)" : "initial",
            }}
            onClick={() => setIsImgOpenned(true)}
          />
        </div>
      )}
      <Dialog open={isImgOpenned} aria-labelledby="modal-modal-title">
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={fileItemData.base64}
            alt="image"
            loading="lazy"
            style={{ marginLeft: "10px", width: "98%" }}
          />
          <DialogActions>
            <Button
              startIcon={<HighlightOffIcon />}
              variant="contained"
              color="secondary"
              href="#contained-buttons"
              onClick={() => setIsImgOpenned(false)}
            >
              Fechar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ImageInput;
