import { getJustifications } from '../../reducers/ProjectValidation/tabs/georeferencingAnalyzesReducer'
import { notNullValidation } from '../../services/validations'

export function getNewGeoreferencingAnalyzesForm() {

    return {
        id: 0,
        isFormValid: true,
        isFormDisabled: false,
        isUpdating: false,    
        oldSystemFields: [],
        rows: [] as any[],
        btnConfig: {
            visible: false,
        },
        systemFields: [] as any[],
        impeditiveFields: [] as any[],
        extension: 0,
        fileName: "",
        fileUrl: "",
        pageFields: [
            {
                id: 1,
                error: false,
                label: "Área comporta a potência*",
                name: "doesTheAreaContainThePower",
                required: true,
                value: 1,
                type: 3,
                options: [
                    {
                        id: 0,
                        name: 'Sim',
                        value: 1
                    },
                    {
                        id: 1,
                        name: 'Não',
                        value: 4
                    },
                ],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 2,
                error: false,
                label: "Inclinação*",
                name: "inclination",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [1, 2, 3, 4, 5, 9],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 3,
                error: false,
                label: "Risco de alagamento*",
                name: "floodRisk",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [1, 4, 5, 6, 7, 8],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 4,
                error: false,
                label: "Vegetação",
                name: "vegetation",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [3, 5, 6, 7, 8, 9],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 5,
                error: false,
                label: "APP (Área de proteção permanente)*",
                name: "permanentProtectionArea",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [3, 5, 6, 7, 8, 9],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 6,
                error: false,
                label: "Reserva legal*",
                name: "legalReserve",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [2, 3, 5, 6, 7, 8, 9],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 7,
                error: false,
                label: "UCS (Unidade de conservação)*",
                name: "conservationUnit",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [3, 5, 6, 7, 8, 9],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 8,
                error: false,
                label: "Assentamento*",
                name: "settlement",
                required: true,
                value: 4,
                type: 3,
                options: [
                    {
                        id: 0,
                        name: 'Sim',
                        value: 1
                    },
                    {
                        id: 1,
                        name: 'Não',
                        value: 4
                    },
                ],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 9,
                error: false,
                label: "Quilombola*",
                name: "quilombola",
                required: true,
                value: 4,
                type: 3,
                options: [
                    {
                        id: 0,
                        name: 'Sim',
                        value: 1
                    },
                    {
                        id: 1,
                        name: 'Não',
                        value: 4
                    },
                ],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 10,
                error: false,
                label: "Indígena*",
                name: "Indigenous",
                required: true,
                value: 4,
                type: 3,
                options: [
                    {
                        id: 0,
                        name: 'Sim',
                        value: 1
                    },
                    {
                        id: 1,
                        name: 'Não',
                        value: 4
                    },
                ],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 11,
                error: false,
                label: "Direito minerário*",
                name: "miningLaw",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [1, 4, 5, 6, 7, 8],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 12,
                error: false,
                label: "Risco de caverna*",
                name: "caveRisk",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [1, 4, 5, 6, 7, 8],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 13,
                error: false,
                label: "Sítios arqueológicos*",
                name: "archaeologicalSites",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [1, 4, 5, 6, 7, 8],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 14,
                error: false,
                label: "Risco de inundação*",
                name: "inundationRisk",
                required: true,
                value: '',
                type: 2,
                optionsFilter: [1, 4, 5, 6, 7, 8],
                options: [],
                validation: (value: any) => notNullValidation(value)
            },
            {
                id: 15,
                error: false,
                label: "Imagem*",
                name: "contentBase64",
                required: false,
                visible:false, 
                value: '',
                type: 4,   
                xs: 4.5,  
                rows: 1,           
                validation: (value: any) => notNullValidation(value)
            },

        ],
        justificationForm: {
            isFormValid: true,
            isFormDisabled: false,
            btnConfig: {
                visible: true,
                value: 'Vincular',
                xs: 2,
                height: '50%',
                iconType: 1
            },
            pageFields: [
                {
                    id: 1,
                    error: false,
                    label: "Campo*",
                    name: "systemFieldFormEnum",
                    required: true,
                    value: '',
                    type: 2,
                    options: [],
                    action: (value: number)=> getJustifications(value),
                    validation: (value: any) => notNullValidation(value)
                },
                {
                    id: 2,
                    error: false,
                    label: "Motivo*",
                    name: "justicationId",
                    required: true,
                    value: '',
                    type: 2,
                    options: [],
                    validation: (value: any) => notNullValidation(value)
                },
                {
                    id: 3,
                    error: false,
                    label: "Comentário",
                    name: "descJustification",
                    required: false,
                    value: '',
                    type: 4,
                    xs: 10,
                    options: [],
                }
            ],
        }
    }
}

export default getNewGeoreferencingAnalyzesForm