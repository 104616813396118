import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import UserMenuComponent from './UserMenu';
import DateBoxComponent from './DateBox';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DialogComponent from '../component/Dialog'
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import type { RootState, AppDispatch } from '../reducers/store'
import { useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { setDialogIdle } from '../reducers/Dialog/dialogReducer';
import LoadingOverlay from './LoadingOverlay';

const HeaderComponent = ({ open, menutoggle }: any) => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading)
  const isDialogOpen = useSelector((state: RootState) => state.dialog.isOpen)
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleMenu = (link: any) => {
    navigate(`/${link}`)
  };

  const pages = [
    { name: 'PRÉ-PROJETOS', link: 'preprojects'},
    { name: 'PROJETOS', link: 'projectgrid'},
  ];

  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
      {/* <Container maxWidth="xl"> */}
      <Toolbar style={{ marginLeft: open ? '19em' : '4em' }}>
        {!isMobile && (<IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => menutoggle()}
        >
          {!isMobile && (open ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />)}
        </IconButton>)}

        <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
          {pages.map((page) => (
            <Button
              onClick={() => handleMenu(page.link)}
              key={page.name}
              sx={{ my: { xs: 'none', md: 1 }, display: 'block', padding: { xs: 0, md: 1 }, }}
            >
              {page.name}
            </Button>
          ))}
        </Box>        

        <Box sx={{ flexGrow: 0 }}>
          <UserMenuComponent />
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
          <DateBoxComponent />
        </Box>
      </Toolbar>
      {isLoading && <LoadingOverlay open={isLoading} />}
      <DialogComponent open={isDialogOpen} handleClose={() => dispatch(setDialogIdle())} />
    </AppBar>
  );
}

export default HeaderComponent