import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageContainerComponent from "../../component/PageContainer";
import {
  cleanRowSelected,
  getprojectGrid
} from "../../reducers/Project/projectGridReducer";
import { AppDispatch, RootState } from "../../reducers/store";
import { ptBR } from '@mui/x-data-grid/locales';
import { setProjectData } from "../../reducers/ProjectValidation/projectToValidateReducer";
import ProjectGridColumnsData from "./ProjectGridColumns";

const ProjectGridComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const rows: any = useSelector((state: RootState) => state.projectGrid.rows);
  const rowSelected = useSelector((state: RootState) => state.projectGrid.rowSelected);
  const navigate = useNavigate();
  const { columns }= ProjectGridColumnsData()

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(getprojectGrid());
  }, [dispatch])

  useEffect(() => {
    if (rowSelected.id) {
      dispatch(setProjectData(rowSelected))
      dispatch(cleanRowSelected());
      navigate('/project-validation');
    }
    // eslint-disable-next-line 
  }, [rowSelected])

  return (
    <PageContainerComponent title="Projetos" style={{ marginLeft: isMobile ? 60 : 300 }}>
      <DataGrid localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.id}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{ toolbar: GridToolbar }}
      />
    </PageContainerComponent>
  );
}

export default ProjectGridComponent;