import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post, remove } from "../../../services/agent";
import loadingMiddleware from '../../Loading/loadingMiddleware';
import { SystemForm } from '../../../types'
import { format } from "date-fns";
import { dataParser, handleFiller, insertValueIntoFieldByName, optionsFiller } from "../../../services/utils";
import { getNoteHistoryInitialState } from "../../../data/forms/noteHistory";
import HttpResponseHandler from "../../Error/httpResponseHandler";
import { setDialog } from "../../Dialog/dialogReducer";

const PROJECT_URL = "projects"
const SYSTEM_FORMS_URL = "system-forms"
const USERS_URL = "users"
const ANNOTATION_HISTORIES_URL = "annotations-histories"

const getNoteHistory = createAsyncThunk(
    'noteHistory/get',
    async ({
        id,
        userRegistrationId,
        systemFormId }: any, { dispatch }) => {
        let result = null;
        const response = await get(
            PROJECT_URL + '/'
            + id + '/'
            + userRegistrationId + '/'
            + systemFormId + '/annotation-history');

        result = {
            notes: (response.length ? response : []),
            systemFormId: systemFormId
        }

        return result
    }
)

const getSystemForms = createAsyncThunk(
    'systemForms/get',
    async (_, { dispatch }) => {
        const response = await get(SYSTEM_FORMS_URL)
        return response
    }
)

const getUsers = createAsyncThunk(
    'usersNoteHistory/get',
    async (_, { dispatch }) => {
        const response = await get(USERS_URL)
        return response
    }
)

const createNote = createAsyncThunk(
    'createNoteHistory/post',
    async (data: any, { dispatch }) => {
        const response = await post(ANNOTATION_HISTORIES_URL, data)
        const responseJson = await response.json()

        dispatch(setDialog({
            title: 'Histórico de Anotações',
            message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
        }))

        if (response.status === 200 || response.status === 201) {
            dispatch(setFirstTouch(true))
            await dispatch(getAllNoteHistory())
        }

        return;
    }
)

const removeNote = createAsyncThunk(
    'removeNoteHistory',
    async (id: any, { dispatch }) => {
        const response = await remove(ANNOTATION_HISTORIES_URL + '/' + id)

        if (response.status === 200) {
            dispatch(setDialog({
                title: 'Histórico de Anotações',
                message: 'Anotação excluída com sucesso.'
            }))
            dispatch(setFirstTouch(true))
            await dispatch(getAllNoteHistory())
        } else {
            const responseJson = await response.json()
            dispatch(setDialog({
                title: 'Histórico de Anotações',
                message: HttpResponseHandler.getHttpStatusMessage(response.status, JSON.stringify(responseJson))
            }))
        }
    }
)

const formValidation = createAsyncThunk(
    'formValidationNoteHistory',
    async (_, { getState, dispatch }) => {
        const { noteHistory, user, projectToValidate }: any = getState();
        const { isFormValid, pageFields } = noteHistory
        const dataToSend = dataParser(pageFields)

        if (isFormValid) {
            dataToSend['userRegistrationId'] = user.userRegistrationId
            dataToSend['projectId'] = projectToValidate.projectSelected.id
            dispatch(createNote(dataToSend))
        }

        return ''
    },
)

const getAllNoteHistory = createAsyncThunk(
    'allNoteHistory',
    async (_, { getState, dispatch }) => {
        const { user, noteHistory, projectToValidate }: any = getState();
        if (noteHistory.firstTouch) {
            dispatch(clearNoteHistorys())
            dispatch(setFirstTouch(false))
            const userRegistrationId = user.userRegistrationId;
            const projectId = projectToValidate.projectSelected.id;
            noteHistory.systemForms.forEach(async (systemForm: SystemForm) => {
                await dispatch(getNoteHistory({
                    id: projectId,
                    userRegistrationId: userRegistrationId,
                    systemFormId: systemForm.id
                }))
            })
        }
    }
)

export const noteHistorySlice = createSlice({
    name: 'noteHistory',
    initialState: getNoteHistoryInitialState(),
    reducers: {
        clearNoteHistorys: (state) => {
            state.noteHistorys = [];
        },
        setFirstTouch: (state, { payload }) => {
            state.firstTouch = payload;
        },
        handleFieldFiller: (state, { payload }) => {
            state.pageFields = [...handleFiller(state.pageFields, payload.field, payload.value)]
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNoteHistory.fulfilled, (state, { payload }) => {
                const transformedPayload = {
                    notes: payload.notes.map((note: any) => ({
                        ...note,
                        dateRegistration: format(new Date(note.dateRegistration), 'dd/MM/yyyy HH:mm')
                    })),
                    systemFormId: payload.systemFormId
                }
                state.noteHistorys = [...state.noteHistorys, transformedPayload]
            })
            .addCase(getSystemForms.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'systemFormId', payload)]
                state.systemForms = payload
            })
            .addCase(getUsers.fulfilled, (state, { payload }) => {
                state.pageFields = [...optionsFiller(state.pageFields, 'userRecipientId', payload)]
            })
            .addCase(createNote.fulfilled, (state) => {
                insertValueIntoFieldByName(state.pageFields, 'numberCapacityKwdc', '')
                insertValueIntoFieldByName(state.pageFields, 'userRecipientId', '')
                insertValueIntoFieldByName(state.pageFields, 'systemFormId', '')
            })
    }
})

export {
    removeNote,
    formValidation,
    getNoteHistory,
    getSystemForms,
    getAllNoteHistory,
    getUsers,
    loadingMiddleware,
};

export const {
    clearNoteHistorys,
    setFirstTouch,
    handleFieldFiller,
} = noteHistorySlice.actions

export default noteHistorySlice.reducer