import { IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageContainerComponent from "../../component/PageContainer";
import { cleanRowSelected,  getPreProjectGrid } from "../../reducers/PreProject/preProjectGridReducer";
import { AppDispatch, RootState } from "../../reducers/store";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ptBR } from '@mui/x-data-grid/locales';
import { AppRegistration, UploadFileSharp, ViewListSharp } from '@mui/icons-material';
import ButtonGroupComponent from "../../component/ButtonGroup";
import { format } from "date-fns";
import { setPreProjectDuplicityData } from "../../reducers/PreProjectDuplicty/preProjectDuplicityEditReducer";
import { 
  cleanRowSelected as cleanRowDuplicitySelected, 
  getPreprojectById as getPreprojectDuplicityById 
} from "../../reducers/PreProjectDuplicty/preProjectDuplicityReducer";
import { getPreProjectById } from "../../reducers/PreProject/preProjectViewReducer";
import { parseToDecimal } from "../../services/utils";

const PreProjectGridComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const rows = useSelector((state: RootState) => state.preProjectGrid.rows);
  const duplicityRowSelected: any = useSelector((state: RootState) => state.preProjetoDuplicity.rowSelected);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(getPreProjectGrid());
  }, [dispatch])

  useEffect(() => {
    if (duplicityRowSelected.id) {
      const payload: any = {
        ...duplicityRowSelected,
        numberFactorCapacity: duplicityRowSelected.numberFactorCapacity?.toString().replace('.', ','),
        numberCapacityKwac: parseToDecimal(duplicityRowSelected.numberCapacityKwac),
        numberCapacityKwdc: parseToDecimal(duplicityRowSelected.numberCapacityKwdc),
        dateLastUpdate: format(
          new Date(duplicityRowSelected.dateLastUpdate),
          'dd/MM/yyyy HH:mm'
        ),
      };     
      dispatch(setPreProjectDuplicityData(payload));
      dispatch(cleanRowDuplicitySelected());
      navigate('/preproject-duplicity-edit');
    }
    // eslint-disable-next-line 
  }, [duplicityRowSelected])

  const handleRowClick = async (row: any) => {
    if(row.statusName === "Avaliação Duplicado"){
      await dispatch(getPreprojectDuplicityById(row.id));
      return
    } else {
      dispatch(getPreProjectById(row.id));
      dispatch(cleanRowSelected());
      navigate('/preproject/view');
    }
  }

  const columns: GridColDef[] = [
    {
      field: "preProjectActions",
      headerName: "Visualizar",
      width: 100,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleRowClick(params.row)}
          size="small"
          title="Ver"
        >
          <VisibilityIcon color="primary" />
        </IconButton>
      ),
    },
    { field: 'coPreProject', headerName: 'Código', width: 150, filterable: true },
    { field: 'nameProjectDevelop', headerName: 'Projeto (Ref. develop)', width: 200, filterable: true },
    { field: 'nameProject', headerName: 'Projeto', width: 140, filterable: true },
    { field: 'stateAcronym', headerName: 'Estado', width: 80, filterable: true },
    { field: 'cityName', headerName: 'Cidade', width: 200, filterable: true },
    { field: 'utilityName', headerName: 'Distribuidora', width: 170, filterable: true },
    {
      field: "numberCapacityKwac",
      headerName: "Capacidade instalada kWac", 
      width: 190,
      filterable: true,
    },
    { field: 'numberFactorCapacity', headerName: 'Fator', width: 80, filterable: true },
    {
      field: "numberCapacityKwdc",
      headerName: "Capacidade instalada kWdc",
      width: 190,
      filterable: true,
    },
    { field: 'accessOpinionName', headerName: 'Parecer de acesso', width: 150, filterable: true },
    {
      field: "dateLastUpdate",
      headerName: "Última atualização",
      width: 150,
      filterable: true,
      valueFormatter: (params) => {      
        return params.value ? format(new Date(params.value), "dd/MM/yyyy 'às' HH:mm") : '';
      },
    },
    { field: 'statusName', headerName: 'Status', width: 200, filterable: true },
  ];

  const buttons = [
    { name: "Ver Pré-projetos", link: "/preprojects", icon: <ViewListSharp />},
    { name: "Cadastrar", link: "/preproject", icon: <AppRegistration />},
    { name: "Importar", link: "/preProject-incharge", icon: <UploadFileSharp /> },   
    { name: "Ver Importações", link: "/preproject/imports", icon: <ViewListSharp /> },
  ];

  return (
    <PageContainerComponent title="Pré-projetos" style={{ marginLeft: isMobile ? 60 : 300 }}>
      <ButtonGroupComponent buttons={buttons} />
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.id}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{ toolbar: GridToolbar }}
      />
    </PageContainerComponent>
  );
}

export default PreProjectGridComponent;