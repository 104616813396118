import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const DateBoxComponent = ({field}: any) => {

  const getDate = () => {
    return new Date().toLocaleString('pt-BR', {
      hour12: false,
    });
  }


  const [date, setDate] = React.useState(getDate());

  setTimeout(() => {
    setDate(getDate())
  }, 1000);

  return (
      <Grid container>
        <Grid item xs={6}>
          <Typography>{date}</Typography>
        </Grid>
    </Grid>
  );
}

export default DateBoxComponent