import { notNullValidation } from "../../../services/validations";

export function getSupportMailSenderInitialState() {
    return {
        pageFields: [
            {
                id: 1,
                error: false,
                label: "Mensagem",
                name: "MessageBody",
                required: true,
                value: '',
                type: 4,
                validation: (value: any) => notNullValidation(value)
            },
        ],
        btnConfig: {
            visible: true,
            value: 'Enviar',
            xs: 12,
            height: '50%',
            iconType: 4
        },
        isFormValid: true,
    }
}